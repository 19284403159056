<div [ngClass]="{'image-box': isInWorkspace}" [ngStyle]="{'height': isInWorkspace ? 'unset' : 'unset'}"
     style="margin-bottom: 5px">

  <img [attr.src]="image"
       *ngIf="image"
       class="image-item"
       alt="Image from workspace"
       (click)="openImage()">

  <mat-spinner
    class="image-item"
    *ngIf="showSpinner"></mat-spinner>

</div>
