<h1 mat-dialog-title>Generate From HTML</h1>

<div mat-dialog-content class="content-no-overflow">
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

  <label>Method</label>
  <div class="mb-3">
    <mat-button-toggle-group (change)="method = $event.value;" [disabled]="saveSpinner" [value]="0">
      <mat-button-toggle [value]="0">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-pos"
          color="none"
          [extension]="'desktop'"
        ></app-nav-tree-file-icon>
        From Computer Files
      </mat-button-toggle>

      <mat-button-toggle [value]="1">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-pos"
          color="none"
          [extension]="'file-system-icon'"
        ></app-nav-tree-file-icon>
        From Project Files
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div *ngIf="method == '0'">
    <label>File Upload</label>
    <div class="container file-input-container">
      <div class="row justify-content-md-center">
        <div class="col">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input (change)="selectLocalFile($event)" type="file" class="custom-file-input" id="inputGroupFile01">
              <label class="custom-file-label" for="inputGroupFile01">{{localFileName}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="method == '1'">
    <label>Project Files</label>
    <label *ngIf="workspaceFileSelected">{{workspaceFilePath}}</label>
    <div class="container file-input-container">
      <app-nav-workspace-tree *ngIf="!workspaceFileSelected"
                              [initialData]="rootWorkspaceNode"
                              [ngClass]="{'display-none': !expandWorkspace}"
                              [height]="'calc(100% - 30px)'"
                              [(copyNode)]="copyNode"
                              [(isCopy)]="isCopy"
                              (selected)="selectedFile($event)"
                              [maxHeight]="'35vh'">
      </app-nav-workspace-tree>
    </div>
    <button id="selectNewFile" mat-button *ngIf="workspaceFileSelected && !saveSpinner"
            (click)="workspaceFileSelected = false">Select new file
    </button>
  </div>

  <div mat-dialog-actions>
    <button id="cancelButton" mat-button *ngIf="!saveSpinner" [mat-dialog-close]>Cancel</button>

    <button id="createButton" mat-button *ngIf="!saveSpinner" (click)="generate()" color="primary">
      Generate
    </button>
    <button mat-button *ngIf="saveSpinner" color="primary">
      <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
        <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                     [diameter]="20"
                     class="whiteSpinner"></mat-spinner>
      </mat-icon>
    </button>
  </div>
</div>
