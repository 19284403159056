<mx-options
  [allowShadow]="allowShadow"
  [showButtons]="showButtons"
  [showButtonText]="showButtonText"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
  [iconMarginBottom]="iconMarginBottom"
  [defaultColors]="true"
  #mxOptions
>

  <mx-options-header>
    <!--      filter-->
    <app-filter-by
      class="filter-by mr-1 ml-2"
      [searchParams]="searchParams"
      (searchParamsChange)="newParams($event)"
      [disabled]="!checkPaths.filter.enabled"
      (filter)="filter()"
      [filterBy]="'email'"
      [placeholderText]="'Email...'"
      [isSearchCollapsed]="mxOptions.isSearchCollapsed"
      (isSearchCollapsedChange)="mxOptions.toggleBody();"
    ></app-filter-by>


    <!--      create-->
    <button mat-button
            *ngIf="showButtons"
            (click)="create()"
            [disabled]="!checkPaths.create.enabled"
            [matTooltip]="!showTooltip? undefined : 'Add user'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="create" color="green"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Add user</span>
    </button>


    <!--      refresh-->
    <button mat-button
            *ngIf="showButtons"
            (click)="refresh()"
            [disabled]="!checkPaths.refresh.enabled"
            [matTooltip]="!showTooltip? undefined : 'Refresh'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="refresh"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Refresh</span>
    </button>

    <!--      delete-->
    <button mat-button
            *ngIf="showButtons && (hasSelected)"
            (click)="delete()"
            [disabled]="!checkPaths.delete.enabled"
            [matTooltip]="!showTooltip? undefined : 'Delete'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="delete"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Delete</span>
    </button>

  </mx-options-header>

  <mx-options-body>

    <div class="filter">
      <app-user-filter
        [lockSearchParams]="lockFilterParams"
        [userRequests]="userRequests"
        [searchParams]="searchParams"
        (searchParamsChange)="newParams($event)"
        (filter)="filter()"
        (close)="mxOptions.closeBody();"
      ></app-user-filter>
    </div>
  </mx-options-body>
</mx-options>
