<mx-options
  [allowShadow]="allowShadow"
  [showButtons]="showButtons"
  [showButtonText]="showButtonText"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
  [iconMarginBottom]="iconMarginBottom"
  [defaultColors]="true"
  #mxOptions
>

  <mx-options-header>

    <!--      restart-->
    <button mat-button
            *ngIf="showButtons"
            (click)="restartEngine()"
            [disabled]="!checkPaths.restartEngine.enabled"
            [matTooltip]="!showTooltip? undefined : 'Restart engine'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="restart"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Restart Engine</span>
    </button>

    <!--      export-->
    <button mat-button
            *ngIf="showButtons"
            (click)="export()"
            [disabled]="!checkPaths.export.enabled"
            [matTooltip]="!showTooltip? undefined : 'Export'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="export"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Export Project</span>
    </button>

    <!--      import-->
    <button mat-button
            *ngIf="showButtons"
            (click)="import()"
            [disabled]="!checkPaths.import.enabled"
            [matTooltip]="!showTooltip? undefined : 'Import'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="import"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Import Project</span>
    </button>

    <!--      export-->
    <button mat-button
            (click)="unauthorizedRequests()"
            *ngIf="showButtons && userService.isAdmin()"
            [matTooltip]="!showTooltip? undefined : 'Unauthorized requests'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="export"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Unauthorized requests</span>
    </button>

  </mx-options-header>

  <mx-options-body>

    <!--    <div class="filter">-->
    <!--      &lt;!&ndash;filter&ndash;&gt;-->
    <!--      <app-filter-suite-->
    <!--        *ngIf="!single"-->
    <!--        [lockSearchParams]="lockFilterParams"-->
    <!--        [searchParams]="searchParams"-->
    <!--        (searchParamsChange)="newParams($event)"-->
    <!--        (filter)="filter()"-->
    <!--        (close)="mxOptions.closeBody();">-->
    <!--      </app-filter-suite>-->
    <!--    </div>-->
  </mx-options-body>
</mx-options>

