import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import OverlayScrollbars from 'overlayscrollbars';

@Directive({
  selector: '[appOverlayScroll]'
})
export class OverlayScrollDirective implements OnInit, OnDestroy {
  private scrollbarInstance: OverlayScrollbars | undefined;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.scrollbarInstance = OverlayScrollbars(this.el.nativeElement, {

      scrollbars: {
        autoHide: 'leave',
        autoHideDelay: 300
      }
    });
  }

  ngOnDestroy(): void {
    if (this.scrollbarInstance) {
      this.scrollbarInstance.destroy();
    }
  }
}
