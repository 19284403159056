<div class="session-list">
  <div class="create-chat-button">
    <button mat-raised-button color="primary" (click)="createNewChatSession()">
      Create new chat
    </button>
  </div>

  <div
    [ngStyle]="{
  'height': maxHeight
  }"
    style="overflow-y: auto;">

    <overlay-scrollbars
      #pageScroll
      style="height: calc(100%)"
      [options]="{ scrollbars: { autoHide: 'l' } }"
    >
      <mat-list>
        <!--    <mat-list-item>-->
        <!--      <h3 matLine>Create new chat</h3>-->
        <!--    </mat-list-item>-->

        <!--    <mat-divider></mat-divider>-->

        <!--      <mat-list-item *ngFor="let session of aiChatSessions" (click)="selectedSession(session)">-->
        <div *ngIf="relatedSessionPurpose != null" class="session-info related-session"
             style="display: flex; flex-direction: column;"
             (click)="selectedSession(this.relatedSessionPurpose)">
          <div class="session-title" matLine #title
               [matTooltip]="isOverflowingTitle(title) ? relatedSessionPurpose.title:null">{{ relatedSessionPurpose.title }}</div>
          <div class="bottom-line" style="display: flex; justify-content: space-between; align-items: center;">
            <div class="session-date" matLine>{{ relatedSessionPurpose.lastUsedDate | date }}</div>
            <div class="session-actions">
              <button mat-button
                      style="margin: 0;padding: 0;"
                      class="mr-1"
                      [matTooltip]="'Edit session'"
                      (click)="editSessions(relatedSessionPurpose); $event.stopPropagation()">
                <mx-icon size="small" mx="edit"></mx-icon>
                <!--    <span>Copy to clipboard</span>-->
              </button>
              <button mat-button
                      style="margin: 0;padding: 0;"
                      [matTooltip]="'Remove session'"
                      (click)="removeSession(relatedSessionPurpose); $event.stopPropagation()">
                <mx-icon mx="delete" size="small"></mx-icon>
                <!--    <span>Copy to clipboard</span>-->
              </button>
            </div>
          </div>
        </div>
        <!--      </mat-list-item>-->

        <ng-container *ngFor="let session of aiChatSessions">
          <mat-list-item
            *ngIf="!relatedSessionPurpose || relatedSessionPurpose.id !== session.id"
            (click)="selectedSession(session)">
            <div class="session-info" style="display: flex; flex-direction: column;">
              <div class="session-title" matLine #title
                   [matTooltip]="isOverflowingTitle(title) ? session.title:null">{{ session.title }}</div>
              <div class="bottom-line" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="session-date" matLine>{{ session.lastUsedDate | date }}</div>
                <div class="session-actions">
                  <button mat-button
                          style="margin: 0;padding: 0;"
                          class="mr-1"
                          [matTooltip]="'Edit session'"
                          (click)="editSessions(session); $event.stopPropagation()">
                    <mx-icon size="small" mx="edit"></mx-icon>
                    <!--    <span>Copy to clipboard</span>-->
                  </button>
                  <button mat-button
                          style="margin: 0;padding: 0;"
                          [matTooltip]="'Remove session'"
                          (click)="removeSession(session); $event.stopPropagation()">
                    <mx-icon mx="delete" size="small"></mx-icon>
                    <!--    <span>Copy to clipboard</span>-->
                  </button>
                </div>
              </div>
            </div>
          </mat-list-item>
        </ng-container>

      </mat-list>
    </overlay-scrollbars>
  </div>
</div>
