<h1 mat-dialog-title>Import Project</h1>
<div mat-dialog-content>
  <div *ngIf="!importSpinner">
    <div class="row justify-content-md-center" style=" max-width: 100%;">
      <div *ngIf="showSuccess" class="row">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <h5 class="alert-heading"> {{(
            (importInfoDetails.workspaceError != null && importInfoDetails.workspaceError != "") ||
            (importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != "") ||
            (importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != "") ||
            (importInfoDetails.suitesError != null && importInfoDetails.suitesError != "") ||
            (importInfoDetails.casesError != null && importInfoDetails.casesError != "")
          ) ? 'Partial ' : ''}} Success!</h5>
          <hr>
          <p *ngIf="successMessage" class="mb-0">{{ successMessage }} </p>
          <button (click)="showSuccess=false" type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>

    <app-error-message *ngIf="showUploadError" [error]="error" [showInDialog]="true"></app-error-message>

    <mat-progress-bar *ngIf="currentFileUpload" mode="determinate" [value]="progress.percentage"
                      class="mt-1"></mat-progress-bar>

    <div class="container" style=" max-width: 100%;">
      <div class="row justify-content-md-center">
        <div class="col">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input (change)="selectFile($event)" type="file" class="custom-file-input" id="inputGroupFile01">
              <label class="custom-file-label" for="inputGroupFile01">{{fileName}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br>

    <mat-checkbox [disabled]="spinner" type="checkbox" [checked]="overwriteProjectParams"
                  (change)="overwriteProjectParams = !overwriteProjectParams"
                  id="overwriteProjectParams">Overwrite project params data with import file?
    </mat-checkbox>
    <br/>
    <mat-checkbox [disabled]="spinner" type="checkbox" [checked]="overwriteCases"
                  (change)="overwriteCases = !overwriteCases"
                  id="overwriteCases">Overwrite cases data with import file?
    </mat-checkbox>
    <br/>
    <mat-checkbox [disabled]="spinner" type="checkbox" [checked]="compile" (change)="compile = !compile"
                  id="compileCheck">Compile cases?
    </mat-checkbox>
    <br/>
    <mat-checkbox [disabled]="spinner" type="checkbox" [checked]="overwriteSuites"
                  (change)="overwriteSuites = !overwriteSuites"
                  id="overwriteSuites">Overwrite suites data with import file?
    </mat-checkbox>
    <br/>
    <mat-checkbox [disabled]="spinner" type="checkbox" [checked]="overwriteSchedules"
                  (change)="overwriteSchedules = !overwriteSchedules"
                  id="overwriteSchedules">Overwrite schedules data with import file?
    </mat-checkbox>
    <br/>
    <mat-checkbox [disabled]="spinner" type="checkbox" [checked]="overwriteWorkspace"
                  (change)="overwriteWorkspace = !overwriteWorkspace"
                  id="overwriteWorkspace">Overwrite workspace files data with import file?
    </mat-checkbox>
    <br/>
    <br/>
  </div>

  <div *ngIf="importSpinner && this.importInfoDetails == undefined">
    <mat-spinner style="align-self:flex-end; margin: 0 auto;" [diameter]="50" color="primary"></mat-spinner>
  </div>

  <div *ngIf="importSpinner && this.importInfoDetails != undefined">

    <!--    {{(importInfoDetails != null && (-->
    <!--    (importInfoDetails.workspaceError != null && importInfoDetails.workspaceError != "") ||-->
    <!--    (importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != "") ||-->
    <!--    (importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != "") ||-->
    <!--    (importInfoDetails.suitesError != null && importInfoDetails.suitesError != "") ||-->
    <!--    (importInfoDetails.casesError != null && importInfoDetails.casesError != "")-->
    <!--  ))?'Partial ' : ''}}-->
    <div class="row justify-content-md-center" style=" max-width: 100%;">
      <div *ngIf="showSuccess" class="row">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <h5 class="alert-heading"> {{(
            (importInfoDetails.workspaceError != null && importInfoDetails.workspaceError != "") ||
            (importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != "") ||
            (importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != "") ||
            (importInfoDetails.suitesError != null && importInfoDetails.suitesError != "") ||
            (importInfoDetails.casesError != null && importInfoDetails.casesError != "")
          ) ? 'Partial ' : ''}} Success!</h5>
          <hr>
          <p *ngIf="successMessage" class="mb-0">{{ successMessage }} </p>
          <button (click)="showSuccess=false" type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>

    <div>
      Project Params: {{importInfoDetails.currentProjectParamsNumber}}/{{importInfoDetails.maxProjectParamsNumber}}
    </div>
    <div>
      Cases:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{importInfoDetails.currentCasesNumber}}
      /{{importInfoDetails.maxCasesNumber}}
    </div>
    <div>
      Suites:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{importInfoDetails.currentSuitesNumber}}
      /{{importInfoDetails.maxSuitesNumber}}
    </div>
    <div
      *ngIf=" importInfoDetails.currentSuitesNumber == importInfoDetails.maxSuitesNumber && importInfoDetails.connectingCasesWithSuitesStart && !importInfoDetails.connectingCasesWithSuitesEnd">
      {{counterDot == 0 ? 'Connecting cases with suites.' : counterDot == 1 ? 'Connecting cases with suites..' : 'Connecting cases with suites...'}}
    </div>
    <div *ngIf="this.type === 'schedule'">
      Schedules:&nbsp;{{importInfoDetails.currentScheduleNumber}}/{{importInfoDetails.maxScheduleNumber}}
    </div>
    <div *ngIf="importInfoDetails.workspaceStart && !importInfoDetails.workspaceCompleted">
      {{workspaceDot == 0 ? 'Import workspace.' : workspaceDot == 1 ? 'Import workspace..' : 'Import workspace...'}}
    </div>
    <div *ngIf="importInfoDetails.workspaceCompleted">
      {{'workspace was imported'}}
    </div>

    <br>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.projectParamsSkipped != null && importInfoDetails.projectParamsSkipped != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped project params
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.projectParamsSkipped"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.projectParamsErrors != null && importInfoDetails.projectParamsErrors != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Project params errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.projectParamsErrors"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Project param error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.projectParamsError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.casesSkipped != null && importInfoDetails.casesSkipped != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped cases
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.casesSkipped"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.casesErrors != null && importInfoDetails.casesErrors != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Cases errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.casesErrors"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.casesError != null && importInfoDetails.casesError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Cases error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.casesError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>


    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.suitesSkipped != null && importInfoDetails.suitesSkipped != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped suites
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.suitesSkipped"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.suitesErrors != null && importInfoDetails.suitesErrors != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suites errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.suitesErrors"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.suitesError != null && importInfoDetails.suitesError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suites error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.suitesError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <!--    <mat-expansion-panel style="border-radius: 0;" *ngIf="importInfoDetails.suitesSkipped != null && importInfoDetails.suitesSkipped != ''">-->
    <!--      <mat-expansion-panel-header>-->
    <!--        <mat-panel-title>-->
    <!--          Skipped suite items-->
    <!--        </mat-panel-title>-->
    <!--      </mat-expansion-panel-header>-->

    <!--      <mat-form-field class="w-100" >-->
    <!--          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"-->
    <!--                    matAutosizeMinRows="2"-->
    <!--                    [contentEditable]="false"-->
    <!--                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.suitesSkipped" (ngModelChange)="paramsChangeValue()"></textarea>-->
    <!--      </mat-form-field>-->
    <!--    </mat-expansion-panel>-->

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.caseSuitesErrors != null && importInfoDetails.caseSuitesErrors != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suite items errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.caseSuitesErrors"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.caseSuitesError != null && importInfoDetails.caseSuitesError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suites items error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.caseSuitesError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.scheduleSkipped != null && importInfoDetails.scheduleSkipped != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped schedules
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.scheduleSkipped"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.scheduleErrors != null && importInfoDetails.scheduleErrors != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Schedules errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.scheduleErrors"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Schedules error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.scheduleError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>
    <!--  -->
    <!--  <mat-expansion-panel style="border-radius: 0;" *ngIf="importInfoDetails.scheduleErrors != null && importInfoDetails.scheduleErrors != ''">-->
    <!--    <mat-expansion-panel-header>-->
    <!--      <mat-panel-title>-->
    <!--        Workspace errors-->
    <!--      </mat-panel-title>-->
    <!--    </mat-expansion-panel-header>-->

    <!--    <mat-form-field class="w-100" >-->
    <!--          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"-->
    <!--                    matAutosizeMinRows="2"-->
    <!--                    [contentEditable]="false"-->
    <!--                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.workspaceError" (ngModelChange)="paramsChangeValue()"></textarea>-->
    <!--    </mat-form-field>-->
    <!--  </mat-expansion-panel>-->

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.workspaceError != null && importInfoDetails.workspaceError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Workspace error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.workspaceError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <br>
  </div>
</div>
<div mat-dialog-actions>
  <button id="okButton" mat-button mat-raised-button *ngIf="!spinner && finished" (click)="closeDialog()">Ok</button>
  <button id="closeButton" mat-button *ngIf="!spinner && !finished" (click)="closeDialog()">Close</button>
  <button id="uploadButton" [disabled]="!selectedFiles" mat-raised-button [ngClass]="{'green_button': selectedFiles}"
          *ngIf="!spinner && !finished" (click)="upload()">Upload
  </button>

  <!--  <button mat-button *ngIf="spinner" color="primary">-->
  <!--    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">-->
  <!--      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"-->
  <!--                   [diameter]="20"-->
  <!--                   class="whiteSpinner"></mat-spinner>-->
  <!--    </mat-icon>-->
  <!--  </button>-->
</div>
