<app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

<!--<div class="px-4">-->
<!--  <mat-form-field class="w-100" appearance="outline">-->
<!--    <mat-label>Select project</mat-label>-->
<!--    <mat-icon matPrefix color="accent">search</mat-icon>-->
<!--    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" autocomplete="off">-->
<!--    <mat-icon matSuffix color="accent">bubble_chart</mat-icon>-->
<!--  </mat-form-field>-->
<!--</div>-->

<div class="responsive-table-wrapper">
  <table mat-table [dataSource]="items" class="w-100">

    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef style="width: 80px">Active</th>
      <td mat-cell *matCellDef="let element">
        <mx-icon class="align-middle mx-icon" color="primary"
                 [ngStyle]="{'display' : (activeProjectId != element.id) ? 'none' : 'inline-block'}" mx="check">
        </mx-icon>

      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element"
          [matTooltip]="!element.name ? null : element.name.length > nameMaxSize ? element.name : null">
        <!--        <mx-icon class="align-middle mx-icon" [color]="(activeProjectId != element.id) ? '#404040' : 'primary'"-->
        <!--                 mx="project"></mx-icon>-->
        <span
          [matTooltip]="!element.name ? null : element.name.length > nameMaxSize ? element.name : null"
          class="name-style align-middle"
          [ngStyle]="{'color': (activeProjectId != element.id) ? '#404040' : '#1e88e5'}">
          {{!element.name ? '' : element.name.length > nameMaxSize ? element.name.substr(0, nameMaxSize - 1) + '..' : element.name }}
        </span>
      </td>
    </ng-container>

    <!-- id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID</th>
      <td mat-cell *matCellDef="let element"
          [matTooltip]="!element.id ? null : element.id.length > idMaxSize ? element.id : null"
          [ngStyle]="{'color': (activeProjectId != element.id) ? '#404040' : '#1e88e5'}">
        {{!element.id ? '' : element.id.length > idMaxSize ? element.id.substr(0, idMaxSize - 1) + '..' : element.id}}
      </td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef> Owner</th>
      <td mat-cell *matCellDef="let element"
          [matTooltip]="!element.userOwnerDisplayName ? null : element.userOwnerDisplayName.length > idMaxSize ? element.userOwnerDisplayName : null"
          [ngStyle]="{'color': (activeProjectId != element.userOwnerDisplayName) ? '#404040' : '#1e88e5'}">
        <!--                {{!element.userOwnerEmail ? '' : element.userOwnerEmail.length > idMaxSize ? element.userOwnerEmail.substr(0, idMaxSize - 1) + '..' : element.userOwnerEmail}}-->
        {{element.userOwnerDisplayName}}
      </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description</th>
      <td mat-cell *matCellDef="let element"
          [matTooltip]="!element.description ? null : element.id.length > idMaxSize ? element.description : null"
          [ngStyle]="{'color': (activeProjectId != element.description) ? '#404040' : '#1e88e5'}">
        {{!element.description ? '' : element.description.length > idMaxSize ? element.description.substr(0, idMaxSize - 1) + '..' : element.description}}
        <!--      {{element.userOwnerEmail}}-->
      </td>
    </ng-container>


    <!--    &lt;!&ndash; Stop Column &ndash;&gt;-->
    <!--    <ng-container matColumnDef="options">-->
    <!--      <th mat-header-cell *matHeaderCellDef> Stop</th>-->
    <!--      <td mat-cell *matCellDef="let element">-->
    <!--        &lt;!&ndash; Suite is stopped ** Cancel execution of the suite? &ndash;&gt;-->

    <!--        <button mat-raised-button color="warn"-->
    <!--                (click)="setDefaultValue(element);$event.stopPropagation();"-->
    <!--                class="hoverBlue stop-button-min-width">-->

    <!--          <mx-icon mx="stop"></mx-icon>-->
    <!--          Set Default-->
    <!--        </button>-->
    <!--      </td>-->
    <!--    </ng-container>-->


    <tr class="row-header" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="row-style" mat-row *matRowDef="let row; columns: displayedColumns; let i = index"
        (click)="clickedItem(row, i)"></tr>

  </table>
</div>
<spinner-for-table [loading]="isLoading"></spinner-for-table>

<mat-paginator
  *ngIf="!hidePaginator"
  [pageSizeOptions]="pageSizeOptions"
  [length]="pagesInfo.totalElements"
  [pageIndex]="pageParams.page.value"
  [pageSize]="pageParams.size.value"
  (page)="setPageSizeOrIndex($event)"
  showFirstLastButtons></mat-paginator>
