import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-file-create-case-dialog',
  templateUrl: './view-file-create-case-dialog.component.html',
  styleUrls: ['./view-file-create-case-dialog.component.css']
})
export class ViewFileCreateCaseDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ViewFileCreateCaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  viewInWorkspace(): void {
    this.dialogRef.close('view');
  }

  createCase(): void {
    this.dialogRef.close('create');
  }
}
