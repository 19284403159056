<h1 mat-dialog-title xmlns="http://www.w3.org/1999/html">AI Settings</h1>

<div mat-dialog-content>

  <div *ngIf="aiConfig != null" class="mb-3 input-box">
    <mat-form-field class="form-field-full-width w-100">
      <mat-select placeholder="Vendor"
                  (selectionChange)="changedVendor($event)"
                  [(value)]="aiConfig.vendor">
        <mat-option value="OpenAI">Open AI</mat-option>
        <mat-option value="Claude" [disabled]="true">Claude</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <label *ngIf="aiConfig != null">Key</label>
  <div *ngIf="aiConfig != null" class="mb-3 input-box">
    <input type="text" [(ngModel)]="aiConfig.key">
    <mx-icon mx="information" color="primary" class="ml-1 cursor-pointer"
             [matTooltip]="'This value overrides the value from the configuration parameters that the project administrator could have set for the entire project.'"></mx-icon>
  </div>

  <label *ngIf="aiConfig != null "> Model</label>
  <div *ngIf="aiConfig != null" class="mb-3 input-box">
    <input type="text" [(ngModel)]="aiConfig.model">
    <mx-icon mx="information" color="primary" class="ml-1 cursor-pointer"
             [matTooltip]="'This value overrides the value from the configuration parameters that the project administrator could have set for the entire project.'"></mx-icon>
  </div>

  <label *ngIf="aiConfig != null " style="text-align: center;"> Choose a conversation style</label>
  <div class="conversation-style" *ngIf="aiConfig != null">
    <div class="mat-button-toggle-group-wrapper">
      <mat-button-toggle-group name="aiTemperature" [(ngModel)]="aiConfig.temperature" aria-label="AI Temperature">
        <mat-button-toggle value="0.9">
          <diV class="conversation-option">
            <div class="more-word">More</div>
            <div class="important-word">Creative</div>
          </diV>
        </mat-button-toggle>
        <mat-button-toggle value="0.5">
          <div class="conversation-option">
            <div class="more-word">More</div>
            <div class="important-word">Balanced</div>
          </div>
        </mat-button-toggle>
        <mat-button-toggle value="0.1">
          <div class="conversation-option">
            <div class="more-word">More</div>
            <div class="important-word">Precise</div>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

  </div>


</div>

<div mat-dialog-actions>
  <button id="closeButton" mat-button (click)="closeDialog()">Cancel</button>
  <button mat-button (click)="updateAIOption()" color="primary">Update</button>
</div>

