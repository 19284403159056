import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { AppRoutingModule, routes } from './app-routing.module';
import { ApiService } from './shared/services/api.service';
import { ConfigService } from './shared/services/config.service';
import { UserService } from './layout/users/shared/services/user.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './shared/guard/auth.guard';
import { CaseService } from './layout/cases/shared/services/case.service';
import { CompileService } from './layout/cases/shared/services/compile.service';
import { RunService } from './layout/runs/shared/services/run.service';

import { SuiteService } from './layout/suites/shared/services/suite.service';
import { FileService } from './shared/services/file.service';
import { AdminGuard } from './shared/guard/admin.guard';
import { GlobalEventsManagerService } from './shared/services/global-events-manager.service';
import { EqualValidator } from './shared/validators/equal-validator.directive';
import { CanDeactivateGuard } from './shared/can-deactivate/can-deactivate.guard';
import { AuthInterceptor } from './shared/services/auth-interceptor.service';
import { AngularFireModule } from '@angular/fire';
import { OnlyNumber } from './shared/util-directive/only-number/only-number.directive';
import { EnumToArrayPipe } from './shared/util-directive/enum-to-array/enum-to-array.pipe';
import { RouterModule } from '@angular/router';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

import { environment } from '../environments/environment';
import { CasesGuard } from './shared/guard/cases.guard';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NavbarModule } from './layout/navbar/navbar.model';
import { IdleDialogModule } from './layout/dialogs/idle-dialog/idle-dialog.module';
import { ConsoleToggleServiceService } from "./shared/services/console-toggle-service.service";
import { ErrorModule } from "./layout/mx/error/error.module";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RunAdvanceSettingsModule } from "./layout/runs/components/run-advance-settings/run-advance-settings.module";
import {
  ViewFileCreateCaseDialogComponent
} from './layout/runs/components/view-file-create-case-dialog/view-file-create-case-dialog.component';
import {
  AddProjectAIParamDialogComponent
} from './layout/users/components/add-project-aiparam-dialog/add-project-aiparam-dialog.component';
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";

export function initUserFactory(userService: UserService) {
  return () => userService.initUser();
}

@NgModule({
  declarations: [
    AppComponent,
    EqualValidator,
    OnlyNumber,
    EnumToArrayPipe,
    ViewFileCreateCaseDialogComponent,
    AddProjectAIParamDialogComponent
  ],
  imports: [
    IdleDialogModule,
    NavbarModule,
    AngularFireModule.initializeApp(environment.firebase),
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'corrected' }),
    NgxAuthFirebaseUIModule.forRoot(
      environment.firebase,
      undefined,
      {
        enableFirestoreSync: true,
        toastMessageOnAuthSuccess: false,
        toastMessageOnAuthError: true,
        passwordMinLength: 6
      }
    ),
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    ErrorModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    RunAdvanceSettingsModule,
    FormsModule,
    MatCheckboxModule,
  ],
  exports: [RouterModule, OnlyNumber],
  providers: [
    ApiService,
    UserService,
    ConfigService,
    CaseService,
    SuiteService,
    CompileService,
    FileService,
    RunService,
    AuthGuard,
    AdminGuard,
    CasesGuard,
    GlobalEventsManagerService,
    ConsoleToggleServiceService,
    CanDeactivateGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initUserFactory,
      deps: [UserService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
