<section class="main-container">
  <app-project-main-tab-options
    [checkPaths]="getProjectMainTabOptionsCheckPathObject()"

    (unauthorizedRequests)="unauthorizedRequests()"
    (export)="exportProject()"
    (import)="importProject()"
    (restartEngine)="restartEngine()">

  </app-project-main-tab-options>

  <div class="data-wrapper">

    <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

    <div class="data">
      <label class="data_label">Project name: </label>
      <span class="data_span">{{ projectName() }}</span>
      <button mat-icon-button (click)="openEditDialog('input','Name:','name')"
              *ngIf="checkPaths.updateProjectName.enabled">
        <mx-icon class="pencil-icons" mx="edit"></mx-icon>
      </button>
    </div>

    <div class="data">
      <label class="data_label">Project ID: </label>
      <span class="data_span">{{ tokensStorage.getProjectId() }}</span>
    </div>

    <div class="data">
      <label class="data_label">Description: </label>
      <pre class="data_span">
        <div [innerHTML]="replaceStringWithNewLines(userProject.project.description)"></div>
      </pre>
      <button mat-icon-button *ngIf="checkPaths.updateDescription.enabled"
              (click)="openEditDialog('textarea','Description:','description')">
        <mx-icon class="pencil-icons" mx="edit"></mx-icon>
      </button>
    </div>

    <!--    TODO: FINISH DELETE PROJECT -->
    <!--        <div class="data">-->
    <!--          <label class="data_label">Delete project: </label>-->
    <!--          <app-link class="data_span" [text]="'Delete'"-->
    <!--                    (clickLink)="openDeleteDialog()"></app-link>-->
    <!--        </div>-->

    <div class="data">
      <label class="data_label">Public project: </label>
      <span class="data_span" style="overflow: visible">
          <mat-checkbox class="public-project-cb" *ngIf="checkPaths.publicProject.enabled"
                        [disabled]="!this.userProject.user.enabledPrivateProjects"
                        [checked]="userProject.project.publicProject" (click)="checkedPublicProjectCheckbox($event)">
          </mat-checkbox>
        <mat-checkbox class="public-project-cb" *ngIf="!checkPaths.publicProject.enabled" [disabled]="true"
                      [checked]="userProject.project.publicProject">

        </mat-checkbox>
        </span>
    </div>

    <!--    <div class="data">-->
    <!--      <label class="data_label">Export project: </label>-->
    <!--      <span class="data_span" style="overflow: visible">-->
    <!--        <button mat-raised-button (click)="exportProject()">Export project</button>-->
    <!--      </span>-->
    <!--    </div>-->
    <!--    <div class="data">-->
    <!--      <label class="data_label">Import project: </label>-->
    <!--      <span class="data_span" style="overflow: visible">-->
    <!--        <button mat-raised-button (click)="importProject()">Import project</button>-->
    <!--      </span>-->
    <!--    </div>-->

    <div class="data">
      <label class="data_label">Engine status: </label>
      <span class="data_span" style="overflow: visible">
          <button class="sensor-btn"
                  [ngClass]="{
                      'stopped-color': engineStatus == 'REMOVED' && !restartEngineSpinner,
                      'initializing-color': engineStatus == 'INITIALIZING' || restartEngineSpinner,
                      'running-color': engineStatus == 'RUNNING' && !restartEngineSpinner
                  }"
                  mat-mini-fab (click)="checkPaths.restartEngine.enabled? restartEngine() : $event.stopPropagation()"
                  [matTooltip]=" engineStatus == 'INITIALIZING' ? 'Restarting' : 'Restart'">

<!--            <mx-icon-->
            <!--              *ngIf="checkPaths.restartEngine.enabled && !restartEngineSpinner"-->
            <!--              mx="restart"-->
            <!--              color="white"-->
            <!--              aria-label="restart engine"></mx-icon>-->

            <mat-spinner *ngIf="restartEngineSpinner" class="sensor-spinner" [strokeWidth]="2"
                         [diameter]="18"></mat-spinner>
          </button>
        </span>
    </div>

    <div class="data" *ngIf="storagePasswordFetched">
      <label class="data_label">
        Mount workspace:
      </label>
      <span class="data_span">
        <div style="margin-bottom: 20px;">
          <div class="unselectable">
            Windows:
          </div>
          <div style="display: inline;">
            <span>net use x: \\{{getStorageAddress()}}\{{tokensStorage.getProjectId()}} {{storagePassword}}
              /user:{{tokensStorage.getProjectId()}}</span>
          </div>
        </div>
        <div>
          <div class="unselectable">
            Linux:
          </div>
          <div style="display: inline;">
            <span>mount -t cifs -w -o username={{tokensStorage.getProjectId()}} -o password={{storagePassword}} -o dir_mode=0777 -o file_mode=0777 \\\\{{getStorageAddress()}}
              \\{{tokensStorage.getProjectId()}} /mnt/{{tokensStorage.getProjectId()}}</span>
          </div>
        </div>
      </span>
    </div>

  </div>
</section>

