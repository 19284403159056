import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AiChatService} from '../../shared/services/ai-chat.service';
import {AiChat} from '../../shared/models/aiChat';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-ngx';
import {MatDialog} from '@angular/material/dialog';
import {ExportAiChatComponent} from '../export-ai-chat/export-ai-chat.component';
import {GlobalEventsManagerService} from '../../../../shared/services/global-events-manager.service';
import {
  AddProjectParamDialogComponent
} from '../../../users/dialogs/add-project-param-dialog/add-project-param-dialog.component';
import {ProjectParamsService} from '../../../users/shared/services/project-params.service';
import {AiInputComponent} from '../ai-input/ai-input.component';
import {Subscription} from "rxjs";
import {MaxtafTokensStorageService} from "../../../../shared/services/maxtaf-tokens-storage.service";
import {TemplatePortal} from "@angular/cdk/portal";
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {AiOptionsDialogComponent} from "../ai-options-dialog/ai-options-dialog.component";
import {UserService} from "../../../users/shared/services/user.service";
import {ChatSession} from "../../shared/models/chatSession";
import {AiSessionsListComponent} from "../ai-sessions-list/ai-sessions-list.component";
import {ChatSessionComponent} from "../chat-session/chat-session.component";
import {ChatSessionFilter} from "../../shared/models/chatSessionFilter";
import {AiConstService, ChatSessionSearchParams} from "../../shared/services/ai-const.service";
import {AiDeleteChatSessionsComponent} from "../ai-delete-chat-sessions/ai-delete-chat-sessions.component";
import {ChatSessionHelper} from "../../../../shared/models/chatSessionHelper";


@Component({
  selector: 'app-ai-wrapper',
  templateUrl: './ai-wrapper.component.html',
  styleUrls: ['./ai-wrapper.component.css']
})
export class AiWrapperComponent implements OnInit {
  chat: AiChat[] = [];
  activeChat: AiChat = null;
  @ViewChild('pageScroll') public pageScroll: OverlayScrollbarsComponent;
  @ViewChild('chatArea') public chatAreaViewChild: ElementRef;
  @ViewChild('aiInputWrapper') public aiInputAreaViewChild: ElementRef;
  @ViewChild('inputArea') public inputAreaViewChild: ElementRef;
  @ViewChild('aiInput') public aiInput: AiInputComponent;

  @ViewChild('aiSessionsListComponent') public aiSessionsListComponent: AiSessionsListComponent;
  @ViewChild('aiChatSession') public chatSessionComponent: ChatSessionComponent;

  @Output('hideAI') closeEvent = new EventEmitter<any>();
  @Input('resizeAIMode') resizeAIMode = false;

  // subscribe;
  disableInput = false;
  errorMessage = '';


  maximumNumberOfRequests = 20;
  currentNumberOfRequests = 1;
  projectParamValue = '';
  chatHeight = 400;

  selectedChatSession: ChatSession = undefined;
  probableSelectedChatSessionTestName: string = undefined;
  probableSelectedChatSession: ChatSession = undefined;

  inputActive = false;
  @Output('resizeAI') resizeAIEvent = new EventEmitter<any>();
  private esAIChatAskQuestion: EventSource;

  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  sub: Subscription;
  showLoadResponseDiv = false;
  isRunActive = false;
  aiRequest: Subscription;

  chatSessionSearchParams: ChatSessionSearchParams;

  relatedSessionPurposeId: string = null;
  relatedSessionPurposeName: string = null;

  constructor(
    private aiChatService: AiChatService,
    public globalEventsManagerService: GlobalEventsManagerService,
    private projectParamService: ProjectParamsService,
    public tokensService: MaxtafTokensStorageService,
    public overlay: Overlay,
    public dialog: MatDialog,
    public tokensStorage: MaxtafTokensStorageService,
    public aiConstService: AiConstService,
    public viewContainerRef: ViewContainerRef,
    public userService: UserService
  ) {
    this.globalEventsManagerService
      .refreshAIChatEmitter
      .subscribe((userProjectId) => {

        this.selectedChatSession = null;
        this.chatSessionSearchParams = AiConstService.getDefaultChatSessionSearchParams();

        // this.refreshData();

        // this.getContent();
        // setTimeout(() => {
        //   this.refreshData();
        // }, 500);
      });

    this.globalEventsManagerService.maxAIRequests.subscribe((mode) => {
      this.maximumNumberOfRequests = mode;
      this.setMessageType();
    });

    this.globalEventsManagerService.currentAiRequests.subscribe((mode) => {
      this.currentNumberOfRequests = mode;
      this.setMessageType();
    });

    this.globalEventsManagerService.aiKey.subscribe((mode) => {
      this.projectParamValue = mode;
      this.setMessageType();
    });

    this.globalEventsManagerService.relatedSessionPurposeEmitter.subscribe(
      (mode: ChatSessionHelper) => {

        try {
          this.setRelatedSessionPurpose(mode);
        } catch (e) {

        }

      });

    this.chatSessionSearchParams = AiConstService.getDefaultChatSessionSearchParams();
  }


  setRelatedSessionPurpose(helper: ChatSessionHelper) {
    if (helper != null) {
      this.relatedSessionPurposeId = helper.caseId;
      this.relatedSessionPurposeName = helper.caseName;

      this.setRelatedSessionPurposeWithId(this.relatedSessionPurposeId, this.relatedSessionPurposeName);
    }

  }

  probableCloseAIChat = false;

  setRelatedSessionPurposeWithId(purposeId: string, purposeName: string) {
    if (purposeId == null) {
      this.probableSelectedChatSession = null;
      if (this.selectedChatSession != null) {
        // this.chatSessionComponent.getChatRecordsForSession();

        // if (this.selectedChatSession.sessionPurposeRelatedId != null) {
        //   this.selectedChatSession = null;
        // }

        this.probableCloseAIChat = true;

      } else {
        this.aiSessionsListComponent.setRelatedSessionPurpose(null);
      }

    } else {
      console.log('setRelatedSessionPurposeWithId:  purposeId: ' + purposeId);
      this.probableCloseAIChat = false;
      this.aiChatService.getChatSessionByPurpose(
        "CASE",
        purposeId,
        "'" + purposeName + "' Assistant"
      ).subscribe(
        (chatSession: ChatSession) => {


          if (this.selectedChatSession != null) {
            // this.chatSessionComponent.getChatRecordsForSession();
            // this.selectedChatSession = chatSession;
            if (this.probableSelectedChatSession != null && chatSession.id == this.probableSelectedChatSession.id) {
              this.probableSelectedChatSessionTestName = null;
              this.probableSelectedChatSession = null;
            } else {
              this.probableSelectedChatSessionTestName = purposeName;
              this.probableSelectedChatSession = chatSession;
            }

          } else {
            this.probableSelectedChatSessionTestName = purposeName;
            this.probableSelectedChatSession = chatSession;
            this.aiSessionsListComponent.setRelatedSessionPurpose(chatSession);
          }
        },
        error => {
          console.error('error ', error);
        }
      );
    }
  }

  switchToNewAiChat() {
    this.selectedChatSession = this.probableSelectedChatSession;
    this.probableSelectedChatSession = undefined;
    this.probableSelectedChatSessionTestName = undefined;
  }

  returnToAiSessionList() {
    this.selectedChatSession = null;
    this.probableSelectedChatSession = undefined;
    this.probableSelectedChatSessionTestName = undefined;
    this.probableCloseAIChat = false;
  }

  closeIdInfoDiv() {
    this.probableSelectedChatSession = undefined;
    this.probableSelectedChatSessionTestName = undefined;
    this.probableCloseAIChat = false;
  }

  scrollToBottom(duration = 500) {
    this.chatSessionComponent.scrollToBottom();
  }

  ngOnInit(): void {
    this.getContent();
  }

  ngOnDestroy() {
    this.stopAIRequest();
  }

  resizeAI() {
    this.resizeAIEvent.emit();
  }

  getChatHeight(): number {
    try {
      const divHeight = this.chatAreaViewChild.nativeElement.offsetHeight;
      return divHeight;
    } catch (e) {

    }

    return this.chatHeight;
  }

  inputHeight: number = 98;

  private rowHeights: number[] = [98, 98, 98, 98, 114, 130, 146, 161, 177, 193, 209];

  // private rowHeights: number[] = [88, 88, 88, 88, 104, 120, 136, 151, 167, 183, 199];


  setInputHeight(rows: number): void {
    if (rows < 2) {
      this.inputHeight = this.rowHeights[2];
    }
    if (rows > 10) {
      this.inputHeight = this.rowHeights[10];
    }
    this.inputHeight = this.rowHeights[rows];
  }

  getAiInputHeight() {
    //73
    // 88
    // 104
    // 120
    // 136
    // 151
    // 167
    // 183
    // 199

    try {
      const divHeight = this.aiInputAreaViewChild.nativeElement.offsetHeight;
      return divHeight;
    } catch (e) {

    }

    return 91;
  }

  getInputDivHeight() {

    try {
      const divHeight = this.inputAreaViewChild.nativeElement.offsetHeight;
      return divHeight;
    } catch (e) {

    }

    return 91;
  }

  setMessageType() {
    if (this.projectParamValue == null || this.projectParamValue.trim() == '') {
      if (this.currentNumberOfRequests < this.maximumNumberOfRequests) {
        if (this.errorMessage.startsWith("You have reached the daily limit of")) {
          this.errorMessage = '';
          this.disableInput = false;
        }
      } else {
        this.errorMessage = "You have reached the daily limit of " + this.maximumNumberOfRequests + " requests using the MaxTAF Cloud AI key. If you would like to use your own openAI key.";
        this.disableInput = true;
      }
    } else {
      if (this.errorMessage.startsWith("You have reached the daily limit of")) {
        this.errorMessage = '';
        this.disableInput = false;
      }
    }
  }

  closeMessage() {
    this.errorMessage = '';
  }

  openAddOrEditProjectParamDialog(paramId = 'mx.ai.openai.key') {
    this.projectParamService.getProjectParam(paramId)
      .subscribe(
        projectParam => {

          const dialogRef = this.dialog.open(AddProjectParamDialogComponent, {
            width: '500px',
            data: {
              projectParam: projectParam
            }
          });

          dialogRef.afterClosed().subscribe(result => {

          });
        },
        error => {
          console.error(error);

        }
      );
  }


  response = '';

  stopAIRequest() {
    try {
      // if (this.aiRequest) {
      //   this.aiRequest.unsubscribe();
      // }
      this.isRunActive = false;
      this.showLoadResponseDiv = false;
      // // this.esAIChatAskQuestion.close();
      this.chatSessionComponent.stopAIRequest();
    } catch (e) {
      console.error('error: ', e);
    }
  }

  creatingChatSession = false;

  createChatSession(question: string, caseId: string, caseName: string) {
    this.creatingChatSession = true;
    this.clickedSession(null);
    if (caseId != null) {
      this.aiChatService.getChatSessionByPurpose("CASE", caseId, "'" + caseName + "' Assistant")
        .subscribe(
          chatSession => {
            try {
              this.clickedSession(chatSession);

              this.askQuestion(question, chatSession.id);
              this.creatingChatSession = false;
            } catch (e) {
              this.creatingChatSession = false;
            }
          },
          error => {
            console.error(error);
            this.creatingChatSession = false;
          }
        );
    } else {
      this.aiChatService.createChatSession(new ChatSession())
        .subscribe(
          chatSession => {
            try {
              this.clickedSession(chatSession);

              this.askQuestion(question, chatSession.id);
              this.creatingChatSession = false;
            } catch (e) {
              this.creatingChatSession = false;
            }
          },
          error => {
            console.error(error);
            this.creatingChatSession = false;
          }
        );
    }

  }


  getContent(changeValueOfLoadAIResponse = false) {
    if (this.tokensStorage.getUserId() == null || this.tokensStorage.getUserId() == '') {
      return;
    }

    this.refreshData();

  }

  addToChatArray(res: AiChat[]): void {
    res.forEach(r => {
      if (!this.chat.some(c => c.id === r.id)) {
        this.chat.push(r);
      }
    });

    this.chat = this.chat.filter(c => res.some(r => r.id === c.id));
  }


  private askQuestion(question: string, sessionId: string) {
    // setTimeout(() => {
    this.chatSessionComponent.askQuestion(
      question,
      sessionId,
      this.selectedChatSession == null || !this.addCode ? null : this.selectedChatSession.sessionPurposeRelatedId
    );
    // }, 1000);
  }

  public openAiPromptForCase(caseId: string) {

    if (this.selectedChatSession == null || this.selectedChatSession.sessionPurposeRelatedId != caseId) {
      if (
        caseId == null
      ) {
        this.selectedChatSession = null;
      } else {
        this.creatingChatSession = true;
        this.aiChatService.getChatSessionByPurpose("CASE", caseId, "")
          .subscribe(
            chatSession => {
              try {
                this.clickedSession(chatSession);

                this.creatingChatSession = false;
              } catch (e) {
                this.creatingChatSession = false;
              }
            },
            error => {
              console.error(error);
              this.creatingChatSession = false;
            }
          );
      }
    }
  }


  public addToPrompt(question: string, caseId: string, caseName: string) {
    // setTimeout(() => {
    //   this.scrollToBottom();
    // }, 500);

    if (this.selectedChatSession != null) {
      if (
        caseId == null ||
        this.selectedChatSession.sessionPurposeRelatedId == caseId
      ) {
        this.inputActive = true;
        this.aiInput.addToPrompt(question);
      } else {
        this.aiChatService.getChatSessionByPurpose("CASE", caseId, "'" + caseName + "' Assistant")
          .subscribe(
            chatSession => {
              try {
                this.clickedSession(chatSession);

                this.inputActive = true;
                this.aiInput.addToPrompt(question);
                this.creatingChatSession = false;
              } catch (e) {
                this.creatingChatSession = false;
              }
            },
            error => {
              console.error(error);
              this.creatingChatSession = false;
            }
          );
      }
    } else {
      this.aiChatService.createChatSession(new ChatSession())
        .subscribe(
          chatSession => {
            try {
              this.clickedSession(chatSession);

              this.inputActive = true;
              this.aiInput.addToPrompt(question);
              this.creatingChatSession = false;
            } catch (e) {
              this.creatingChatSession = false;
            }
          },
          error => {
            console.error(error);
            this.creatingChatSession = false;
          }
        );
    }


  }

  public askAndSaveQuestion(question: string, caseId: string = undefined, caseName: string = undefined) {
    this.inputHeight = this.rowHeights[2];
    if (this.selectedChatSession) {
      if (
        caseId == null ||
        this.selectedChatSession.sessionPurposeRelatedId == caseId
      ) {
        this.askQuestion(question, this.selectedChatSession.id);
      } else {
        this.createChatSession(question, caseId, caseName);
      }

    } else {
      this.createChatSession(question, caseId, caseName);
    }
  }

  hideShowVisibility() {
    this.selectedChatSession = null;
    this.closeEvent.emit();
  }

  exportAIChat() {
    const dialogRef = this.dialog.open(ExportAiChatComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }


  deleteAIChats() {
    this.aiChatService.deleteChatSessions(new ChatSessionFilter()).subscribe(res => {

      this.refreshData();
    }, error => {
      // this.showLoadResponseDiv = false;
      console.error('error: ', error);
    });
  }

  openDeleteDialog() {
    let title = ''
//  chatSessionSearchParams: ChatSessionSearchParams;
    if (this.selectedChatSession != null) {
      title = 'Are you sure you want to delete the chat titled \'' + this.selectedChatSession.title + '\'?'
    } else {
      title = 'Are you sure you want to delete all chats?'
    }

    const dialogRef = this.dialog.open(AiDeleteChatSessionsComponent, {
      width: '500px',
      data: {
        chatSessionSearchParams: this.chatSessionSearchParams,
        selectedChatSession: this.selectedChatSession,
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == true) {
        // Discard changes;
        this.selectedChatSession = null;
        this.chatSessionSearchParams = AiConstService.getDefaultChatSessionSearchParams();
        this.refreshData(this.chatSessionSearchParams);
        // if (this.selectedChatSession != null) {
        //   this.deleteAIChat();
        // } else {
        //   this.deleteAIChats();
        // }
      }
    });
  }

  refreshData(chatSessionSearch?: ChatSessionSearchParams) {
    if (this.selectedChatSession != null) {
      this.chatSessionComponent.getChatRecordsForSession();
    } else {
      this.aiSessionsListComponent.getChatSessions(chatSessionSearch);
    }
    // this.getContent();
  }

  // refreshDataAndAsk(newChat) {
  //   this.addToChatArray(newChat);
  //   setTimeout(() => {
  //     this.scrollToBottom();
  //   }, 500);
  //
  //   this.askQuestionWithoutContent();
  // }

  inputActiveChanges(inputActive: boolean) {
    this.inputActive = inputActive;
    // setTimeout(() => {
    //   this.scrollToBottom();
    // }, 500);
  }


  openOptionsContextMenu(event: MouseEvent/*, node: MaxtafFileNode*/) {
    // event.type = 'contextmenu';
    this.closeContextMenu();

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(event)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);


    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    // this.overlayRef = this.overlay.create();

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: "node"
    }));

  }

  closeContextMenu() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  menuRefreshAi() {
    this.refreshData();
    this.closeContextMenu();
  }

  menuDeleteAi() {
    this.openDeleteDialog();
    this.closeContextMenu();
  }

  menuExportAi() {
    this.exportAIChat();
    this.closeContextMenu();
  }

  menuAiSetting() {
    this.openSettingsDialog();
    this.closeContextMenu();
  }

  private openSettingsDialog() {
    const dialogRef = this.dialog.open(AiOptionsDialogComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {


    });
  }

  public openedAI() {
    // this.scrollToBottom(100);
    // setTimeout(() => {
    //   this.scrollToBottom(0);
    // }, 100);

    // this.scrollY(100000);
  }


  clickedSession(chatSession: ChatSession) {
    this.selectedChatSession = chatSession;
  }

  openChatSessions() {
    this.setRelatedSessionPurposeWithId(this.relatedSessionPurposeId, this.relatedSessionPurposeName);
    this.selectedChatSession = null;
  }

  showChatSessionSearch = false

  setSearchChatSessions() {
    this.showChatSessionSearch = !this.showChatSessionSearch;
  }

  hideSearchChatSessions() {
    this.showChatSessionSearch = false;
  }

  filterSearchChatSessions(chatSession: ChatSessionSearchParams) {
    if (this.selectedChatSession == null) {
      this.aiSessionsListComponent.getChatSessions(chatSession);
    }
    this.hideSearchChatSessions();
  }

  newSearchChatSessionsParams(chatSession: ChatSessionSearchParams) {
    this.chatSessionSearchParams = chatSession;
  }


  addCode = false;

  addCaseCodeToQuestion(addCode: boolean) {
    this.addCode = addCode;
  }

  protected readonly undefined = undefined;
}
