import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {EditDialogComponent} from '../../../dialogs/edit-dialog/edit-dialog.component';
import {UserProject} from '../../shared/models/userProject';
import {SecurityGroupService} from '../../shared/services/security-group.service';
import {SecurityGroup} from '../../shared/models/securityGroup';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {UserService} from '../../shared/services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GlobalEventsManagerService} from '../../../../shared/services/global-events-manager.service';
import {FormControl} from '@angular/forms';
import {User} from '../../shared/models/user';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';
import {ProjectService} from '../../../projects/shared/services/project.service';
import {ApiKeyService} from "../../shared/services/api-key.service";
import {UserPageParams, UserParamSearch, UserPreferencesParamSearch} from "../../shared/services/user-const.service";
import {ClickedTableItem} from "../../../mx/util/list/ClickedTableItem";
import {UserPreferencesParam} from "../../shared/models/userPreferencesParam";
import {PageParams} from "../../../mx/util/params/PageParams";
import {
  AddUserPreferenceParamDialogComponentComponent
} from "../add-user-preference-param-dialog-component/add-user-preference-param-dialog-component.component";
import {
  UserPreferenceParamsOptionsPaths
} from "../user-preference-params-options/user-preference-params-options.component";
import {UserPreferenceParamsListComponent} from "../user-preference-params-list/user-preference-params-list.component";
import {UserParam} from "../../shared/models/userParams";
import {UserParamsOptionsPaths} from "../user-params-options/user-params-options.component";
import {PageInfo} from "../../../../shared/models/pageInfo";
import {AddEditUserParamDialogComponent} from "../add-edit-user-param-dialog/add-edit-user-param-dialog.component";

class UserCheckPaths {
  updateParams = new CheckPath();
  updateRoles = new CheckPath();
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  className = 'UserComponent';

  @Input('shadow') shadow = true;
  bridgeActive = false;
  bridgeActiveSpinner = true;

  userProject: UserProject = new UserProject();

  userParamsList: UserParam[] = [];
  userParamsListPage: UserParam[] = [];
  userParamsListPagesInfo: PageInfo = new PageInfo();
  isLoadingResultsUserParamsList = false;

  selectedSecurityGroups = new FormControl();
  oldSecurityGroups = new FormControl();

  ownerDisabled = false;

  showErrorAlert = false;

  @ViewChild(UserPreferenceParamsListComponent) userPreferenceParamsListComponent: UserPreferenceParamsListComponent;

  @Input('userProject') set _user(userProject: UserProject) {
    this.userProject = userProject;
    if (this.userProject.id != undefined) {
      this.refreshBridgeStatus();
    }

    this.refreshUserParams();
    this.userProject.securityGroups.forEach(securityGroup => {
      // if (securityGroup.name != 'ADMIN' && securityGroup.name != 'SYSTEM') {
      //   this.ownerDisabled = true;
      // } else {
      //   this.ownerDisabled = false;
      // }
    });
  }

  @Input('securityGroups') securityGroups: SecurityGroup[];

  @Input('allowEditSecurityGroups') allowEditSecurityGroups = true;
  @Input('allowEditName') allowEditName = false;

  @Output('getUser') getUser = new EventEmitter<any>();

  @Output('error') error = new EventEmitter<any>();

  @Input('editPassword') editPassword = false;
  @Input('params') params: UserPageParams;
  @Output('paramsChanges') paramsChanges = new EventEmitter<UserPageParams>();

  userDetailsShow = true;

  @Input('userDetailsShow') set _userDetailsShow(res) {
    this.userDetailsShow = res == true || res == 'true';
  }

  userProjectDetailsShow = true;

  @Input('userProjectDetailsShow') set _userProjectDetailsShow(res) {
    this.userProjectDetailsShow = res == true || res == 'true';
  }

  checkPaths: UserCheckPaths;

  constructor(
    public dialog: MatDialog,
    public securityGroupService: SecurityGroupService,
    public tokensService: MaxtafTokensStorageService,
    private userProjectConfigService: UserProjectConfigService,
    private userService: UserService,
    private projectService: ProjectService,
    public snackBar: MatSnackBar,
    public checkRolesService: CheckRolesService,
    private globalEventsManagerService: GlobalEventsManagerService,
    private apiKeyService: ApiKeyService,
  ) {
    this.checkPaths = this.getUserCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );
  }

  ngOnInit(): void {
    // this.securityGroupService.getAll('').subscribe(
    //   res => {
    //     this.securityGroups = res;
    //
    //     // this.selectedSecurityGroups.setValue(this.user.securityGroups);
    //     // this.selectedSecurityGroups.setValue(this.securityGroups);
    //
    //     const newSelectedSecurityGroup = [];
    //
    //     this.userProject.securityGroups.forEach(selectedSecurityGroup => {
    //       for (const securityGroup of this.securityGroups) {
    //         if (selectedSecurityGroup.name == securityGroup.name) {
    //           newSelectedSecurityGroup.push(securityGroup);
    //           break;
    //         }
    //       }
    //     });
    //
    //     this.selectedSecurityGroups.setValue(newSelectedSecurityGroup);
    //
    //     if (this.userProject.user.id == this.userService.getUserId()) {
    //       this.allowEditSecurityGroups = false;
    //     }
    //   },
    //   error => {
    //     console.error(error);
    //   }
    // );
    //
    // this.globalEventsManagerService.bridgeIndicatorEmitter.subscribe((mode) => {
    //   this.bridgeActive = mode;
    // });

  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }


  openUserProjectEditFieldDialog(type: string, name: string, nameField: string) {
    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '700px',
      data: {type: type, name: name, value: this.userProject[nameField]}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.userProject[nameField] = result;
        this.getUser.emit(this.userProject);
        this.updateUserProject(this.userProject);
      }
    });
  }


  updateUserProject(userProject: UserProject) {
    this.userService.putUserProject(userProject.id, userProject).subscribe(
      res => {

      }, error => {
        console.error(error);
        this.showError(error);
      }
    );
  }

  updateUser(user: User) {
    this.userService.putLikePatch(user.id, user).subscribe(
      res => {
      }, error => {
        console.error(error);
        this.showError(error);
      }
    );
  }


  refreshBridgeStatus() {
    this.bridgeActiveSpinner = true;
    this.userService.getBridgeAddress().subscribe(
      bridgeValue => {
        this.bridgeActive = bridgeValue != '';
        if (!this.userProjectDetailsShow) {
          this.openSnackBar('Bridge status refreshed', 'OK', 2);
        }
        this.bridgeActiveSpinner = false;
        this.globalEventsManagerService.setBridge(this.bridgeActive);
      },
      error => {
        console.error('error: ', error);
        this.error.emit(error);
      }
    );
  }


  openSnackBar(message: string, action: string, timeInSec: number) {
    const sec = 1000;
    this.snackBar.open(message, action, {
      duration: timeInSec * sec
    });
  }

  private getUserCheckPaths(): UserCheckPaths {
    return {
      updateParams: new CheckPath('PUT', this.userProjectConfigService.updateUserProject()),
      updateRoles: new CheckPath('PUT', this.userProjectConfigService.createOrUpdateOrDeleteUserProjectSecurityGroups()),
    };
  }


  clickedUserPreferenceParams(clickedItem: ClickedTableItem<UserPreferencesParam>) {
    this.openAddOrEditUserPreferenceParamDialog(clickedItem.item);
  }


  userPreferenceTabPageParamsChange(userPreferencePageParams: PageParams) {
    this.params.userPreferenceParamsTab.page = userPreferencePageParams;
    this.newParams();
  }

  userTabPageParamsChange(userPageParams: PageParams) {
    this.params.userParamsTab.page = userPageParams;
    this.newParams();
    this.refreshUserParams();
  }

  serPreferenceParamsTabSearchParamsChange(userPreferencesParamSearch: UserPreferencesParamSearch) {
    this.params.userPreferenceParamsTab.search = userPreferencesParamSearch;
    this.newParams();
  }

  serParamsTabSearchParamsChange(userParamSearch: UserParamSearch) {
    this.params.userParamsTab.search = userParamSearch;
    this.newParams();
    this.refreshUserParams();
  }

  newParams() {
    this.paramsChanges.emit(this.params);
  }

  openAddOrEditUserPreferenceParamDialog(userPreferencesParam: UserPreferencesParam) {
    const dialogRef = this.dialog.open(AddUserPreferenceParamDialogComponentComponent, {
      width: '500px',
      data: {
        userPreferencesParam,
        configuration: true,
        title: 'User preference param',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshUserPreferenceParams();
    });
  }


  refreshUserPreferenceParams() {
    this.userPreferenceParamsListComponent.refreshItems();
  }

  refreshUserParams() {
    if (this.userProject.userRunParams) {
      this.userParamsList = [];
      let lines = this.userProject.userRunParams.split('\n');

      for (let line of lines) {
        let parts = line.split('=');
        let userParam = new UserParam();
        userParam.name = parts[0];
        userParam.value = parts[1] || '';
        if (!(userParam.name.trim() === '' && userParam.value.trim() === '')) {
          this.userParamsList.push(userParam);
        }
      }

      this.sortUserParamsList();
    }

    this.createUserParamsPage();
  }

  private sortUserParamsList() {
    let sortParams = this.params.userParamsTab.page.sort.value.split(',');
    if (sortParams[0] === 'name') {
      this.userParamsList.sort((a, b) => {
        if (sortParams[1] === 'asc') {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
    } else if (sortParams[0] === 'value') {
      this.userParamsList.sort((a, b) => {
        if (sortParams[1] === 'asc') {
          return a.value.localeCompare(b.value);
        } else {
          return b.value.localeCompare(a.value);
        }
      });
    }
  }

  createUserParamsPage() {
    if (this.params != undefined && this.params.userParamsTab != null) {
      let searchParams = this.params.userParamsTab.search;
      let pageParams = this.params.userParamsTab.page;


      let filteredList = this.userParamsList.filter(userParam => {
        let matchesName = !searchParams.name.value || userParam.name.includes(searchParams.name.value);
        let matchesValue = !searchParams.value.value || userParam.value.includes(searchParams.value.value);
        return matchesName && matchesValue;
      });


      let totalElements = filteredList.length;
      let totalPages = Math.ceil(totalElements / pageParams.size.value);
      let pageIndex = pageParams.page.value;
      let sizeOfElementsOnPage = Math.min(pageParams.size.value, totalElements - pageIndex * pageParams.size.value);


      let start = pageIndex * pageParams.size.value;
      let end = start + sizeOfElementsOnPage;
      this.userParamsListPage = filteredList.slice(start, end);


      this.userParamsListPagesInfo = new PageInfo(
        pageIndex === 0, // isFirstPage
        pageIndex === totalPages - 1, // isLastPage
        pageIndex,
        sizeOfElementsOnPage,
        pageParams.size.value,
        totalElements,
        totalPages
      );
    }
  }

  openExportUserPreferenceParamsDialog() {

  }

  openImportInfoDialog() {

  }

  openImportUserPreferenceParamsDialog() {

  }

  getUserPreferenceParamsOptionsCheckPathObject(): UserPreferenceParamsOptionsPaths {
    return {
      filter: new CheckPath('GET', this.userProjectConfigService.getUserPreferencesParam()),
      create: new CheckPath('', ''),
      refresh: new CheckPath('GET', this.userProjectConfigService.getUserPreferencesParam()),
      export: new CheckPath('', ''),
      import: new CheckPath('', ''),
      delete: new CheckPath('', ''),
      reset: new CheckPath('', '')
    };
  }

  getUserParamsOptionsCheckPathObject(): UserParamsOptionsPaths {
    return {
      filter: new CheckPath('GET', this.userProjectConfigService.whoami()),
      create: new CheckPath('PUT', this.userProjectConfigService.updateUserProject()),
      refresh: new CheckPath('GET', this.userProjectConfigService.whoami()),
      export: new CheckPath('', ''),
      import: new CheckPath('', ''),
      delete: new CheckPath('', ''),
      reset: new CheckPath('', '')
    };
  }

  openExportUserParamsDialog() {

  }

  openUserParamsImportInfoDialog() {

  }

  openImportUserParamsDialog() {

  }


  userTabPageSetPageInfoChanges(pagesInfo: PageInfo) {
    this.userParamsListPagesInfo = pagesInfo;
  }

  clickedUserParams(clickedItem: ClickedTableItem<UserParam>) {
//edit
    this.openAddOrEditUserParamDialog(clickedItem.item);
  }

  deleteUserProjectParam(deletedUserParam: UserParam) {
    this.userParamsList = this.userParamsList.filter(userParam =>
      userParam.name !== deletedUserParam.name
    );


    this.userProject.userRunParams = this.userParamsList.map(userParam =>
      `${userParam.name}=${userParam.value || ''}`
    ).join('\n');

    this.refreshUserParams();

    this.updateUserProject(this.userProject);
  }

  private openAddOrEditUserParamDialog(userParam: UserParam) {
    const dialogRef = this.dialog.open(AddEditUserParamDialogComponent, {
      width: '500px',
      data: {
        userParam,
        configuration: false,
        title: 'User param'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.userParamsList = this.userParamsList.map(userParam =>
          userParam.name === result.name ? result : userParam
        );

        this.userProject.userRunParams = this.userParamsList.map(userParam =>
          `${userParam.name}=${userParam.value || ''}`
        ).join('\n');

        this.refreshUserParams();

        this.updateUserProject(this.userProject);
      }
      // this.refreshUserParams();
    });
  }


  createUserParam() {
    const dialogRef = this.dialog.open(AddEditUserParamDialogComponent, {
      width: '500px',
      data: {
        configuration: false,
        title: 'User param',
        userParamsList: this.userParamsList
      }
    });

    dialogRef.afterClosed().subscribe((result: UserParam) => {
      if (result != null) {
        this.userParamsList.push(result);

        this.userProject.userRunParams = this.userParamsList.map(userParam =>
          `${userParam.name}=${userParam.value || ''}`
        ).join('\n');

        this.refreshUserParams();

        this.updateUserProject(this.userProject);
      }
      // this.refreshUserParams();
    });
  }

  selectedIndexChange(index: number) {
    this.params.tabIndex.value = index;
    this.newParams();
  }


}

