<section class="main-container">

  <div class="row">

    <div class="col-md">
      <mat-form-field class="form-field-full-width">
        <mat-select placeholder="Filter done as exact match, or not" [(value)]="search.exactSearch">
          <mat-option value="Contains">Contains</mat-option>
          <mat-option value="Exact">Exact</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md">
      <button mat-raised-button class="mr-1 mb-1" (click)="filterRecords()" mat-button color="primary">
        <mx-icon marginBottom="true" mx="filter"></mx-icon>
        <span class="pl-1">Filter</span>
      </button>
      <button mat-raised-button class="mr-1 mb-1" (click)="clearFilter()" mat-button color="primary">
        <mx-icon marginBottom="true" mx="clear"></mx-icon>
        <span class="pl-1">Clear</span>
      </button>
      <button mat-raised-button class="mb-1" (click)="clickedClose()" mat-button color="primary">
        <mx-icon marginBottom="true" mx="close"></mx-icon>
        <span class="pl-1">Close</span>
      </button>
    </div>

  </div>

  <div class="row">

    <div class="col-md">
      <mat-form-field class="form-field-full-width">
        <input matInput type="text" placeholder="Email..." [disabled]="lockSearchParams.includes('email')"
               [(ngModel)]="search.email">
      </mat-form-field>
    </div>

    <div class="col-md" *ngIf="!userRequests">
      <mat-form-field class="form-field-full-width">
        <input matInput type="text" placeholder="Name..." [disabled]="lockSearchParams.includes('name')"
               [(ngModel)]="search.name">
      </mat-form-field>
    </div>

  </div>


  <!--  <div class="row">-->

  <!--    <div class="col-md">-->
  <!--      <mat-form-field class="form-field-full-width">-->
  <!--        <mat-select placeholder="Select securityGroup" [(value)]="search.securityGroup">-->
  <!--          <mat-option value="{{null}}">None</mat-option>-->
  <!--          <mat-option value="SECURITY_GROUP_USER">User</mat-option>-->
  <!--          <mat-option value="SECURITY_GROUP_ADMIN">Admin</mat-option>-->
  <!--        </mat-select>-->
  <!--      </mat-form-field>-->
  <!--    </div>-->
  <!--    -->
  <!--    <div class="col-md">-->
  <!--      <mat-form-field class="form-field-full-width">-->
  <!--        <input matInput type="text" placeholder="Last name..." [(ngModel)]="search.lastName">-->
  <!--      </mat-form-field>-->
  <!--    </div>-->

  <!--  </div>-->

  <!--<div class="row">-->

  <!--  <div class="col-md">-->
  <!--    <mat-form-field class="form-field-full-width">-->
  <!--      <mat-select placeholder="Select active" [(value)]="search.active">-->
  <!--        <mat-option value="{{null}}">Active and Inactive Users</mat-option>-->
  <!--        <mat-option value="{{true}}">Active Users</mat-option>-->
  <!--        <mat-option value="{{false}}">Inactive Users</mat-option>-->
  <!--      </mat-select>-->
  <!--    </mat-form-field>-->
  <!--  </div>-->

  <!--</div>-->
</section>
