<section class="main-container">


  <!--  <div class="list-page-with-shadow nonpadded-list">-->

  <mat-tab-group
    [selectedIndex]="params.tabIndex.value"
    (selectedIndexChange)="selectedIndexChange($event)">

    <mat-tab label="User">
      <ng-template matTabContent>
        <app-user-info
          [userProject]="userProject">

        </app-user-info>
      </ng-template>
    </mat-tab>

    <mat-tab label="User Params">
      <ng-template matTabContent>
        <app-user-params-options
          [searchParams]="params.userParamsTab.search"
          (searchParamsChange)="serParamsTabSearchParamsChange($event)"
          [checkPaths]="getUserParamsOptionsCheckPathObject()"

          (create)="createUserParam()"
          (filter)="refreshUserParams()"
          (import)="openImportUserParamsDialog()"
          (importInfo)="openUserParamsImportInfoDialog()"
          (export)="openExportUserParamsDialog()"
          (refresh)="refreshUserParams()">

        </app-user-params-options>


        <app-user-params-list
          [autoSetItems]="false"
          [configuration]="false"

          [items]="userParamsListPage"
          [pagesInfo]="userParamsListPagesInfo"
          [loading]="isLoadingResultsUserParamsList"

          [searchParams]="params.userParamsTab.search"
          [pageParams]="params.userParamsTab.page"
          (pageParamsChange)="userTabPageParamsChange($event)"
          (pagesInfoChanges)="userTabPageSetPageInfoChanges($event)"
          (refresh)="refreshUserParams()"
          (delete)="deleteUserProjectParam($event)"

          (clickedItem)="clickedUserParams($event)"
        >

        </app-user-params-list>
        <!--          <div class="data-wrapper" *ngIf="userProjectDetailsShow">-->

        <!--            &lt;!&ndash;            <mat-card [ngClass]="{'shadow': shadow, 'shadow-none': !shadow}">&ndash;&gt;-->
        <!--              <div-->
        <!--                *ngIf="(userProject.userRunParams == undefined || userProject.userRunParams == null || userProject.userRunParams == ''); else showParams"-->
        <!--                class="data">-->
        <!--                <label class="data_label">Parameters: </label>-->
        <!--                <label class="data_span">No parameters</label>-->
        <!--                <button mat-icon-button *ngIf="!checkPaths.updateParams.enabled"-->
        <!--                        (click)="openUserProjectEditFieldDialog('properties','Params:','userRunParams')">-->
        <!--                  <mx-icon mx="edit" class="edit-icon" hoverColor="primary" color="accent"></mx-icon>-->
        <!--                </button>-->
        <!--              </div>-->

        <!--              <ng-template #showParams>-->
        <!--                <div class="data">-->
        <!--                  <label class="data_label">Parameters: </label>-->
        <!--                  <pre class="data_span">{{userProject.userRunParams}}</pre>-->
        <!--                  <button mat-icon-button *ngIf="checkPaths.updateParams.enabled"-->
        <!--                          (click)="openUserProjectEditFieldDialog('properties','Params:','userRunParams')">-->
        <!--                    <mx-icon mx="edit" class="edit-icon" hoverColor="primary" color="accent"></mx-icon>-->
        <!--                  </button>-->
        <!--                </div>-->
        <!--              </ng-template>-->
        <!--            </mat-card>-->
        <!--          </div>-->
      </ng-template>
    </mat-tab>
    <mat-tab label="User Preference">
      <ng-template matTabContent>

        <app-user-preference-params-options
          [searchParams]="params.userPreferenceParamsTab.search"
          (searchParamsChange)="serPreferenceParamsTabSearchParamsChange($event)"
          [checkPaths]="getUserPreferenceParamsOptionsCheckPathObject()"


          (filter)="refreshUserPreferenceParams()"
          (import)="openImportUserPreferenceParamsDialog()"
          (importInfo)="openImportInfoDialog()"
          (export)="openExportUserPreferenceParamsDialog()"
          (refresh)="refreshUserPreferenceParams()">

        </app-user-preference-params-options>


        <app-user-preference-params-list
          [autoSetItems]="true"
          [configuration]="true"

          [searchParams]="params.userPreferenceParamsTab.search"
          [pageParams]="params.userPreferenceParamsTab.page"
          (pageParamsChange)="userPreferenceTabPageParamsChange($event)"
          (clickedItem)="clickedUserPreferenceParams($event)"></app-user-preference-params-list>


      </ng-template>
    </mat-tab>

  </mat-tab-group>

  <!--  </div>-->
</section>


