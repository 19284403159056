import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MxTable} from "../../../mx/util/list/MxTable";
import {PageParams} from "../../../mx/util/params/PageParams";
import {UserParamSearch} from "../../shared/services/user-const.service";
import {MatDialog} from "@angular/material/dialog";
import {DeleteDialogComponent} from "../../../dialogs/delete-dialog/delete-dialog.component";
import {YesNoDialogComponent} from "../../../dialogs/yes-no-dialog/yes-no-dialog.component";
import {UserParam} from "../../shared/models/userParams";
import {UserService} from "../../shared/services/user.service";

@Component({
  selector: 'app-user-params-list',
  templateUrl: './user-params-list.component.html',
  styleUrls: ['./user-params-list.component.css']
})
export class UserParamsListComponent extends MxTable<UserParam> implements OnInit {

  displayedColumns = ['name', 'value', 'remove', 'options'];
  idName = 'name';

  pageParams: PageParams;
  searchParams: UserParamSearch;

  configurationParams = false;

  @Output('delete') deleteEmit = new EventEmitter<UserParam>();

  @Input('configuration') set _setConfigurationParams(configurationParams) {
    this.configurationParams = configurationParams;
    if (configurationParams) {
      this.displayedColumns = ['name', 'value', 'options'];
    } else {
      this.displayedColumns = ['name', 'value', 'remove'];
    }
  }

  constructor(
    private userParamService: UserService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  getItems() {
    // this.startLoadingSpinner();
    // this.setItems([]);
    // if (this.configurationParams) {
    //   this.userParamService.get(this.searchParams, this.pageParams)
    //     .subscribe(
    //       res => {
    //         this.stopLoadingSpinner();
    //         if (res) {
    //           this.setItems(res.content);
    //           this.setPageInfo(res);
    //         } else {
    //           this.setItems([]);
    //         }
    //       },
    //       error => {
    //         this.showError(error);
    //         this.stopLoadingSpinner();
    //       }
    //     );
    // } else {
    //   this.projectParamService.getAll(this.searchParams, this.pageParams)
    //     .subscribe(
    //       res => {
    //         this.stopLoadingSpinner();
    //         if (res) {
    //           this.setItems(res.content);
    //           this.setPageInfo(res);
    //         } else {
    //           this.setItems([]);
    //         }
    //       },
    //       error => {
    //         this.showError(error);
    //         this.stopLoadingSpinner();
    //       }
    //     );
    // }

  }

  removeProjectParam(element: UserParam) {
    this.createDeleteDialog(element);
  }

  createDeleteDialog(element: UserParam): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '600px',
      data: {type: 'User param'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteUserParams(element);
      }
    });
  }

  private deleteUserParams(element: UserParam) {
    this.deleteEmit.emit(element);
    // this.projectParamService.deleteProjectParam(name)
    //   .subscribe(
    //     res => {
    //       this.refreshItems();
    //     },
    //     error => {
    //       console.error(error);
    //       this.error.emit(error);
    //     }
    //   );
  }

  isOverflow(el: HTMLElement): boolean {
    let curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') {
      el.style.overflow = 'hidden';
    }
    let isOverflowing = el.clientWidth < el.scrollWidth
      || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;

  }

  /*
  openAddProjectParamDialog(projectParam?: ProjectParams) {

    let dialogRef = this.dialog.open(AddProjectParamDialogComponent, {
      width: '500px',
      data: {projectParam: projectParam}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.getProjectParams();
    });
  }


  openExportDialog() {
    let dialogRef = this.dialog.open(ExportProjectParamsComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openImportDialog() {
    let dialogRef = this.dialog.open(ImportComponent, {
      width: '500px',
      data: {type: "project params"}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getProjectParams();
    });
  }
  */

  setDefaultValue(element: UserParam) {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        body: '',
        title: 'Are you sure you want to reset \'' + element.name + '\' param?',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetConfigurationParam(element);
      }
    });


  }

  resetConfigurationParam(element: UserParam) {
    // this.userPreferencesService.setDefaultValue(element.name)
    //   .subscribe(
    //     res => {
    //       this.refreshItems();
    //     },
    //     error => {
    //       console.error(error);
    //       this.error.emit(error);
    //     }
    //   );
  }
}
