<div mat-dialog-content>

  <app-mx-diff-code
    [leftCode]="code1"
    [leftLanguage]="language1"
    [rightCode]="code2"
    [rightLanguage]="language2"
  >
  </app-mx-diff-code>

</div>

<div mat-dialog-actions>
  <button id="cancelButton" class="mr-3" mat-button [mat-dialog-close]>Close</button>

  <!--  <button id="createButton" mat-button *ngIf="!saveSpinner" (click)="do()" color="primary">-->
  <!--    {{duplicate ? 'Duplicate' : 'Create'}}-->
  <!--  </button>-->
  <!--  <button mat-button *ngIf="saveSpinner" color="primary">-->
  <!--    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">-->
  <!--      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"-->
  <!--                   [diameter]="20"-->
  <!--                   class="whiteSpinner"></mat-spinner>-->
  <!--    </mat-icon>-->
  <!--  </button>-->


</div>
