<h1 mat-dialog-title xmlns="http://www.w3.org/1999/html">Edit chat</h1>


<label> Chat title</label>
<div class="mb-3 input-box">
  <input type="text" [(ngModel)]="session.title">
  <!--  <mx-icon mx="information" color="primary" class="ml-1 cursor-pointer"></mx-icon>-->
</div>

<div mat-dialog-actions>
  <button id="closeButton" mat-button (click)="closeDialog()">Cancel</button>
  <button mat-button (click)="updateAiSessionTitle()" color="primary">Update</button>
</div>

