<section class="main-container ai-message-wrapper"
         [ngClass]="{'ai-color': ai, 'user-color': !ai}"
>
  <div class="ai-title-wrapper">
    <div class="title-box">
      <mx-icon mx="user" *ngIf="!ai"></mx-icon>
      <mx-icon mx="ai" *ngIf="ai"></mx-icon>
      <span class="title">{{(ai ? 'AI Chat' : 'You')}}</span>
    </div>
    <div class="title-buttons">
      <button mat-button
              *ngIf="!ai"
              style="margin: 0;padding: 0;min-width: 35px;"
              [matTooltip]="'Edit message'"
              (click)="editMessage(); $event.stopPropagation()">
        <mx-icon mx="edit"></mx-icon>
      </button>
      <button mat-button
              style="margin: 0;padding: 0;min-width: 35px;"
              [matTooltip]="'Delete message'"
              (click)="deleteMessage(); $event.stopPropagation()">
        <mx-icon mx="delete"></mx-icon>
        <!--    <span>Copy to clipboard</span>-->
      </button>
      <button mat-button
              style="margin: 0;padding: 0;min-width: 35px;margin-right: 15px"
              [matTooltip]="'Copy to clipboard'"
              (click)="copyToClipboard(); $event.stopPropagation()">
        <mx-icon mx="copy"></mx-icon>
        <!--    <span>Copy to clipboard</span>-->
      </button>
    </div>
  </div>
  <div class="ai-content-wrapper">
    <div *ngFor="let item of this.processedMessage">
      <markdown
        *ngIf="!item.isCode"
        class="markdown-style"
        [data]="item.content"
        lineNumbers
      >
      </markdown>
      <app-ai-code
        *ngIf="item.isCode"
        [ai]="ai"
        [message]="item.content"
      >

      </app-ai-code>
    </div>
  </div>
</section>
