<h1 mat-dialog-title class="ml-1 mr-1">{{title}}</h1>

<div mat-dialog-content>
  <app-error-message class="fs-12" *ngIf="securityMessageError" [error]="error"
                     [showInDialog]="true"></app-error-message>
  <p>{{body}}</p>
  <br>
  <mat-form-field class="w-100">
    <input matInput type="text" [placeholder]="'Please type in the project ID to continue'"
           [(ngModel)]="securityMessage">
    <button mat-button *ngIf="securityMessage" matSuffix mat-icon-button aria-label="Clear"
            (click)="securityMessage=''">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Cancel</button>
  <button mat-button (click)="submit()" color="primary">{{submitButtonText}}</button>
</div>
