import {Component, Input, OnInit} from '@angular/core';
import {MxTable} from "../../../mx/util/list/MxTable";
import {PageParams} from "../../../mx/util/params/PageParams";
import {UserPreferencesParamSearch} from "../../shared/services/user-const.service";
import {MatDialog} from "@angular/material/dialog";
import {DeleteDialogComponent} from "../../../dialogs/delete-dialog/delete-dialog.component";
import {YesNoDialogComponent} from "../../../dialogs/yes-no-dialog/yes-no-dialog.component";
import {UserPreferencesParam} from "../../shared/models/userPreferencesParam";
import {UserService} from "../../shared/services/user.service";

@Component({
  selector: 'app-user-preference-params-list',
  templateUrl: './user-preference-params-list.component.html',
  styleUrls: ['./user-preference-params-list.component.css']
})
export class UserPreferenceParamsListComponent extends MxTable<UserPreferencesParam> implements OnInit {

  displayedColumns = ['name', 'value', 'description', 'options'];
  idName = 'name';

  pageParams: PageParams;
  searchParams: UserPreferencesParamSearch;

  configurationParams = false;

  @Input('configuration') set _setConfigurationParams(configurationParams) {
    this.configurationParams = configurationParams;

    this.displayedColumns = ['name', 'value', 'description', 'options'];

  }

  constructor(
    private userPreferencesService: UserService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }


  getItems() {
    this.startLoadingSpinner();
    this.setItems([]);
    this.userPreferencesService.getUserPreferencesParams(this.searchParams, this.pageParams)
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          if (res) {
            this.setItems(res.content);
            this.setPageInfo(res);
          } else {
            this.setItems([]);
          }
        },
        error => {
          this.showError(error);
          this.stopLoadingSpinner();
        }
      );

  }

  removeUserPreferencesParam(element: UserPreferencesParam) {
    this.createDeleteDialog(element.name);
  }

  createDeleteDialog(name: string): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '600px',
      data: {type: 'Project param'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.deleteUserPreferencesParam(name);
      }
    });
  }

  private deleteUserPreferencesParam(name: string) {
    // this.userPreferencesService.deleteUserPreferencesParam(name)
    //   .subscribe(
    //     res => {
    //       this.refreshItems();
    //     },
    //     error => {
    //       console.error(error);
    //       this.error.emit(error);
    //     }
    //   );
  }

  isOverflow(el: HTMLElement): boolean {
    let curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') {
      el.style.overflow = 'hidden';
    }
    let isOverflowing = el.clientWidth < el.scrollWidth
      || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflowing;

  }


  setDefaultValue(element: UserPreferencesParam) {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        body: '',
        title: 'Are you sure you want to reset \'' + element.name + '\' param?',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetUserPreferencesParam(element);
      }
    });


  }

  resetUserPreferencesParam(element: UserPreferencesParam) {
    this.userPreferencesService.resetUserPreferencesParam(element.name)
      .subscribe(
        res => {
          this.refreshItems();
        },
        error => {
          console.error(error);
          this.error.emit(error);
        }
      );
  }


  parseTestingFrameworkName(name) {
    switch (name) {
      case 'JUNIT':
        return 'JUnit';
      case 'TESTNG':
        return 'TestNG';
      case 'CLEAN_JAVA':
        return 'Java Native';
      case 'PYTEST':
        return 'Pytest';
      case 'ROBOT_FRAMEWORK':
        return 'Robot Framework';
      case 'UNITTEST':
        return 'Unittest';
      case 'MOCHA':
        return 'Mocha';
      case 'JASMINE':
        return 'Jasmine';
      case 'WEBDRIVERIO':
        return 'WebdriverIO';
      case 'CLEAN_JAVASCRIPT':
        return 'JavaScript Native';
      case 'CLEAN_PYTHON':
        return 'Python Native';
      case 'NUNIT':
        return 'NUnit';
      case 'CLEAN_CSHARP':
        return 'C# Native';
      default:
        return '';
    }
  }

  parseTestingTypeName(name) {
    switch (name) {
      case 'JAVA':
        return 'Java';
      case 'PYTHON':
        return 'Python';
      case 'JAVASCRIPT':
        return 'JavaScript';
      case 'UTAM':
        return 'Utam';
      case 'MXML':
        return 'Mxml';

      default:
        return '';
    }
  }
}

