import {Injectable} from '@angular/core';
import {PageParams} from '../../../mx/util/params/PageParams';
import {SearchParam} from '../../../mx/util/params/searchParam';
import {ScheduleConstService, ScheduleSearchParams} from '../../../scheduler/shared/services/schedule-const.service';
import {RunConstService, RunDetailsParams, RunSearchParams} from '../../../runs/shared/services/run-const.service';
import {SuiteSearchParams} from '../../../suites/shared/services/suite-const.service';
import {PageInfo} from '../../../../shared/models/pageInfo';
import {CaseTab} from '../models/CaseTab';
import {Case} from '../models/case';
import {CaseSuiteConstCommonService} from "./case-suite-const-common.service";

@Injectable({
  providedIn: 'root'
})
export class CaseConstService {

  constructor() {

  }

  public static getDefaultSearchParams(archived: boolean = false): CaseSearchParams {
    return CaseSuiteConstCommonService.getDefaultCaseSearchParams(archived);
  }

  public static getDefaultSearchParamsCases(archived: boolean = false): CaseSearchParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      name: new SearchParam(null, null),
      caseType: new SearchParam(null, null),
      description: new SearchParam(null, null),
      params: new SearchParam(null, null),
      code: new SearchParam(null, null),
      fileLocation: new SearchParam(null, null),
      anyFileLocation: new SearchParam(null, null),
      archived: new SearchParam(archived, archived),
      showPageObjects: new SearchParam(false, false),
      showCases: new SearchParam(true, true),
      showTrashRecords: new SearchParam(archived, archived),
      showActiveRecords: new SearchParam(!archived, !archived),
    };
  }

  public static getDefaultSearchParamsPageObjects(archived: boolean = false): CaseSearchParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      name: new SearchParam(null, null),
      caseType: new SearchParam(null, null),
      description: new SearchParam(null, null),
      params: new SearchParam(null, null),
      code: new SearchParam(null, null),
      fileLocation: new SearchParam(null, null),
      anyFileLocation: new SearchParam(null, null),
      archived: new SearchParam(archived, archived),
      showPageObjects: new SearchParam(true, true),
      showCases: new SearchParam(false, false),
      showTrashRecords: new SearchParam(archived, archived),
      showActiveRecords: new SearchParam(!archived, !archived),
    };
  }

  public static getDefaultCaseTabsParams(): CaseTabsParams {
    return {
      tabs: [],
      selectedTab: new SearchParam(0, 0),
      addTabParams: this.initAddItemDialogParams()
    };
  }

  public static initAddItemDialogParams(): AddTabParams {
    return {
      index: new SearchParam(null, null),

      caseSearch: CaseConstService.getDefaultSearchParams(false),
      casePage: CaseConstService.getDefaultPageParams(10),
      casePageInfo: new PageInfo(),
      selectedItems: []
    };
  }

  public static getDefaultPageParams(size = 10): PageParams {
    return CaseSuiteConstCommonService.getDefaultCasePageParams(size);
  }

  public static getDefaultPageParamsForCodeHistory(size = 5): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('createdDate,desc', 'createdDate,desc')
    };
  }

  public static getDefaultSearchParamsForCodeHistory(caseId: string): CodeHistorySearchParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      id: new SearchParam(null, null),
      caseId: new SearchParam(caseId, caseId),
      email: new SearchParam(null, null),
      code: new SearchParam(null, null)
    };
  }

  public static getDefaultCasePageParams(): CaseParams {
    return {
      case: this.initCaseComponentParams(),

      runPage: RunConstService.getDefaultPageParams(),
      runSearch: RunConstService.getDefaultSearchParams(),

      schedulePage: ScheduleConstService.getDefaultPageParams(),
      scheduleSearch: ScheduleConstService.getDefaultSearchParams(),

      casesPage: CaseConstService.getDefaultPageParams(1),
      casesSearch: CaseConstService.getDefaultSearchParams(),
      casesPagesInfo: new PageInfo(),
      caseIndex: new SearchParam(null, null)
    };
  }

  private static initCaseComponentParams(): CaseComponentParams {
    return {
      caseId: new SearchParam(null, null),
      caseTabIndex: new SearchParam(0, 0),
      codeTabIndex: new SearchParam(0, 0),

      scriptSplitScreen: this.initScriptSplitScreenParams(),


      addCaseInSuite: this.initAddCaseInSuite()
    };
  }

  public static initAddCaseInSuite(): AddCaseInSuite {
    return {
      page: CaseSuiteConstCommonService.getDefaultSuitePageParams(5),
      search: CaseSuiteConstCommonService.getDefaultSuiteSearchParams(false),
    };
  }

  private static initScriptSplitScreenParams(): CaseScriptSplitScreenParams {
    return {
      tabIndex: new SearchParam(0, 0),

      codeLookup: this.initCaseCodeLookupParams(),
      runDetails: RunConstService.getDefaultRunDetailsPageParams(),
    };
  }

  public static initCaseCodeLookupParams(): CaseCodeLookupParams {
    return {
      caseId: new SearchParam(null, null),
      caseProjectId: new SearchParam(null, null),

      dialog: this.initCaseCodeLookupDialogParams(),
    };
  }

  public static initCaseCodeLookupDialogParams(): CodeLookupDialogParams {
    return {
      casePage: this.getDefaultPageParams(5),
      caseSearch: this.getDefaultSearchParams(false),
      projectId: new SearchParam(null, null),
      projectName: new SearchParam(null, null),
    };
  }
}

export class CodeLookupDialogParams {
  casePage: PageParams;
  caseSearch: CaseSearchParams;
  projectId: SearchParam;
  projectName: SearchParam;
}

export class CaseCodeLookupParams {
  public caseId: SearchParam;
  public caseProjectId: SearchParam;

  public dialog: CodeLookupDialogParams;
}

export class CaseScriptSplitScreenParams {
  public tabIndex: SearchParam;

  public codeLookup: CaseCodeLookupParams;
  public runDetails: RunDetailsParams;
}

export class CaseSearchParams {
  public exactSearch: SearchParam;
  public name: SearchParam;
  public caseType: SearchParam;
  public description: SearchParam;
  public params: SearchParam;
  public code: SearchParam;
  public fileLocation: SearchParam;
  public anyFileLocation: SearchParam;
  public archived: SearchParam;
  public showPageObjects: SearchParam;
  public showCases: SearchParam;
  public showActiveRecords: SearchParam;
  public showTrashRecords: SearchParam;
}

export class CaseComponentParams {
  public caseId: SearchParam;
  public caseTabIndex: SearchParam;
  public codeTabIndex: SearchParam;

  public scriptSplitScreen: CaseScriptSplitScreenParams;

  public addCaseInSuite: AddCaseInSuite;
}

export class AddCaseInSuite {
  page: PageParams;
  search: SuiteSearchParams;
}

export class CaseParams {
  public case: CaseComponentParams;

  public runPage: PageParams;
  public runSearch: RunSearchParams;

  public schedulePage: PageParams;
  public scheduleSearch: ScheduleSearchParams;

  public casesPage: PageParams;
  public casesSearch: CaseSearchParams;

  public casesPagesInfo: PageInfo;

  public caseIndex: SearchParam;
}

export class CodeHistorySearchParams {
  public exactSearch: SearchParam;
  public id: SearchParam;
  public caseId: SearchParam;
  public email: SearchParam;
  public code: SearchParam;
}

class AddTabParams {
  public index: SearchParam;

  public caseSearch: CaseSearchParams;
  public casePage: PageParams;
  public casePageInfo: PageInfo;

  public selectedItems: Case[];
}

export class CaseTabsParams {
  public tabs: CaseTab[];
  public selectedTab: SearchParam;

  public addTabParams: AddTabParams;
}
