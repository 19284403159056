<h1 mat-dialog-title class="text-center" *ngIf="title != ''">{{title}}</h1>
<div mat-dialog-content *ngIf="selectedChatSession == undefined">
  <mat-checkbox [(ngModel)]="useFilter">Use filter</mat-checkbox>
</div>
<div mat-dialog-actions>

  <button id="cancelButton" mat-button [mat-dialog-close]="undefined">
    Cancel
  </button>


  <button id="yesButton" mat-button (click)="deleteSessions()" color="primary">
    Yes
  </button>

</div>
