import {map} from 'rxjs/operators';
import {ApiService} from '../../../../shared/services/api.service';
import {ConfigService} from '../../../../shared/services/config.service';
import {Injectable} from '@angular/core';
import {UserProject} from '../models/userProject';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {Params} from '../../../mx/util/params/params';
import {SecurityGroup} from '../models/securityGroup';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';
import {User} from '../models/user';

@Injectable()
export class UserService {

  private currentUserProject: UserProject = new UserProject();
  public systemUser = false;

  constructor(
    private tokens: MaxtafTokensStorageService,
    private apiService: ApiService,
    private config: ConfigService,
    public tokensStorage: MaxtafTokensStorageService,
    private userProjectConfigService: UserProjectConfigService
  ) {
  }

  initUser() {
    if (this.tokensStorage.getUserId() == null || this.tokensStorage.getUserId() == '') {
      this.setCurrentUserProject(new UserProject());
      return null;
    }
    return this.apiService.get(this.userProjectConfigService.whoami()).toPromise()
      .then(res => {
        if (res.access_token !== null) {
          return this.getMyInfo().toPromise()
            .then(userProject => {
              this.setCurrentUserProject(userProject);
              return this.getCurrentUserProject();
            });
        }
      }).catch((error) => {
        console.error('error', error);
        return null
      });
  }

  setCurrentUserProject(currentUserProject: UserProject) {
    this.systemUser = false;
    if (currentUserProject != undefined && currentUserProject.securityGroups != undefined) {
      currentUserProject.securityGroups.forEach(cup => {
        if (cup.name == 'SYSTEM') {
          this.systemUser = true;
        }
      });
    }
    this.currentUserProject = currentUserProject;
  }

  getCurrentUserProject() {
    return this.currentUserProject;
  }

  isCurrentUserAnonymous(): boolean {
    if (this.getCurrentUserProject() != undefined && this.getCurrentUserProject().user != undefined && this.getCurrentUserProject().user.id != undefined) {
      return this.getCurrentUserProject().roles.includes('ROLE_ANONYMOUS');
    } else {
      return false;
    }
  }

  isCurrentUserGuest(): boolean {
    if (this.getCurrentUserProject() != undefined && this.getCurrentUserProject().user != undefined && this.getCurrentUserProject().user.id != undefined) {
      return this.getCurrentUserProject().roles.includes('ROLE_GUEST');
    } else {
      return false;
    }
  }

  isCurrentUserAnonymousOrGuest(): boolean {
    if (this.getCurrentUserProject() != undefined && this.getCurrentUserProject().user != undefined && this.getCurrentUserProject().user.id != undefined) {
      return this.getCurrentUserProject().roles.includes('ROLE_ANONYMOUS') || this.getCurrentUserProject().roles.includes('ROLE_GUEST');
    } else {
      return false;
    }
  }


  resetCredentials() {
    return this.apiService.get(this.config.getResetCredentialsUrl());
  }

  getMyInfo() {
    return this.apiService.get(this.userProjectConfigService.whoami()).pipe(
      map(userProject => {
        this.setCurrentUserProject(userProject);
        return this.getCurrentUserProject();
      })
      // TODO save in local info
    );
  }

  getUserProjects(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.userProjectConfigService.getUserProjects(), params);
  }

  putLikePatch(id: string, body: User) {
    return this.apiService.put(this.userProjectConfigService.updateUser(id), body);
  }

  putUserProject(userProjectId: string, body: UserProject) {
    return this.apiService.put(this.userProjectConfigService.updateUserProject(userProjectId), body);
  }

  putUserProjectSecurityGroups(userProjectId: string, securityGroups: SecurityGroup[]) {
    return this.apiService.put(this.userProjectConfigService.createOrUpdateOrDeleteUserProjectSecurityGroups(userProjectId), securityGroups);
  }

  isAdmin() {
    if (this.getCurrentUserProject() != null) {
      if (this.getCurrentUserProject().securityGroups) {
        const securityGroups = this.getCurrentUserProject().securityGroups;
        for (const securityGroup of securityGroups) {
          if (securityGroup.name === 'ADMIN') {
            return true;
          }
        }
      }
    }

    return false;
  }

  getUserId() {
    return this.currentUserProject.user.id;
  }

  getBridgeAddress() {
    return this.apiService.get(this.userProjectConfigService.getBridgeAddress(), undefined, true);
  }

  getSensors() {
    return this.apiService.get(this.userProjectConfigService.getSensors());
  }

  deleteUserFromProject(userProjectId: string) {
    return this.apiService.delete(this.userProjectConfigService.deleteUserProject(userProjectId));
  }

  getUserPreferencesParam(paramName: string) {
    return this.apiService.get(this.userProjectConfigService.getUserPreferencesParam() + '?paramName=' + paramName);
  }

  getUserPreferencesParamList(searchParams?: any) {
    searchParams = new Params(searchParams);
    const params = {...searchParams};

    return this.apiService.get(this.userProjectConfigService.getUserPreferencesParamsList(), params);
  }

  setUserPreferencesParam(name: string, value: string) {
    return this.apiService.put(this.userProjectConfigService.setUserPreferencesParam() + '?name=' + name, value);
  }


  getUserPreferencesParams(searchParams, pageParams) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};
    return this.apiService.get(this.userProjectConfigService.getUserPreferencesParams(), params);
  }


  resetUserPreferencesParam(name: string) {
    return this.apiService.put(this.userProjectConfigService.resetUserPreferencesParam() + '?name=' + name, '{}');
  }
}
