<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content>

  <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
    <p *ngIf="error" class="mb-0">{{ errorMessage }}</p>
    <button (click)="error=false" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <label>Name</label>
  <div class="mb-2">
    <input [disabled]="true" type="text" [(ngModel)]="projectParam.name">
  </div>

  <label> {{projectParam.masked && projectParam.hasValue ? 'New ' : ''}}Value</label>
  <div class="mb-2">
    <input id="valueInput" [disabled]="!checkPaths.update.enabled || !allowEdit" type="text"
           [(ngModel)]="projectParam.value">
  </div>

  <!--  <label>Description</label>-->
  <!--  <div>-->
  <!--    <input type="text" [disabled]="!checkPaths.update.enabled || !allowEdit" [(ngModel)]="projectParam.description">-->
  <!--  </div>-->


</div>

<div mat-dialog-actions>
  <button id="cancelButton" mat-button (click)="close()">{{allowEdit ? 'Cancel' : 'Close'}}</button>

  <button id="createButton" *ngIf="allowEdit" mat-button [disabled]="!checkPaths.update.enabled"
          (click)="edit()"
          color="primary">
    {{projectParam.masked && projectParam.hasValue ? 'Save' : 'Save'}}
  </button>
</div>
