<mx-options
  [allowShadow]="allowShadow"
  [showButtons]="showButtons"
  [showButtonText]="showButtonText"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
  [iconMarginBottom]="iconMarginBottom"
  [defaultColors]="true"
  #mxOptions
>

  <mx-options-header>


    <app-filter-by
      class="filter-by mr-1 ml-2"
      *ngIf="showButtons"
      [disabled]="!checkPaths.filter.enabled"
      [searchParams]="searchParams"
      (searchParamsChange)="newParams($event)"
      (filter)="filter()"
      [isSearchCollapsed]="mxOptions.isSearchCollapsed"
      (isSearchCollapsedChange)="mxOptions.toggleBody();"
    ></app-filter-by>


    <!--      create-->
    <button mat-button
            *ngIf="showButtons && !configuration"
            [disabled]="!checkPaths.create.enabled"
            (click)="create()"
            [matTooltip]="!showTooltip? undefined : 'Create'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="create" color="green"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Create</span>
    </button>


    <!--      refresh-->
    <button mat-button
            *ngIf="showButtons"
            [disabled]="!checkPaths.refresh.enabled"
            (click)="refresh()"
            [matTooltip]="!showTooltip? undefined : 'Refresh'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="refresh"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Refresh</span>
    </button>

    <!--      delete-->
    <button mat-button
            *ngIf="showButtons && (hasSelected)"
            [disabled]="!checkPaths.delete.enabled"
            (click)="delete()"
            [matTooltip]="!showTooltip? undefined : 'Delete'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="delete"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Delete</span>
    </button>

    <!--      reset-->
    <button mat-button
            *ngIf="showButtons && configuration"
            [disabled]="!checkPaths.reset.enabled"
            (click)="reset()"
            [matTooltip]="!showTooltip? undefined : 'Reset to default'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="reset"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Reset</span>
    </button>

    <button mat-button
            *ngIf="showButtons"
            [disabled]="false"
            [matMenuTriggerFor]="username"
            [matTooltip]="!showTooltip? undefined : 'Options'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="options"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">More options</span>
    </button>

    <mat-menu #username="matMenu">
      <button
        *ngIf="showButtons && checkPaths.export.enabled"
        (click)="export()"
        [disabled]="!checkPaths.export.enabled"
        color="primary" mat-menu-item>
        <mx-icon mx="export" color="primary"></mx-icon>
        <span class="menu-text-primary ml-2">Export</span>
      </button>

      <button
        *ngIf="!showButtons && !checkPaths.export.enabled"
        (click)="$event.stopPropagation()"
        [disabled]="!checkPaths.export.enabled"
        mat-menu-item>
        <mx-icon mx="export"></mx-icon>
        <span class="ml-2">Export</span>
      </button>

      <button
        *ngIf="showButtons && checkPaths.import.enabled"
        (click)="import()"
        [disabled]="!checkPaths.import.enabled"
        color="primary" mat-menu-item>
        <mx-icon mx="export" color="primary"></mx-icon>
        <span class="menu-text-primary ml-2">Import</span>
      </button>

      <button
        *ngIf="!showButtons && !checkPaths.import.enabled"
        (click)="$event.stopPropagation()"
        [disabled]="!checkPaths.import.enabled"
        mat-menu-item>
        <mx-icon mx="export"></mx-icon>
        <span class="ml-2">Import</span>
      </button>

      <button
        *ngIf="showButtons && checkPaths.import.enabled"
        (click)="importInfo()"
        [disabled]="!checkPaths.import.enabled"
        color="primary" mat-menu-item>
        <mx-icon mx="import-info" color="primary"></mx-icon>
        <span class="menu-text-primary ml-2">Import info</span>
      </button>

      <button
        *ngIf="!showButtons && !checkPaths.import.enabled"
        (click)="importInfo()"
        [disabled]="!checkPaths.import.enabled"
        mat-menu-item>
        <mx-icon mx="import-info"></mx-icon>
        <span class="ml-2">Import info</span>
      </button>
    </mat-menu>

  </mx-options-header>

  <mx-options-body>
    <div class="filter">
      <app-project-params-filter
        [lockSearchParams]="lockFilterParams"
        [searchParams]="searchParams"
        (searchParamsChange)="newParams($event)"
        (filter)="filter()"
        (close)="mxOptions.closeBody();"
      ></app-project-params-filter>
    </div>
  </mx-options-body>
</mx-options>
