import {Component, Inject, Input, OnInit} from '@angular/core';
import {FileService} from '../../../shared/services/file.service';
import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CloudConfigService} from '../../../shared/services/cloud-config.service';
import {ScheduleConfigService} from '../../../shared/services/schedule-config.service';
import {interval, Subscription} from 'rxjs';
import {ImportInfoDetails} from './ImportInfoDetails';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {

  @Input()
  type = '';

  overwrite1 = false;
  overwrite1RequestName = 'overwrite';
  overwrite2 = false;
  overwrite3 = false;
  overwrite2RequestName = 'overwrite';

  importType = 'xml';

  url: string;

  fileName = 'Choose file';

  inputFileReference = null;

  selectedFiles: FileList;

  currentFileUpload: File;
  progress: { percentage: number } = {percentage: 0};

  showSuccess = false;
  showUploadError = false;
  error: any;
  importInfoId = undefined;

  successMessage: string;

  spinner = false;
  finished = false;

  projectParamsState = false;

  hideSourceRadioGroup = false;

  hideOverwriteButton2 = true;
  compile = false;
  overwriteButton1Message = 'Overwrite data with import file?';
  overwriteButton2Message = '';
  overwriteButton3Message = '';
  compileButton1Message = 'Compile cases';

  showError = false;

  refreshIntervalSub: Subscription;
  @Input('refreshIntervalInMs') refreshInterval = 1000;

  importSpinner = false;
  overwrite3RequestName = '';
  hideOverwriteButton3 = true;
  importStatusField: boolean;


  constructor(
    private fileService: FileService,
    private cloudConfigService: CloudConfigService,
    private scheduleConfigService: ScheduleConfigService,
    public dialogRef: MatDialogRef<ImportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: string,
      overwriteButton1Message: string,
      overwriteButton2Message: string,
      hideSourceRadioGroup: boolean;
      overwrite1RequestName: string,
      overwrite2RequestName: string
    }
  ) {
    this.type = this.data.type;
    if (this.type === 'case') {
      this.url = this.cloudConfigService.importCases();
    }
    if (this.type === 'suite') {
      this.url = this.cloudConfigService.importSuites();
      this.overwriteButton1Message = 'Overwrite case data with import file?';
      this.overwriteButton2Message = 'Overwrite suite data with import file?';
      this.overwrite1RequestName = 'overwriteCases';
      this.overwrite2RequestName = 'overwriteSuites';
      this.hideOverwriteButton2 = false;
    }

    if (this.type === 'schedule') {
      this.url = this.scheduleConfigService.importSchedules(); // '/schedule';
      this.overwriteButton1Message = 'Overwrite case data with import file?';
      this.overwriteButton2Message = 'Overwrite suite data with import file?';
      this.overwriteButton3Message = 'Overwrite schedule data with import file?';
      this.overwrite1RequestName = 'overwriteCases';
      this.overwrite2RequestName = 'overwriteSuites';
      this.overwrite3RequestName = 'overwriteSchedules';
      this.hideSourceRadioGroup = true;
      this.hideOverwriteButton2 = false;
      this.hideOverwriteButton3 = false;
    }

    if (this.type.toLowerCase() == 'project params') {
      this.hideSourceRadioGroup = true;
      this.importType = 'json';
      this.url = this.cloudConfigService.importProjectParams();
    }

    if (data.overwriteButton2Message != undefined) {
      this.overwriteButton2Message = data.overwriteButton2Message;
      this.hideOverwriteButton2 = false;
    }

    if (data.hideSourceRadioGroup != undefined) {
      this.hideSourceRadioGroup = data.hideSourceRadioGroup;
    }

    if (data.overwrite1RequestName != undefined) {
      this.overwrite1RequestName = data.overwrite1RequestName;
    }

    if (data.overwrite2RequestName != undefined) {
      this.overwrite2RequestName = data.overwrite2RequestName;
    }

    if (data.overwriteButton1Message != undefined) {
      this.overwriteButton1Message = this.data.overwriteButton1Message;
    }

    dialogRef.disableClose = true;
  }


  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      if (!this.spinner && this.finished) {
        document.getElementById('okButton').click();
      } else if (!this.spinner && !this.finished) {
        document.getElementById('closeButton').click();
      }
    }
  };

  // ENTER shortcut for importing the test
  importShortcutListener = (e) => {
    if (e.keyCode == 13 && this.selectedFiles) {
      e.preventDefault();
      if (!this.spinner && this.finished) {
        document.getElementById('okButton').click();
      } else {
        document.getElementById('uploadButton').click();
      }
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.importShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.importShortcutListener, false);
    this.stopLiveRefresh();
  }

  showErrorAlert(error) {
    this.error = error;
    this.showUploadError = true;
  }

  hideErrorAlert() {
    this.error = null;
    this.showUploadError = false;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectFile(event) {
    this.hideErrorAlert();
    const file: File = event.target.files.item(0);

    this.inputFileReference = event.target;

    if (file.name.toLowerCase().match('.*\\.java|.*\\.json|.*\\.xml|.*\\.mxml|.*\\.py|.*\\.js|.*\\.cs')) {
      this.selectedFiles = event.target.files;
      this.fileName = this.selectedFiles.item(0).name;

      if (file.name.toLowerCase().match('.*\\.java')) {
        this.importType = 'junit';
      }

      if (file.name.toLowerCase().match('.*\\.xml|.*\\.mxml')) {
        this.importType = 'xml';
      }

      if (file.name.toLowerCase().match('.*\\.py')) {
        this.importType = 'python';
      }

      if (file.name.toLowerCase().match('.*\\.js')) {
        this.importType = 'javascript';
      }

      if (file.name.toLowerCase().match('.*\\.cs')) {
        this.importType = 'csharp';
      }

      if (file.name.toLowerCase().match('.*\\.json')) {
        this.importType = 'json';
      }

    } else {
      this.showErrorAlert({
        name: 'Invalid format!',
        message: 'Supported extensions are xml, json, java, py, js and cs - please select one of these for import.'
      });
      this.selectedFiles = null;
      this.fileName = 'Choose file';
    }
  }

  counterDot = 0;

  startLiveRefresh() {
    this.refreshIntervalSub = interval(this.refreshInterval)
      .subscribe(() => {
        if (document.hidden) {
          return;
        }

        if (this.counterDot + 1 > 2) {
          this.counterDot = 0;
        } else {
          this.counterDot += 1;
        }
        if (this.type === 'case') {
          this.fileService.getImportInfoCase(this.importInfoId).subscribe(
            res => {
              this.importInfoResponse(res);

            },
            error => {
              this.spinner = false;
              console.error(error);
            }
          );
        }

        if (this.type === 'suite') {
          this.fileService.getImportInfoSuite(this.importInfoId).subscribe(
            res => {
              this.importInfoResponse(res);
            },
            error => {
              this.spinner = false;
              console.error(error);
            }
          );
        }

        if (this.type === 'schedule') {
          this.fileService.getImportInfoSchedule(this.importInfoId).subscribe(
            res => {
              this.importInfoResponse(res);
            },
            error => {
              this.spinner = false;

              console.error(error);
            }
          );
        }

        if (this.type.toLowerCase() == 'project params') {
          this.fileService.getImportInfoProjectParam(this.importInfoId).subscribe(
            res => {
              this.importInfoResponse(res);
            },
            error => {
              this.spinner = false;
              console.error(error);
            }
          );
        }
      });


  }

  stopLiveRefresh() {
    this.showSuccess = true;
    if (this.refreshIntervalSub) {
      this.refreshIntervalSub.unsubscribe();
    }
  }

  upload() {
    this.hideErrorAlert();
    this.showSuccess = false;
    this.progress.percentage = 0;

    this.currentFileUpload = this.selectedFiles.item(0);

    this.spinner = true;
    this.importSpinner = true;
    this.fileService.importFile(this.currentFileUpload, this.url, this.overwrite1, this.overwrite1RequestName, this.overwrite2, this.overwrite2RequestName, this.importType, this.compile, this.overwrite3RequestName, this.overwrite3)
      .subscribe(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
          } else {
            if (event instanceof HttpResponse) {
              // this.showSuccess = true;
              // let successJson = JSON.parse(event.body);
              const successJson = event.body;
              this.importInfoId = successJson.id;
              this.successMessage = 'File ' + this.currentFileUpload.name;

              if (this.progress.percentage >= 100) {
                this.spinner = false;
                this.finished = true;

                this.startLiveRefresh();
              }
            } else {
            }

            this.inputFileReference.value = '';
          }

        },
        error => {
          console.error(' $$$$$$$$ error', error);
          this.showErrorAlert(error);
          this.fileName = 'Choose file';
          this.progress = {percentage: 0};
          this.selectedFiles = null;
          this.currentFileUpload = null;
          this.selectedFiles = null;
          this.spinner = false;
          this.inputFileReference.value = '';
        }
      );
    if (this.importStatusField === false) {
    }
    this.selectedFiles = undefined;

  }


  importInfoDetails: ImportInfoDetails = undefined;

  private importInfoResponse(res: ImportInfoDetails) {

    this.importInfoDetails = res;
    if (this.type === 'case') {
      if (this.importInfoDetails.casesCompleted) {
        this.stopLiveRefresh();
      }
    }

    if (this.type === 'suite') {
      if (this.importInfoDetails.suitesCompleted) {
        this.stopLiveRefresh();
      }
    }

    if (this.type === 'schedule') {
      if (this.importInfoDetails.scheduleCompleted) {
        this.stopLiveRefresh();
      }
    }

    if (this.type.toLowerCase() == 'project params') {
      if (this.importInfoDetails.projectParamsCompleted) {
        this.stopLiveRefresh();
      }
    }
    this.importStatus();

  }

  partialSuccess = false;


  importStatus(): void {
    let countOfSkippedCases = this.importInfoDetails?.casesSkipped?.split('\n').length - 1;
    countOfSkippedCases = isNaN(countOfSkippedCases) ? 0 : Math.max(0, countOfSkippedCases);

    let countOfSkippedSuites = this.importInfoDetails?.suitesSkipped?.split('\n').length - 1;
    countOfSkippedSuites = isNaN(countOfSkippedSuites) ? 0 : Math.max(0, countOfSkippedSuites);

    let countOfSkippedSchedules = this.importInfoDetails?.scheduleSkipped?.split('\n').length - 1;
    countOfSkippedSchedules = isNaN(countOfSkippedSchedules) ? 0 : Math.max(0, countOfSkippedSchedules);

    let countOfSkippedProjectParams = this.importInfoDetails?.projectParamsSkipped?.split('\n').length - 1;
    countOfSkippedProjectParams = isNaN(countOfSkippedProjectParams) ? 0 : Math.max(0, countOfSkippedProjectParams);

    let countOfErrorCases = this.importInfoDetails?.casesErrors?.match(/error while importing the /g)?.length ?? 0;
    let countOfErrorSuites = this.importInfoDetails?.suitesErrors?.match(/error while importing the /g)?.length ?? 0;
    let countOfErrorSchedules = this.importInfoDetails?.scheduleErrors?.match(/error while importing the /g)?.length ?? 0;
    let countOfErrorProjectParams = this.importInfoDetails?.projectParamsErrors?.match(/error while importing the /g)?.length ?? 0;

    let allErrorsAreNull = this.checkErrors();


    if (allErrorsAreNull &&
      countOfSkippedCases == 0 &&
      countOfSkippedSuites == 0 &&
      countOfSkippedSchedules == 0 &&
      countOfSkippedProjectParams == 0 &&

      countOfErrorCases == 0 &&
      countOfErrorSuites == 0 &&
      countOfErrorSchedules == 0 &&
      countOfErrorProjectParams == 0
    ) {
      this.importStatusField = true;
      this.partialSuccess = false;
      this.successMessage = this.successMessage + ' was imported with success.';

    } else if (
      (this.importInfoDetails.maxCasesNumber ?? 0) == countOfSkippedCases + countOfErrorCases &&
      (this.importInfoDetails.maxSuitesNumber ?? 0) == countOfSkippedSuites + countOfErrorSuites &&
      (this.importInfoDetails.maxScheduleNumber ?? 0) == countOfSkippedSchedules + countOfErrorSchedules &&
      (this.importInfoDetails.maxProjectParamsNumber ?? 0) == countOfSkippedProjectParams + countOfErrorProjectParams
    ) {
      this.importStatusField = false;
      this.partialSuccess = false;
      this.successMessage = this.successMessage + ' was not imported.';
    } else {
      this.importStatusField = false;
      this.partialSuccess = true;
      this.successMessage = this.successMessage + ' was partially imported.';
    }

  }

  checkErrors() {
    const errors = [
      this.importInfoDetails.workspaceError,
      this.importInfoDetails.scheduleError,
      this.importInfoDetails.projectParamsError,
      this.importInfoDetails.suitesError,
      this.importInfoDetails.casesError
    ];

    return errors.every(error => error == null || error.trim() === '');
  }

  paramsChangeValue() {

  }
}
