<overlay-scrollbars
  #navTreeScrolling
  [options]="{ scrollbars: { autoHide: 'l' } }"
  [ngStyle]="{'height': height, 'max-height': maxHeight ? maxHeight : 'none'}"
>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

    <!--  file-->
    <mat-tree-node class="nav-tree-file" *matTreeNodeDef="let node" matTreeNodePadding
                   (contextmenu)="openContextMenu($event, node); $event.preventDefault();"
                   appDnd (fileDropped)="onFileDropped($event, node)"
                   matTreeNodePaddingIndent="20"
                   (dblclick)="clickedFile(node)"
                   [ngClass]="{ 'background-highlight': activeNode === node }">
      <!--      (click)="activeNode = node;"-->
      <!--      <button class="button-folder-expand-collapse" mat-icon-button disabled></button>-->
      <app-nav-tree-file-icon class="pointer"
                              [extension]="node.typeOfFile"></app-nav-tree-file-icon>
      <span class="no-wrap pointer no-select">{{node.name}}</span>
      <mx-icon class="context-menu-trigger pointer" (click)="openContextMenu($event, node); $event.stopPropagation();"
               mx="options"
               color="primary"></mx-icon>
    </mat-tree-node>

    <!--  folder-->
    <mat-tree-node class="nav-tree-folder"
                   *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                   appDnd (fileDropped)="onFileDropped($event, node)"
                   (contextmenu)="openContextMenu($event, node); $event.preventDefault();"
                   (dblclick)="treeControl.isExpanded(node) ? treeControl.collapse(node) : treeControl.expand(node)"
                   matTreeNodePaddingIndent="20"
                   [ngClass]="{ 'background-highlight': activeNode === node }">
      <!--                   (click)="activeNode = node;"-->
      <button mat-icon-button class="button-folder-expand-collapse"
              [attr.aria-label]="'toggle ' + node.name" matTreeNodeToggle>
        <mx-icon class="mat-icon-rtl-mirror folder-expand-collapse"
                 [mx]="treeControl.isExpanded(node) ? 'tree-node-collapsed' : 'tree-node-expanded'"
                 [color]="treeControl.isExpanded(node) ? 'primary' : 'accent'"></mx-icon>
      </button>

      <mx-icon class="mr-1 pointer" color="#b0bec5" mx="folder" marginBottom="true"
               *ngIf="!treeControl.isExpanded(node)"></mx-icon>
      <mx-icon class="mr-1 pointer" color="#b0bec5" mx="folder-open" marginBottom="true"
               *ngIf="treeControl.isExpanded(node)"></mx-icon>

      <span class="no-wrap pointer no-select">{{node.name}}</span>

      <mx-icon class="context-menu-trigger pointer" (click)="openContextMenu($event, node); $event.stopPropagation();"
               mx="options"
               color="primary"></mx-icon>

      <mat-spinner class="ml-2"
                   *ngIf="node.isLoading"
                   [strokeWidth]="3"
                   [diameter]="22"
                   mode="indeterminate">

      </mat-spinner>
    </mat-tree-node>

    <!--    <mat-tree-node *matTreeNodeDef="let node; when: isLoadMore">-->
    <!--      <button mat-icon-button disabled></button>-->
    <!--      <button mat-button (click)="loadMore(node)">-->
    <!--        Load more...-->
    <!--      </button>-->
    <!--    </mat-tree-node>-->

    <ng-template #contextMenu let-node>
      <section class="node-context-menu" (clickOutside)="closeContextMenu()">

        <button class="context-menu-button" mat-menu-item (click)="createFolder(node)"
                [disabled]="!checkPaths.createFolder.enabled"
                *ngIf="node.isFolder && isPrimaryProject(node)">
          <mx-icon marginBottom="true" mx="create-folder"></mx-icon>
          Create Folder
        </button>
        <button class="context-menu-button" mat-menu-item (click)="searchFolder(node)"

                *ngIf="node.isFolder && isPrimaryProject(node)">
          <mx-icon marginBottom="true" mx="folder-search"></mx-icon>
          Find Files
        </button>
        <button class="context-menu-button" mat-menu-item (click)="createFile(node)"
                [disabled]="!checkPaths.createFile.enabled"
                *ngIf="node.isFolder && isPrimaryProject(node)">
          <mx-icon marginBottom="true" mx="create-file"></mx-icon>
          Create File
        </button>
        <button class="context-menu-button" mat-menu-item (click)="createCase(node)"
                [disabled]="!checkPaths.createCase.enabled"
                *ngIf="node.isFolder && isPrimaryProject(node) && canCreateCase(node)">
          <mx-icon marginBottom="true" mx="create-case"></mx-icon>
          Create Case
        </button>
        <button class="context-menu-button" mat-menu-item (click)="createPageObject(node)"
                [disabled]="!checkPaths.createCase.enabled"
                *ngIf="node.isFolder && isPrimaryProject(node) && canCreateCase(node)">
          <mx-icon marginBottom="true" mx="create-case"></mx-icon>
          Create Page Object
        </button>
        <button class="context-menu-button" mat-menu-item (click)="rename(node)"
                [disabled]="!checkPaths.rename.enabled"
                *ngIf="node.level != 0 && isPrimaryProject(node)">
          <mx-icon marginBottom="true" mx="rename"></mx-icon>
          Rename
        </button>
        <button class="context-menu-button" mat-menu-item (click)="delete(node)"
                [disabled]="!checkPaths.delete.enabled"
                *ngIf="node.level != 0 && isPrimaryProject(node)">
          <mx-icon marginBottom="true" mx="delete"></mx-icon>
          Delete
        </button>
        <button class="context-menu-button" mat-menu-item (click)="upload(node)"
                [disabled]="!checkPaths.upload.enabled"
                *ngIf="node.isFolder && isPrimaryProject(node)">
          <mx-icon marginBottom="true" mx="upload"></mx-icon>
          Upload
        </button>
        <button class="context-menu-button" mat-menu-item (click)="download(node)"
                [disabled]="!checkPaths.download.enabled">
          <mx-icon marginBottom="true" mx="download"></mx-icon>
          Download
        </button>
        <button class="context-menu-button" mat-menu-item (click)="clickedFile(node)"
                [disabled]="node.typeOfFile == 'zip' && !checkPaths.zip.enabled" *ngIf="!node.isFolder">
          <mx-icon marginBottom="true" mx="open"></mx-icon>
          Open
        </button>
        <button class="context-menu-button" mat-menu-item (click)="copy(node)"
                [disabled]="!checkPaths.paste.enabled">
          <mx-icon marginBottom="true" mx="copy"></mx-icon>
          Copy
        </button>
        <button class="context-menu-button" mat-menu-item (click)="cut(node)"
                [disabled]="!checkPaths.paste.enabled"
                *ngIf="node.level != 0 && isPrimaryProject(node)">
          <mx-icon marginBottom="true" mx="cut"></mx-icon>
          Cut
        </button>
        <button class="context-menu-button" mat-menu-item (click)="paste(node)"
                [disabled]="!checkPaths.paste.enabled"
                *ngIf="node.isFolder && copyNode != undefined && isPrimaryProject(node)">
          <mx-icon marginBottom="true" mx="paste"></mx-icon>
          Paste
        </button>
        <button class="context-menu-button" mat-menu-item (click)="zip(node)"
                [disabled]="!checkPaths.zip.enabled"
                *ngIf="node.isFolder && isPrimaryProject(node) && node.level != 0">
          <!--                *ngIf="node.isFolder && isPrimaryProject(node)">-->
          <mx-icon marginBottom="true" mx="zip"></mx-icon>
          Zip
        </button>
        <button class="context-menu-button" mat-menu-item (click)="unzip(node)"
                [disabled]="!checkPaths.unzip.enabled"
                *ngIf="!node.isFolder && node.typeOfFile == 'zip' && isPrimaryProject(node)">
          <mx-icon marginBottom="true" mx="unzip"></mx-icon>
          Unzip
        </button>
        <button class="context-menu-button" mat-menu-item (click)="refresh(node)"
                *ngIf="node.isFolder">
          <mx-icon marginBottom="true" mx="refresh"></mx-icon>
          Refresh
        </button>
        <button class="context-menu-button" mat-menu-item (click)="properties(node)"
                *ngIf="node.level != 0">
          <mx-icon marginBottom="true" mx="file-properties"></mx-icon>
          Properties
        </button>
        <button class="context-menu-button"
                mat-menu-item
                (click)="openPageInNewWindow(this.configService.getHost() + '/terminal?path='+node.pathWithName, 800, 400)"
                *ngIf="!node.isFolder && node.typeOfFile == 'sh' && isAdmin && canBePrivate">
          <mx-icon marginBottom="true" mx="run-sh"></mx-icon>
          Run
        </button>
        <!--        <button class="context-menu-button" mat-menu-item (click)="cleanMaven(node)"-->
        <!--                [disabled]="!checkPaths.cleanMaven.enabled"-->
        <!--                *ngIf="!node.isFolder && node.name == 'pom.xml'">-->
        <!--          <mx-icon marginBottom="true" mx="clean"></mx-icon>-->
        <!--          Clean (Maven)-->
        <!--        </button>-->
        <!--        <button class="context-menu-button" mat-menu-item (click)="compileMaven(node)"-->
        <!--                [disabled]="!checkPaths.compileMaven.enabled"-->
        <!--                *ngIf="!node.isFolder && node.name == 'pom.xml'">-->
        <!--          <mx-icon marginBottom="true" mx="compile"></mx-icon>-->
        <!--          Compile (Maven)-->
        <!--        </button>-->
        <!--        <button class="context-menu-button" mat-menu-item (click)="installMaven(node)"-->
        <!--                [disabled]="!checkPaths.installMaven.enabled"-->
        <!--                *ngIf="!node.isFolder && node.name == 'pom.xml'">-->
        <!--          <mx-icon marginBottom="true" mx="install"></mx-icon>-->
        <!--          Install (Maven)-->
        <!--        </button>-->
      </section>
    </ng-template>

  </mat-tree>
</overlay-scrollbars>
<!--  </div>-->
<!--</ngx-simplebar>-->
