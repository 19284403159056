<div *ngIf="session == undefined" style="display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;">

  <!--<div class="ai-chat-spinner">-->
  <mat-spinner></mat-spinner>
</div>

<!--<div *ngIf="session" class="ai-title" #title [matTooltip]="isOverflowingTitle(title) ? session.title:null">-->
<!--  <div>-->
<!--    Title: {{session.title}}-->
<!--  </div>-->
<!--  <div>-->
<!--    &lt;!&ndash;    <button mat-button&ndash;&gt;-->
<!--    &lt;!&ndash;            style="margin: 0;padding: 0; width: 24px;"&ndash;&gt;-->
<!--    &lt;!&ndash;            class="mr-1"&ndash;&gt;-->
<!--    &lt;!&ndash;            [matTooltip]="'Edit session'"&ndash;&gt;-->
<!--    &lt;!&ndash;            (click)="editSessions(session); $event.stopPropagation()">&ndash;&gt;-->
<!--    <mx-icon size="small" mx="edit" (click)="editSessions(session); $event.stopPropagation()"></mx-icon>-->
<!--    &lt;!&ndash;    <span>Copy to clipboard</span>&ndash;&gt;-->
<!--    &lt;!&ndash;    </button>&ndash;&gt;-->
<!--  </div>-->
<!--</div>-->

<div
  [ngStyle]="{
  'height': maxHeight
  }"
  style="overflow-y: auto;"
  *ngIf="session != undefined">


  <!--<div *ngIf="false">-->
  <overlay-scrollbars
    #pageScroll
    [ngClass]="{ 'height-without-board': true }"
    [options]="{ scrollbars: { autoHide: 'l' } }"
  >

    <div style="line-height: 2;" >

<!--      *ngIf="!item.content.startsWith('Your role is to help users create scripts by engaging in a dialogue to understand their coding goals and requirements')"-->
      <ng-container *ngFor="let item of this.chatRecords">
<!--        {{item.createdDate}}-->
        <app-ai-message
          (refreshAndAsk)="refreshDataAndAsk()"
          (refreshChatRecords)="getChatRecordsForSession()"
          [ai]="item.ai"
          [chatId]="item.id"
          [chatSessionId]="item.sessionId"
          [message]="item.content">
        </app-ai-message>
      </ng-container>

      <app-ai-message
        *ngIf="activeChat  != null"
        (refreshAndAsk)="refreshDataAndAsk()"
        [ai]="activeChat.ai"
        [chatId]="activeChat.id"
        [message]="activeChat.content">
      </app-ai-message>
      <app-ai-load-response
        *ngIf="showLoadResponseDiv">
      </app-ai-load-response>
    </div>
  </overlay-scrollbars>

</div>


