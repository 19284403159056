<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content>

  <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
    <p *ngIf="error" class="mb-0">{{ errorMessage }}</p>
    <button (click)="error=false" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div *ngIf="doesNameExist" class="alert alert-warning" role="alert">
    The name '{{existingName}}' already exists with the value '{{existingValue}}'. If the parameter name remain the
    same, this value will be overwritten.
  </div>
  <div class="alert alert-danger" *ngIf="nameCannotBeNull">
    Name cannot be empty.
  </div>

  <label>Name</label>
  <div class="mb-2">
    <input
      [disabled]="!checkPaths.update.enabled || !create || !allowEdit"
      type="text"
      required
      [(ngModel)]="userPreferencesParam.name"
      (ngModelChange)="checkIfNameExists()">

  </div>

  <label>Value</label>
  <div class="mb-2">
    <input id="valueInput" [disabled]="!checkPaths.update.enabled || !allowEdit" type="text"
           [(ngModel)]="userPreferencesParam.value">
  </div>

</div>

<div mat-dialog-actions>
  <button id="cancelButton" mat-button (click)="close()">{{allowEdit ? 'Cancel' : 'Close'}}</button>

  <button id="createButton" *ngIf="allowEdit" mat-button [disabled]="!checkPaths.update.enabled"
          (click)="create? add() : edit()"
          color="primary">
    {{createOkButton}}
  </button>
</div>
