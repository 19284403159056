<h1 mat-dialog-title>{{duplicate ? 'Duplicate this' : 'Create'}}</h1>

<div mat-dialog-content>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

  <!--  <label *ngIf="!duplicate">Case or Page Object</label>-->
  <!--  !{{case.pageObject}}!defaultType: {{defaultType}}!-->
  <label *ngIf="!duplicate && case != undefined">Type</label>
  <div *ngIf="!duplicate &&  case != undefined" class="mb-3">
    <mat-button-toggle-group
      (change)="case.pageObject = $event.value;this.setDefaultTemplateAndFramework();getTestingFrameworksFor('cases',this.defaultCaseType);getTemplatesFor(case.pageObject? 'pageObjects': 'cases',case.pageObject? this.defaultPageObjectType:this.defaultCaseType, this.defaultCaseFramework)"
      placeholder=""
      [disabled]="saveSpinner || createFromFile || blockType || fromWorkspace"
      [value]="case.pageObject">
      <mat-button-toggle [value]="false">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-cases"
          color="none"
          [extension]="'cases'"
        ></app-nav-tree-file-icon>
        Case
      </mat-button-toggle>
      <mat-button-toggle [value]="true">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-pos"
          color="none"
          [extension]="'pos'"
        ></app-nav-tree-file-icon>
        Page Object
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <!--  !{{case.caseType}}!defaultCaseType: {{defaultCaseType}}!defaultPageObjectType: {{defaultPageObjectType}}!-->
  <label *ngIf="!duplicate && case != undefined">Language</label>
  <div *ngIf="!duplicate && case != undefined" class="mb-3">
    <mat-button-toggle-group *ngIf="!case.pageObject"
                             (change)="case.caseType = $event.value; getTestingFrameworksFor('cases',case.caseType);"
                             placeholder=""
                             [disabled]="saveSpinner || createFromFile || blockType || fromWorkspace"
                             [value]="case.caseType">
      <mat-button-toggle value="JAVA" color="primary">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-java"
          color="none"
          [extension]="'java'"
        ></app-nav-tree-file-icon>
        Java
      </mat-button-toggle>
      <br>
      <mat-button-toggle value="PYTHON">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-python"
          color="none"
          [extension]="'py'"
        ></app-nav-tree-file-icon>
        Python
      </mat-button-toggle>
      <br>
      <mat-button-toggle value="JAVASCRIPT">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-javascript"
          color="none"
          [extension]="'js'"
        ></app-nav-tree-file-icon>
        JavaScript
      </mat-button-toggle>
      <br>
      <!--      <mat-option value="CSHARP">C#</mat-option>-->
      <!--      <mat-option value="ROBOT_FRAMEWORK">Robot Framework</mat-option>-->
      <mat-button-toggle *ngIf="isLicensed" value="MXML">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-mxml"
          color="none"
          [extension]="'mxml'"
        ></app-nav-tree-file-icon>
        Mxml
      </mat-button-toggle>
      <!--      <mat-option *ngIf="isLicensed" value="UTAM">Utam</mat-option>-->
    </mat-button-toggle-group>

    <mat-button-toggle-group placeholder=""
                             *ngIf="case != undefined && case.pageObject"
                             (change)="case.caseType = $event.value; getTemplatesFor('pageObjects', case.caseType, this.case.testingFramework)"
                             [disabled]="saveSpinner || createFromFile || blockType"
                             [value]="case.caseType != null ? case.caseType : null">
      <mat-button-toggle value="JAVA">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-java"
          color="none"
          [extension]="'java'"
        ></app-nav-tree-file-icon>
        Java
      </mat-button-toggle>
      <br>
      <mat-button-toggle value="PYTHON">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-python"
          color="none"
          [extension]="'python'"
        ></app-nav-tree-file-icon>
        Python
      </mat-button-toggle>
      <br>
      <mat-button-toggle value="JAVASCRIPT">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-javascript"
          color="none"
          [extension]="'js'"
        ></app-nav-tree-file-icon>
        JavaScript
      </mat-button-toggle>
      <br>
      <mat-button-toggle value="UTAM">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-utam"
          color="none"
          [extension]="'utam'"
        ></app-nav-tree-file-icon>
        Utam
      </mat-button-toggle>
      <br>
      <!--      <mat-option value="CSHARP">C#</mat-option>-->
      <!--      <mat-option value="ROBOT_FRAMEWORK">Robot Framework</mat-option>-->
      <mat-button-toggle *ngIf="isLicensed" value="MXML">
        <app-nav-tree-file-icon
          class="toggle-language-icon language-icon-mxml"
          color="none"
          [extension]="'mxml'"
        ></app-nav-tree-file-icon>
        Mxml
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>


  <label *ngIf="!duplicate"> Name</label>
  <!--  <div *ngIf="!duplicate" class="mb-3">-->
  <!--    <input [disabled]="saveSpinner" type="text" [(ngModel)]="case.name">-->
  <!--  </div>-->
  <div *ngIf="case != undefined && !duplicate" class="mb-3">
    <input [disabled]="saveSpinner" type="text"
           [(ngModel)]="case?.name?.includes('.utam') ? case.name.replace('.utam', '') : case.name"
    >
  </div>

  <mat-form-field *ngIf="!duplicate" appearance="fill" class="mb-3 full-width">
    <mat-label>Description</mat-label>
    <textarea matInput [disabled]="saveSpinner" [(ngModel)]="case.description" rows="2" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
  </mat-form-field>

  <!--  !{{case.testingFramework}}!defaultCaseFramework: {{defaultCaseFramework}}!-->
  <div *ngIf="!duplicate && case != undefined && !case.pageObject" class="mb-3">
    <label>Testing framework</label>
    <mat-select
      (selectionChange)="getTemplatesFor(case.pageObject ? 'pageObjects': 'cases',case.caseType, case.testingFramework)"
      placeholder=""
      [disabled]="saveSpinner"
      [(value)]="case.testingFramework">
      <mat-option *ngFor="let testingFramework of filteredTestingFrameworks" [value]="testingFramework">
        {{parseTestingFrameworkName(testingFramework)}}
      </mat-option>
    </mat-select>
  </div>

  <!--  !{{selectedTemplate}}!defaultCaseTemplate: {{defaultCaseTemplate}}!defaultPageObjectTemplate: {{defaultPageObjectTemplate}}!-->
  <div *ngIf="!duplicate && !createFromFile && !fromWorkspace">
    <label>Template</label>
    <mat-select [(value)]="selectedTemplate" [disabled]="saveSpinner">
      <mat-option *ngFor="let template of filteredTemplates" [value]="template.name">
        {{template.name}}
        <span [attr.data-content]="template.description" class="option-description"></span>
      </mat-option>
    </mat-select>

  </div>

  <label *ngIf="duplicate && setNewFileLocation && !advanceFileLocation">File location name</label>
  <div *ngIf="duplicate && setNewFileLocation && !advanceFileLocation">
    <input type="text" [(ngModel)]="fileLocationName">
  </div>

  <label *ngIf="duplicate && setNewFileLocation && !advanceFileLocation">Auto-open AI Chat Assistant</label>
  <div>
    <mat-checkbox [(ngModel)]="defaultAiHelp" class="pt-3">Auto-open AI Chat Assistant</mat-checkbox>
  </div>

<!--  <label *ngIf="duplicate && setNewFileLocation && !advanceFileLocation">Remember my choice</label>-->
<!--  <div>-->
<!--    <mat-checkbox [(ngModel)]="rememberMyChoice" class="pt-3">Remember my choice</mat-checkbox>-->
<!--  </div>-->

  <!--  <br>-->
  <!--  <div-->
  <!--    *ngIf="duplicate && setNewFileLocation"-->
  <!--    style="display: flex;-->
  <!--    flex-wrap: nowrap;-->
  <!--    justify-content: center;-->
  <!--    align-items: center;">-->
  <!--    <button mat-raised-button color="primary"-->
  <!--            (click)="openAdvance()">{{ advanceFileLocation ? 'Update File location' : 'Change file location'}}</button>-->
  <!--  </div>-->


  <!--  <br>-->

</div>

<div mat-dialog-actions>
  <button id="cancelButton" mat-button *ngIf="!saveSpinner" [mat-dialog-close]>Cancel</button>

  <button id="createButton" mat-button *ngIf="!saveSpinner" (click)="do()" color="primary">
    {{duplicate ? 'Duplicate' : 'Create'}}
  </button>
  <button id="generateButton" mat-button *ngIf="!saveSpinner && case !=undefined && case.caseType == 'UTAM'"
          (click)="generateHtml()"
          color="primary">
    Generate from html
  </button>
  <button mat-button *ngIf="saveSpinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>
