<section>
  <div class="responsive-table-wrapper">
    <table mat-table matSort
           [dataSource]="items"
           [matSortActive]="pageParams.sort.value.split(',')[0]"
           [matSortDirection]="pageParams.sort.value.split(',')[1]"
           (matSortChange)="sortData($event)">

      <!-- Type Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.name}}
        </td>
      </ng-container>

      <!-- Run Date Column -->
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value</th>
        <td class="tooltipInProjectParam" #value mat-cell *matCellDef="let element" matTooltip="{{element.value}}"
            [matTooltipDisabled]="!isOverflow(value)">
          {{element.value}}
        </td>
      </ng-container>


      <!-- Run Date Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description</th>
        <td mat-cell *matCellDef="let element">
          {{element.description}}
        </td>
      </ng-container>

      <!-- Run Date Column -->
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef style="width: 150px;"></th>
        <td mat-cell *matCellDef="let element" style="width: 150px;">
          <button
            (click)="$event.stopPropagation();removeProjectParam(element)"
            mat-icon-button
          >
            <mx-icon marginBottom="true" class="gray_remove" mx="delete"></mx-icon>
          </button>
        </td>
      </ng-container>

      <!-- Stop Column -->
      <ng-container matColumnDef="options" style="width: 100px; max-width: 100px;">
        <th mat-header-cell *matHeaderCellDef style="width: 100px; max-width: 100px;"> Options</th>
        <td mat-cell *matCellDef="let element" style="width: 100px; max-width: 100px;">
          <!-- Suite is stopped ** Cancel execution of the suite? -->

          <button mat-button
                  [color]="'primary'"
                  [matTooltip]="'Set Default Value'"
                  (click)="setDefaultValue(element);$event.stopPropagation();"
                  class="hoverBlue stop-button-min-width">

            <mx-icon mx="reset"></mx-icon>
            <!--            Set Default-->
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index;"
          (click)="clickedItem(row, i)" style="cursor: pointer;"></tr>

    </table>
  </div>

  <spinner-for-table [loading]="isLoading"></spinner-for-table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [length]="pagesInfo.totalElements"
    [pageIndex]="pageParams.page.value"
    [pageSize]="pageParams.size.value"
    (page)="setPageSizeOrIndex($event)"
    showFirstLastButtons></mat-paginator>

</section>
