<h1 mat-dialog-title>{{title}}</h1>

<div mat-dialog-content>

  <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
    <p *ngIf="error" class="mb-0">{{ errorMessage }}</p>
    <button (click)="error=false" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <label>Name</label>
  <div class="mb-2">
    <!--    <input [disabled]="!checkPaths.update.enabled || !create || !allowEdit" type="text"-->
    <!--           [(ngModel)]="userPreferencesParam.name">-->
    {{userPreferencesParam.name}}
  </div>

  <label>Value</label>
  <div class="mb-2">

    <div *ngIf="userPreferencesParam.name == 'mx.user.preferences.cases-workspace.create.case-or-page-object'">
      <mat-button-toggle-group
        (change)="userPreferencesParam.value = $event.value;"
        placeholder=""
        [value]="userPreferencesParam.value">
        <mat-button-toggle [value]="'cases'">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-cases"
            color="none"
            [extension]="'cases'"
          ></app-nav-tree-file-icon>
          Case
        </mat-button-toggle>
        <mat-button-toggle [value]="'pageObjects'">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-pos"
            color="none"
            [extension]="'pos'"
          ></app-nav-tree-file-icon>
          Page Object
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div *ngIf="userPreferencesParam.name == 'mx.user.preferences.cases-workspace.create.case.language'">
      <mat-button-toggle-group
        (change)="userPreferencesParam.value = $event.value;"
        placeholder=""

        [value]="userPreferencesParam.value">
        <mat-button-toggle value="JAVA" color="primary">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-java"
            color="none"
            [extension]="'java'"
          ></app-nav-tree-file-icon>
          Java
        </mat-button-toggle>
        <br>
        <mat-button-toggle value="PYTHON">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-python"
            color="none"
            [extension]="'py'"
          ></app-nav-tree-file-icon>
          Python
        </mat-button-toggle>
        <br>
        <mat-button-toggle value="JAVASCRIPT">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-javascript"
            color="none"
            [extension]="'js'"
          ></app-nav-tree-file-icon>
          JavaScript
        </mat-button-toggle>
        <br>
        <!--      <mat-option value="CSHARP">C#</mat-option>-->
        <!--      <mat-option value="ROBOT_FRAMEWORK">Robot Framework</mat-option>-->
        <mat-button-toggle *ngIf="isLicensed" value="MXML">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-mxml"
            color="none"
            [extension]="'mxml'"
          ></app-nav-tree-file-icon>
          Mxml
        </mat-button-toggle>
        <!--      <mat-option *ngIf="isLicensed" value="UTAM">Utam</mat-option>-->
      </mat-button-toggle-group>
    </div>

    <div *ngIf="userPreferencesParam.name == 'mx.user.preferences.cases-workspace.create.case.framework'">
      <mat-select
        placeholder=""
        [(value)]="userPreferencesParam.value">
        <mat-option *ngFor="let testingFramework of filteredTestingFrameworks" [value]="testingFramework">
          {{parseTestingFrameworkName(testingFramework)}}
        </mat-option>
      </mat-select>
    </div>

    <div *ngIf="userPreferencesParam.name == 'mx.user.preferences.cases-workspace.create.case.template'">
      <mat-select [(value)]="userPreferencesParam.value">
        <mat-option *ngFor="let template of filteredTemplates" [value]="template.name">
          {{template.name}}
          <span [attr.data-content]="template.description" class="option-description"></span>
        </mat-option>
      </mat-select>
    </div>

    <div *ngIf="userPreferencesParam.name == 'mx.user.preferences.cases-workspace.create.page-object.language'">
      <mat-button-toggle-group placeholder=""
                               (change)="userPreferencesParam.value = $event.value;"
                               [value]="userPreferencesParam.value != null ? userPreferencesParam.value : null">
        <mat-button-toggle value="JAVA">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-java"
            color="none"
            [extension]="'java'"
          ></app-nav-tree-file-icon>
          Java
        </mat-button-toggle>
        <br>
        <mat-button-toggle value="PYTHON">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-python"
            color="none"
            [extension]="'python'"
          ></app-nav-tree-file-icon>
          Python
        </mat-button-toggle>
        <br>
        <mat-button-toggle value="JAVASCRIPT">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-javascript"
            color="none"
            [extension]="'js'"
          ></app-nav-tree-file-icon>
          JavaScript
        </mat-button-toggle>
        <br>
        <mat-button-toggle value="UTAM">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-utam"
            color="none"
            [extension]="'utam'"
          ></app-nav-tree-file-icon>
          Utam
        </mat-button-toggle>
        <br>
        <!--      <mat-option value="CSHARP">C#</mat-option>-->
        <!--      <mat-option value="ROBOT_FRAMEWORK">Robot Framework</mat-option>-->
        <mat-button-toggle *ngIf="isLicensed" value="MXML">
          <app-nav-tree-file-icon
            class="toggle-language-icon language-icon-mxml"
            color="none"
            [extension]="'mxml'"
          ></app-nav-tree-file-icon>
          Mxml
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div *ngIf="userPreferencesParam.name == 'mx.user.preferences.cases-workspace.create.page-object.template'">
      <mat-select [(value)]="userPreferencesParam.value">
        <mat-option *ngFor="let template of filteredTemplates" [value]="template.name">
          {{template.name}}
          <span [attr.data-content]="template.description" class="option-description"></span>
        </mat-option>
      </mat-select>
    </div>

    <div *ngIf="userPreferencesParam.name == 'mx.user.preferences.cases-workspace.create.case-or-page-object.ai.help'">
      <mat-checkbox
        [checked]="userPreferencesParam.value === 'true'"
        (change)="userPreferencesParam.value = $event.checked ? 'true' : 'false'"
      >Enabled
      </mat-checkbox>
    </div>


    <!--    <input id="valueInput" [disabled]="!checkPaths.update.enabled || !allowEdit" type="text"-->
    <!--           [(ngModel)]="userPreferencesParam.value">-->
  </div>

  <label>Description</label>
  <div>
    <!--    <input type="text" [disabled]="!checkPaths.update.enabled || !allowEdit" [(ngModel)]="userPreferencesParam.description">-->
    {{userPreferencesParam.description}}
  </div>

  <!--  <label>Auto increment</label>-->
  <!--  <div class="mt-3 ml-1">-->
  <!--    <mat-checkbox [disabled]="!checkPaths.update.enabled || !allowEdit" class="font-style"-->
  <!--                  [(ngModel)]="userPreferencesParam.autoIncrement">-->
  <!--      Auto increment-->
  <!--    </mat-checkbox>-->
  <!--  </div>-->

</div>

<div mat-dialog-actions>
  <button id="cancelButton" mat-button (click)="close()">
    {{allowEdit ? 'Cancel' : 'Close'}}
  </button>

  <button id="createButton" *ngIf="allowEdit" mat-button [disabled]="!checkPaths.update.enabled"
          (click)="create? add() : edit()"
          color="primary">
    {{createOkButton}}
  </button>
</div>
