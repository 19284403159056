import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CloudConfigService} from "../../../../shared/services/cloud-config.service";
import {CheckRolesService} from "../../../../shared/services/check-roles.service";
import {CheckPath} from "../../../mx/options/CheckPath";
import {UserPreferencesParam} from "../../shared/models/userPreferencesParam";
import {UserService} from "../../shared/services/user.service";
import {UserParam} from "../../shared/models/userParams";
import {ProjectService} from "../../../projects/shared/services/project.service";
import {MaxtafTokensStorageService} from "../../../../shared/services/maxtaf-tokens-storage.service";
import {TemplateService} from "../../../../shared/services/template.service";

class UserPreferenceParamCheckPaths {
  update = new CheckPath();
}

@Component({
  selector: 'app-add-user-preference-param-dialog-component',
  templateUrl: './add-user-preference-param-dialog-component.component.html',
  styleUrls: ['./add-user-preference-param-dialog-component.component.css']
})
export class AddUserPreferenceParamDialogComponentComponent implements OnInit {


  checkPaths: UserPreferenceParamCheckPaths;

  @Input('userPreferencesParam') userPreferencesParam: UserParam;
  create = false;
  title = '';
  createOkButton: string;
  backupUserPreferencesParam: UserParam;

  errorMessage = '';
  error = false;
  run = false;
  allowEdit = true;
  configuration = false;
  isLicensed = false;

  filteredTestingFrameworks = [];
  templates = [];
  filteredTemplates = [];

  constructor(
    public dialogRef: MatDialogRef<AddUserPreferenceParamDialogComponentComponent>,
    private userPreferencesParamService: UserService,
    private cloudConfigService: CloudConfigService,
    public checkRolesService: CheckRolesService,
    private projectService: ProjectService,
    private userService: UserService,
    private templateService: TemplateService,
    private maxtafTokensStorageService: MaxtafTokensStorageService,
    @Inject(MAT_DIALOG_DATA) public data: {
      userPreferencesParam: UserParam,
      edit: boolean,
      title: string,
      configuration: boolean
    }
  ) {
    if (data.userPreferencesParam == undefined) {
      this.create = true;
      this.userPreferencesParam = new UserPreferencesParam();
      this.title = 'Add parameter';
      this.createOkButton = 'Add';
    } else {
      this.create = false;
      this.userPreferencesParam = data.userPreferencesParam;
      this.backupUserPreferencesParam = new UserPreferencesParam();
      this.backupUserPreferencesParam.name = data.userPreferencesParam.name;
      this.backupUserPreferencesParam.value = data.userPreferencesParam.value;
      this.title = 'Edit parameter';
      this.createOkButton = 'Save';
    }

    if (data.edit != undefined) {
      this.allowEdit = data.edit;
    }

    if (data.configuration != undefined) {
      this.configuration = data.configuration;
    }

    if (data.title != undefined) {
      this.title = data.title;
    }

    this.checkPaths = this.getProjectParamCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );

    this.projectService.isLicensed(maxtafTokensStorageService.getProjectId()).subscribe(
      res => {
        this.isLicensed = res;
      },
      error => {

      }
    );

    if ('mx.user.preferences.cases-workspace.create.case.framework' == this.userPreferencesParam.name) {
      this.userService
        .getUserPreferencesParam('mx.user.preferences.cases-workspace.create.case.language')
        .subscribe((caseTypeUserPreferencesParam: UserPreferencesParam) => {
            this.getTemplatesList('cases', caseTypeUserPreferencesParam.value, '');
          },
          error => {
            console.error(error);
          })
    }

    if ('mx.user.preferences.cases-workspace.create.case.template' == this.userPreferencesParam.name) {
      this.userService
        .getUserPreferencesParam('mx.user.preferences.cases-workspace.create.case.language')
        .subscribe((caseTypeUserPreferencesParam: UserPreferencesParam) => {
            this.userService
              .getUserPreferencesParam('mx.user.preferences.cases-workspace.create.case.framework')
              .subscribe((caseFrameworkUserPreferencesParam: UserPreferencesParam) => {
                  this.getTemplatesList('cases', caseTypeUserPreferencesParam.value, caseFrameworkUserPreferencesParam.value);
                },
                error => {
                  console.error(error);
                })
          },
          error => {
            console.error(error);
          })
    }

    if ('mx.user.preferences.cases-workspace.create.page-object.template' == this.userPreferencesParam.name) {
      this.userService
        .getUserPreferencesParam('mx.user.preferences.cases-workspace.create.page-object.language')
        .subscribe((pageObjectTypeUserPreferencesParam: UserPreferencesParam) => {
            this.getTemplatesList('pageObjects', pageObjectTypeUserPreferencesParam.value, '');
          },
          error => {
            console.error(error);
          })
    }

  }

  private getTemplatesList(caseOrPageObjects, casetype, testingFramework) {
    this.templateService.getTemplatesList().subscribe(
      res => {
        this.templates = res;

        if (caseOrPageObjects == 'pageObjects') {
          this.getTemplatesFor('pageObjects', casetype, testingFramework);
        } else {
          this.getTestingFrameworksFor('cases', casetype);
          this.getTemplatesFor('cases', casetype, testingFramework);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  getTemplatesFor(type, caseType, testingFramework) {
    if (type == 'pageObjects') {
      this.filteredTemplates = this.templates.filter(
        template => {
          return template.type.toLowerCase() == type.toLowerCase() &&
            template.caseType.toLowerCase() === caseType.toLowerCase();
        }
      );
    } else {
      this.filteredTemplates = this.templates.filter(template => {
          return template.type.toLowerCase() === type.toLowerCase() &&
            template.caseType.toLowerCase() === caseType.toLowerCase() &&
            template.testingFramework.toLowerCase() === testingFramework.toLowerCase();
        }
      );
    }
  }

  getTestingFrameworksFor(casesPageObjects, type) {
    const testingFrameworks = [];
    this.templates.forEach(template => {
      if (
        template.type.toLowerCase() === casesPageObjects &&
        template.type.toLowerCase() === casesPageObjects &&
        template.caseType.toLowerCase() === type.toLowerCase() &&
        !testingFrameworks.includes(template.testingFramework)) {
        testingFrameworks.push(template.testingFramework);
      }
    });

    this.filteredTestingFrameworks = testingFrameworks;
  }

  parseTestingFrameworkName(name) {
    switch (name) {
      case 'JUNIT':
        return 'JUnit';
      case 'TESTNG':
        return 'TestNG';
      case 'CLEAN_JAVA':
        return 'Java Native';
      case 'PYTEST':
        return 'Pytest';
      case 'ROBOT_FRAMEWORK':
        return 'Robot Framework';
      case 'UNITTEST':
        return 'Unittest';
      case 'MOCHA':
        return 'Mocha';
      case 'JASMINE':
        return 'Jasmine';
      case 'WEBDRIVERIO':
        return 'WebdriverIO';
      case 'CLEAN_JAVASCRIPT':
        return 'JavaScript Native';
      case 'CLEAN_PYTHON':
        return 'Python Native';
      case 'NUNIT':
        return 'NUnit';
      case 'CLEAN_CSHARP':
        return 'C# Native';
      default:
        return '';
    }
  }


  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.addShortcutListener, false);
  }

  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for adding a parameter or entering a value after name
  addShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      if (this.userPreferencesParam.name && !(this.userPreferencesParam.value)) {
        document.getElementById('valueInput').focus();
      } else if (this.userPreferencesParam.name && this.userPreferencesParam.value) {
        document.getElementById('createButton').click();
      }
    }
  };

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.addShortcutListener, false);
  }

  add() {
    this.addProjectParams();
  }

  edit() {
    if (this.configuration) {
      this.editConfigurationParams();
    } else {
      this.editProjectParams();
    }
  }

  close() {
    if (!this.create) {
      this.userPreferencesParam.name = this.backupUserPreferencesParam.name;
      this.userPreferencesParam.value = this.backupUserPreferencesParam.value;
    }
    this.closeDialog(false);
  }

  private addProjectParams() {
    // this.error = false;
    // this.userPreferencesParam.createProjectParams(this.userPreferencesParam)
    //   .subscribe(
    //     res => {
    //       this.error = false;
    //       this.closeDialog(true);
    //     },
    //     error => {
    //       console.error(error);
    //       this.errorMessage = error.error.errorMessage;
    //       this.error = true;
    //       // this.closeDialog(false);
    //     }
    //   );
  }

  private editProjectParams() {
    this.error = false;

    this.userPreferencesParamService.setUserPreferencesParam(this.userPreferencesParam.name, this.userPreferencesParam.value)
      .subscribe(
        res => {
          this.error = false;
          this.closeDialog(false);
        },
        error => {
          console.error(error);
          this.errorMessage = error.error.errorMessage;
          this.error = true;
          // this.closeDialog(false);
        }
      );
  }

  private editConfigurationParams() {
    this.error = false;
    this.userPreferencesParamService.setUserPreferencesParam(this.userPreferencesParam.name, this.userPreferencesParam.value)
      .subscribe(
        res => {
          this.error = false;
          this.closeDialog(false);
        },
        error => {
          console.error(error);
          this.errorMessage = error.error.errorMessage;
          this.error = true;
          // this.closeDialog(false);
        }
      );
  }

  closeDialog(res): void {
    this.dialogRef.close(res);
  }

  private getProjectParamCheckPaths(): UserPreferenceParamCheckPaths {
    return {
      update: new CheckPath('PUT', this.cloudConfigService.setProjectParams())
    };
  }

}
