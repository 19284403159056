<div class="data-wrapper">
  <!--    <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>-->

  <!--            <div class="data-title">-->
  <!--              Your data-->
  <!--            </div>-->

  <!--            <mat-card [ngClass]="{'shadow': shadow, 'shadow-none': !shadow}">-->
  <div class="data">
    <label class="data_label">Email: </label>
    <span class="data_span">{{userProject.user.email}}</span>
  </div>

  <div class="data">
    <label class="data_label">Display Name: </label>
    <span class="data_span">
          {{userProject.user.displayName}}
      <button mat-icon-button *ngIf="allowEditName"
              (click)="openUserEditFieldDialog('input','Display Name:','displayName')">
            <mx-icon mx="edit" class="edit-icon" hoverColor="primary" color="accent"></mx-icon>
          </button>
        </span>
  </div>

  <div class="data" *ngIf="userProjectDetailsShow">

    <label class="data_label">SecurityGroups: </label>
    <div *ngIf="allowEditSecurityGroups" class="data_span">
      <mat-select [disabled]="!checkPaths.updateRoles.enabled " [formControl]="selectedSecurityGroups"
                  *ngIf="securityGroups != null && securityGroups.length > 0"
                  (valueChange)="changeSecurityGroup($event)" multiple>
        <mat-option *ngFor="let securityGroup of securityGroups"
                    [disabled]="securityGroup.name == 'OWNER' && this.ownerDisabled"
                    [value]="securityGroup">{{securityGroup.publicName}}</mat-option>
      </mat-select>
    </div>

    <div *ngIf="!allowEditSecurityGroups" class="data_span">
      {{ showSecurityGroups() }}
    </div>
  </div>

  <div class="data" *ngIf="editPassword">
    <label class="data_label">Password: </label>
    <span class="data_span">
          Change password
          <button mat-icon-button (click)="editPasswordDialogComponent()">
            <mx-icon mx="edit" class="edit-icon" hoverColor="primary" color="accent"></mx-icon>
          </button>
        </span>
  </div>

  <div class="data" *ngIf="showApiKey">
    <label class="data_label">
      Api key:


      <button mat-icon-button color="primary" (click)="apiKeyInfo()">
        <mx-icon mx="information"></mx-icon>

      </button>

    </label>
    <span class="data_span" *ngIf="showApiKey">
          <button mat-raised-button color="primary" (click)="createApiKeyDialogComponent()"
                  *ngIf="!this.userProject.existApiKey">
            Create api key
          </button>
          <div *ngIf="this.userProject.existApiKey" style="    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;">
            <div class="mr-1">
            mx-*****************
          </div>
          <button mat-raised-button color="warn" class="ml-1" (click)="deleteApiKeyDialogComponent()">
            Invalidate this api key
          </button>

          </div>



        </span>
  </div>

  <div class="data" *ngIf="userProjectDetailsShow && bridgeActive">
    <label class="data_label">Bridge: </label>
    <span class="data_span" style="overflow: visible">

           <button class="sensor-btn"
                   [ngClass]="{
                      'stopped-color': !bridgeActive,
                      'running-color': bridgeActive
                  }"
                   mat-mini-fab [matTooltip]="bridgeActive? 'active' : 'inactive'">
          </button>
        </span>
  </div>

  <!--            </mat-card>-->
</div>
