import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChatSession} from "../../shared/models/chatSession";
import {MatDialog} from "@angular/material/dialog";
import {EditSessionDialogComponent} from "../edit-session-dialog/edit-session-dialog.component";
import {YesNoDialogComponent} from "../../../dialogs/yes-no-dialog/yes-no-dialog.component";
import {AiChatService} from "../../shared/services/ai-chat.service";
import {MaxtafTokensStorageService} from "../../../../shared/services/maxtaf-tokens-storage.service";
import {Observable, Subscription} from "rxjs";
import {AiConfigService} from "../../shared/services/ai-config.service";
import {GlobalEventsManagerService} from "../../../../shared/services/global-events-manager.service";
import {ChatSessionHelper} from "../../../../shared/models/chatSessionHelper";
import {ChatSessionSearchParams} from "../../shared/services/ai-const.service";

@Component({
  selector: 'app-ai-sessions-list',
  templateUrl: './ai-sessions-list.component.html',
  styleUrls: ['./ai-sessions-list.component.css']
})
export class AiSessionsListComponent implements OnInit {

  @Output('clickedSession') clickedSessionEvent = new EventEmitter<ChatSession>();
  aiChatSessions: ChatSession[] = [];
  relatedSessionPurpose: ChatSession;

  private esAIChatSessionsEventSource: EventSource;
  aiChatSessionsRequestObservable: Subscription;

  chatSessionSearch: ChatSessionSearchParams;

  @Input('chatSessionSearch') set setChatSessionSearch(chatSessionSearch: ChatSessionSearchParams) {
    this.chatSessionSearch = chatSessionSearch;
    this.getChatSessions(this.chatSessionSearch);
  }

  maxHeight: string;

  @Input('maxHeight') set setScrollHeight(maxHeight: number) {
    this.maxHeight = (maxHeight - 60) + "px"
  }


  public setRelatedSessionPurpose(chatSession: ChatSession) {
    this.relatedSessionPurpose = chatSession;
    this.getChatSessions(this.chatSessionSearch);
  }

  // @Input('sessionId') set setSessionPurpose(helper: ChatSessionHelper) {
  //   this.setRelatedSessionPurpose(helper);
  // }

  // setRelatedSessionPurpose(helper: ChatSessionHelper) {
  //   if (helper.caseId == null) {
  //     this.relatedSessionPurpose = undefined;
  //   } else {
  //     this.aiChatService.getChatSessionByPurpose(
  //       "CASE",
  //       helper.caseId,
  //       "'" + helper.caseName + "' Assistant"
  //     ).subscribe(
  //       chatSession => {
  //
  //         this.relatedSessionPurpose = chatSession;
  //
  //       },
  //       error => {
  //         console.error('error ', error);
  //         this.relatedSessionPurpose = undefined;
  //       }
  //     );
  //   }
  // }


  constructor(public dialog: MatDialog,
              public tokensService: MaxtafTokensStorageService,
              public globalEventsManagerService: GlobalEventsManagerService,
              private aiConfigService: AiConfigService,
              private aiChatService: AiChatService,) {

    this.getChatSessions();

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    try {
      this.closeEventSource();
      this.closeConnection();


    } catch (e) {
      console.error(e)
    }
  }

  closeEventSource() {


    try {
      if (this.esAIChatSessionsEventSource) {
        this.esAIChatSessionsEventSource.close();
        this.esAIChatSessionsEventSource.onmessage = null;
        this.esAIChatSessionsEventSource.onerror = null;
        this.esAIChatSessionsEventSource = null;
      }
    } catch (e) {
      console.error(e)
      this.esAIChatSessionsEventSource = null;
    }
  }

  closeConnection() {
    try {
      if (this.aiChatSessionsRequestObservable) {

        this.aiChatSessionsRequestObservable.unsubscribe();
        this.aiChatSessionsRequestObservable = null;
      }
    } catch (e) {
      console.error(e);
    }
  }


  errorMessage = '';
  responseAISession = '';

  public getChatSessions(chatSessionSearch?: ChatSessionSearchParams) {

    this.closeConnection();


    if (chatSessionSearch != null) {
      this.chatSessionSearch = chatSessionSearch;
    }

    this.aiChatSessions = [];

    const user = this.tokensService.getUserId();
    const project = this.tokensService.getProjectId();
    const userProject = this.tokensService.getUserProjectID();
    const fireToken = this.tokensService.getFireToken();
    const refreshToken = this.tokensService.getFireRefreshToken();

    const url = '/api/ai/session' +
      '?' + this.tokensService.USER_PROJECT_TOKEN + '=' + userProject + '&' +
      this.tokensService.PROJECT_TOKEN + '=' + project + '&' +
      this.tokensService.USER_TOKEN + '=' + user + '&' +
      this.tokensService.FIREBASE_TOKEN + '=' + fireToken + '&' +
      this.tokensService.FIREBASE_REFRESH_TOKEN + '=' + refreshToken + '&' +
      this.tokensService.USER_TIMEZONE_ID + '=' + Intl.DateTimeFormat().resolvedOptions().timeZone + '';
    (this.chatSessionSearch != null ? (
      ((this.chatSessionSearch.hideCaseRelated.value == null) ? "" : '&hideCaseRelated=' + this.chatSessionSearch.hideCaseRelated.value) +
      ((this.chatSessionSearch.title.value == null) ? "" : '&title=' + this.chatSessionSearch.title.value) +
      ((this.chatSessionSearch.exactSearch.value == null) ? "" : '&exactSearch=' + this.chatSessionSearch.exactSearch.value)
    ) : "");

    this.esAIChatSessionsEventSource = new EventSource(url);

    this.responseAISession = '';
    this.errorMessage = '';

    this.aiChatSessionsRequestObservable = this.askMaxtafAISessions().subscribe({
      next: (result) => {
        this.errorMessage = '';

        let objResponse: ChatSession;
        try {
          objResponse = JSON.parse(result);

          if (objResponse != null) {
            this.aiChatSessions.push(objResponse);
          }

        } catch (e) {
          console.error("error: ", e);

          this.closeEventSource();
        }

      },
      error: (error) => {
        this.closeEventSource();
      },
      complete: () => {
        this.closeEventSource();
      }
    });
  }

  askMaxtafAISessions(): Observable<string> {
    return new Observable<string>((observer) => {
      this.esAIChatSessionsEventSource.onmessage = (event) => {
        observer.next(event.data);
      };

      this.esAIChatSessionsEventSource.onerror = (error) => {
        console.error(error);
        observer.error(error);


        this.closeEventSource();
        this.closeConnection();
      };
      return () => {
        console.error('qw1 EventSource return:');
        this.closeEventSource();
      };
    });
  }

  editSessions(session: ChatSession) {
    const dialogRef = this.dialog.open(EditSessionDialogComponent, {
      width: '500px',
      data: {
        session: session
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  removeSession(session: ChatSession) {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        title: "Are you sure you want to delete this chat?",
        body: '',
        disableSecondButton: false,
        disableThirdButton: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        return;
      } else if (result) {
        this.aiChatService.deleteChatSession(session.id)
          .subscribe(res => {
              this.getChatSessions();
            }, error => {
              console.error(error);
            }
          );
      }
    });
  }

  isOverflowingTitle(element: HTMLElement): boolean {
    return element.offsetWidth < element.scrollWidth;
  }

  selectedSession(session: ChatSession) {
    this.clickedSessionEvent.emit(session);
  }

  createNewChatSession() {
    let session: ChatSession = new ChatSession(
      null,
      true,
      null,
      'New chat',
      '',
      null
    );

    this.aiChatService.createChatSession(session)
      .subscribe(res => {
          this.clickedSessionEvent.emit(res);
        }, error => {
          console.error('qw1 error in creating new session: ', error);
        }
      );
  }
}
