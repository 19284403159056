import {ApiService} from '../../../../shared/services/api.service';
import {Injectable} from '@angular/core';
import {Params} from '../../../mx/util/params/params';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {CaseFileLocation} from '../models/case-file-location';
import {DuplicateCase} from '../models/duplicateCase';

@Injectable()
export class CaseService {

  mainCode = '';
  mainCodeLanguage = 'text/plain';
  secondCode = '';
  secondCodeLanguage = 'text/plain';

  setMainCode(mainCode, mainCodeLanguage) {
    this.mainCode = mainCode;
    this.mainCodeLanguage = mainCodeLanguage;
  }

  cleanMainCode() {
    this.mainCode = '';
    this.mainCodeLanguage = 'text/plain';
  }

  setSecondCode(secondCode, secondCodeLanguage) {
    this.secondCode = secondCode;
    this.secondCodeLanguage = secondCodeLanguage;
  }

  cleanSecondCode() {
    this.secondCode = '';
    this.secondCodeLanguage = 'text/plain';
  }

  constructor(
    private apiService: ApiService,
    private cloudConfigService: CloudConfigService
  ) {
  }

  getAll(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getCases(), params);
  }

  getAllArchived(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);

    const params = {...searchParams, ...pageParams};
    return this.apiService.get(this.cloudConfigService.getCasesFromTrash(), params);
  }

  getCase(id: string, projectId?: string) {
    return this.apiService.get(this.cloudConfigService.getCase(id, projectId));
  }

  getCaseByFileLocation(fileLocation) {
    return this.apiService.get(this.cloudConfigService.getCaseByFileLocation() + '?fileLocation=' + fileLocation);
  }

  getCaseArchived(id: string) {
    return this.apiService.get(this.cloudConfigService.getCaseArchived(id));
  }

  addCase(body: any, templateName: string) {
    return this.apiService.post(this.cloudConfigService.addCase(true, templateName), body, );
  }

  duplicateCase(caseId: string, duplicateCaseInfo?: DuplicateCase) {
    return this.apiService.post(this.cloudConfigService.duplicateCase(caseId), duplicateCaseInfo);
  }

  updateCase(id: string, body: any) {
    return this.apiService.put(this.cloudConfigService.updateCase(id), body);
  }

  updateCaseParams(id: string, params: string) {
    return this.apiService.put(this.cloudConfigService.updateCaseParams(id), params);
  }

  restoreCase(id: string, newCaseName?: string) {
    return this.apiService.put(this.cloudConfigService.unTrashCase(id, newCaseName), {});
  }

  restoreCases(filter?) {
    filter = new Params(filter);

    const params = {...filter};
    return this.apiService.put(this.cloudConfigService.unTrashCases(), params);
  }

  deleteCase(id: string) {
    return this.apiService.delete(this.cloudConfigService.deleteCase(id));
  }

  deleteCases(filter?) {
    filter = new Params(filter);

    const params = {...filter};
    return this.apiService.delete(this.cloudConfigService.deleteCases(), params);
  }

  deleteCaseArchived(id: string) {
    return this.apiService.delete(this.cloudConfigService.deleteCaseArchived(id));
  }

  deleteCasesArchived(filter?) {
    filter = new Params(filter);

    const params = {...filter};
    return this.apiService.delete(this.cloudConfigService.deleteCasesArchived(), params);
  }

  getCaseSuites(id: string) {
    return this.apiService.get(this.cloudConfigService.getCaseSuites(id));
  }

  getSuitesArchived(id: string) {
    return this.apiService.get(this.cloudConfigService.getSuitesArchived(id));
  }

  removeFromSuites(id: string) {
    return this.apiService.delete(this.cloudConfigService.removeCaseFromSuites(id));
  }

  removeFromSuitesArchived(id: string) {
    return this.apiService.delete(this.cloudConfigService.removeCaseFromSuitesArchived(id));
  }

  replaceWithActiveCase(archivedCaseId: string) {
    return this.apiService.put(this.cloudConfigService.replaceWithActiveCase(archivedCaseId), {});
  }

  getBuildAutomationToolPath() {
    return this.apiService.get(this.cloudConfigService.getTestAndSourceDirectory());
  }


  upadteCaseFileLocation(caseFileLocation: CaseFileLocation, overwriteFileOnNewFileLocation: boolean = false) {
    return this.apiService.put(this.cloudConfigService.updateCaseFileLocation(caseFileLocation.id) + '?overwriteFileOnNewFileLocation=' + overwriteFileOnNewFileLocation, caseFileLocation);
  }

  generateUtam(body: any) {
    return this.apiService.post(this.cloudConfigService.generateUtam(), body);
  }

}
