import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InvitationUserService} from '../../shared/services/invitation-user.service';
import {InvitationUser} from '../../shared/models/invitationUser';
import {YesNoDialogComponent} from '../../../dialogs/yes-no-dialog/yes-no-dialog.component';
import {PageParams} from '../../../mx/util/params/PageParams';
import {UserSearchParams} from '../../shared/services/user-const.service';
import {MxTable} from '../../../mx/util/list/MxTable';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {UserProjectConfigService} from '../../../../shared/services/user-project-config.service';

class UserRequestsListCheckPaths {
  remove = new CheckPath();
  sendAgain = new CheckPath();
}

@Component({
  selector: 'app-user-requests-list',
  templateUrl: './user-requests-list.component.html',
  styleUrls: ['./user-requests-list.component.css']
})
export class UserRequestsListComponent extends MxTable<InvitationUser> implements OnInit {

  displayedColumns = ['email', 'securityGroups', 'actions'];
  idName = 'email';

  pageParams: PageParams;
  searchParams: UserSearchParams;

  checkPaths: UserRequestsListCheckPaths;

  constructor(
    public dialog: MatDialog,
    private invUserService: InvitationUserService,
    private userProjectConfigService: UserProjectConfigService,
    public checkRolesService: CheckRolesService,
    private snackBar: MatSnackBar
  ) {
    super();

    this.checkPaths = this.getUserRequestsListCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );
  }


  ngOnInit(): void {
    super.ngOnInit();
  }

  getItems() {
    this.startLoadingSpinner();
    this.setItems([]);
    this.invUserService.getAllUserRequests(this.searchParams, this.pageParams)
      .subscribe(
        res => {
          this.stopLoadingSpinner();
          if (res) {
            this.setItems(res.content);
            this.setPageInfo(res);
          } else {
            this.setItems([]);
          }
        },
        error => {
          this.showError(error);
          this.stopLoadingSpinner();
        }
      );
  }

  removeRequestDialog(email: string): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        title: 'Cancellation of requests',
        body: 'Are you sure you want to cancel this new user request?',
        label1: 'YES'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeRequest(email);
      }
    });
  }

  private removeRequest(email: string) {
    this.invUserService.deleteRequests(email).subscribe(
      res => {
        this.openSnackBar('Removed', 'OK');
        this.refreshItems();
      },
      error => {
        this.showError(error);
        this.openSnackBar('Request failed. Try again later.', 'OK');
      }
    );
  }

  sendAgainRequest(email: string): void {
    this.invUserService.refreshRequest(email).subscribe(
      res => {
        this.openSnackBar('Refreshed', 'OK');
      },
      error => {
        console.error(error);
        this.openSnackBar('Request failed. Try again later.', 'OK');
        this.error.emit(error);
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  private getUserRequestsListCheckPaths(): UserRequestsListCheckPaths {
    return {
      remove: new CheckPath('DELETE', this.userProjectConfigService.removeRequest()),
      sendAgain: new CheckPath('POST', this.userProjectConfigService.invitationUserRefresh())
    };
  }
}
