<div mat-dialog-content [ngStyle]="{ 'padding': type === 'properties' ? '0' : null }">

  <div *ngIf="type == 'input'">
    <input type="text" [(ngModel)]="value">
  </div>

  <div *ngIf="type == 'textarea'">
    <mat-form-field class="w-100">
      <overlay-scrollbars style="max-height: 300px !important;" [options]="{ scrollbars: { autoHide: 'n' } }">
        <textarea matInput placeholder="" class="overflow-hidden" [matTextareaAutosize]="true"
                  [(ngModel)]="value"></textarea>
      </overlay-scrollbars>
    </mat-form-field>
  </div>

  <div *ngIf="type == 'number'">
    <input OnlyNumber="true" matInput type="number" maxlength="6" placeholder="" [(ngModel)]="value">
  </div>

  <div *ngIf="type == 'properties'">
    <mat-tab-group class="params-tab-group" [(selectedIndex)]="activeTab">

      <!-- Table View -->
      <mat-tab class="params-table-tab" label="Table View">
        <overlay-scrollbars class="params-input-scrollbars" [options]="{ scrollbars: { autoHide: 'n' } }">
          <mat-table #tableRef class="params-table" [dataSource]="parsedProperties"
                     (keydown)="enterKeyListener($event)">

            <!-- Key Column -->
            <ng-container matColumnDef="key">
              <th class="params-table-column" mat-header-cell *matHeaderCellDef></th>
              <td class="params-table-column" mat-cell *matCellDef="let element">
                <div class="input-container">
                  <input class="params-table-input" matInput [(ngModel)]="element.key"
                         (ngModelChange)="updateValueFromProperties()"
                         placeholder="Enter Key"/>
                </div>
              </td>
            </ng-container>

            <!-- Value Column -->
            <ng-container matColumnDef="value">
              <th class="params-table-column" mat-header-cell *matHeaderCellDef></th>
              <td class="params-table-column" mat-cell *matCellDef="let element">
                <div class="input-container">
                  <input class="params-table-input" matInput [(ngModel)]="element.value"
                         (ngModelChange)="updateValueFromProperties()"
                         placeholder="Enter Value"/>
                </div>
              </td>
            </ng-container>

            <!-- Delete Button Column -->
            <ng-container matColumnDef="delete">
              <th class="params-table-column delete" mat-header-cell *matHeaderCellDef></th>
              <td class="params-table-column delete" mat-cell *matCellDef="let element; let i = index;">
                <button class="params-table-delete-button" mat-button (click)="deleteRow(i)">
                  <mx-icon mx="delete" color="rgba(0,0,0,0.6)"></mx-icon>
                </button>
              </td>
            </ng-container>


            <tr class="params-table-row" mat-row *matRowDef="let row; columns: ['key', 'value', 'delete'];"></tr>
            <tfoot>
            <tr class="params-table-row">
              <td class="params-table-column">
                <input class="params-table-input" matInput placeholder="Enter Key" (input)="handleFooterInput()"/>
              </td>
              <td class="params-table-column">
                <input class="params-table-input" matInput placeholder="Enter Value" (input)="handleFooterInput()"/>
              </td>
            </tr>
            </tfoot>
          </mat-table>
        </overlay-scrollbars>
      </mat-tab>

      <!-- Text View -->
      <mat-tab label="Text View">
        <!--        <mat-form-field class="w-100">-->
        <overlay-scrollbars class="params-input-scrollbars params-edit-textview-container"
                            [options]="{ scrollbars: { autoHide: 'n' } }">
            <textarea matInput placeholder="" class="overflow-hidden params-textview-input" [matTextareaAutosize]="true"
                      [(ngModel)]="value" (ngModelChange)="updateValueFromProperties()"></textarea>
        </overlay-scrollbars>
        <!--        </mat-form-field>-->
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button *ngIf="name=='Params:'" color="primary">
    <a target="_blank" href="https://www.maxtaf.com/guides/mtc-guide/?version=Cloud#caseParams">
      <mx-icon mx="information"></mx-icon>
    </a>
  </button>

  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button id="okButton" [mat-dialog-close]="value" color="primary">Save</button>
</div>
