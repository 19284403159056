import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Case} from '../../cases/shared/models/case';
import {Suite} from '../../suites/shared/models/suite';
import {CaseService} from '../../cases/shared/services/case.service';
import {SuiteService} from '../../suites/shared/services/suite.service';
import {EditDialogComponent} from '../edit-dialog/edit-dialog.component';

@Component({
  selector: 'app-same-name-already-exists',
  templateUrl: './same-name-already-exists.component.html',
  styleUrls: ['./same-name-already-exists.component.css']
})
export class SameNameAlreadyExistsComponent implements OnInit {

  className = 'SameNameAlreadyExistsComponent';

  type = '';
  case: Case = new Case();
  suite: Suite = new Suite();

  showSaveError = false;
  error;


  constructor(
    private caseService: CaseService,
    private suiteService: SuiteService,
    public dialogRef: MatDialogRef<SameNameAlreadyExistsComponent>,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { case: Case, suite: Suite }
  ) {
    dialogRef.disableClose = true;

    if (data.case) {
      this.case = Object.assign({}, data.case);
      this.type = 'Case';
    }
    if (data.suite) {
      this.suite = Object.assign({}, data.suite);
      this.type = 'Suite';
    }
  }

  ngOnInit(): void {
  }

  replace() {
    if (this.type == 'Case') {
      this.replaceCase();
    } else {
      this.replaceSuite();
    }
  }

  replaceCase() {
    this.caseService.replaceWithActiveCase(this.case.id)
      .subscribe(
        res => {
          this.openSnackBar('Case is repleaced', 'OK', 5);
          this.dialogRef.close(null);
        },
        error => {
          console.error('error', error);
          this.openSnackBar('An error occurred, try again later.', 'OK', 5);
          this.dialogRef.close(false);
        }
      );
  }

  replaceSuite() {
    this.suiteService.replaceWithActiveSuite(this.suite.id)
      .subscribe(
        res => {
          this.openSnackBar('Suite is repleaced', 'OK', 5);
          this.dialogRef.close(null);
        },
        error => {
          console.error('error', error);
          this.openSnackBar('An error occurred, try again later.', 'OK', 5);
          this.dialogRef.close(false);
        }
      );
  }

  openSnackBar(message: string, action: string, timeInSec: number) {
    const sec = 1000;
    this.snackBar.open(message, action, {
      duration: timeInSec * sec
    });
  }

  openEditNameDialog() {
    this.openEditDialog('input', 'Name');
  }

  openEditDialog(type, name) {

    const dialogRef = this.dialog.open(EditDialogComponent, {
      width: '700px',
      data: {type, name, value: (type = 'Case') ? this.case.name : this.suite.name}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result != null) {
        this.restore(result);
        // (this.type == "Case") ? this.case.name = result : this.suite.name = result;
        // (this.type == "Case") ? this.updateCase() : this.updateSuite();
      }
    });
  }

  private restore(newName?: string) {
    if (newName != undefined) {
      (this.type == 'Case') ? this.case.name = newName : this.suite.name = newName;
    }
    if (this.type == 'Case') {
      this.restoreCase();
    } else {
      this.restoreSuite();
    }
  }

  private restoreCase() {
    this.showSaveError = false;

    this.caseService.restoreCase(this.case.id, this.case.name)
      .subscribe(
        res => {
          this.dialogRef.close(null);
        },
        error => {
          if (error.status == 409) {
            this.sameNameAlreadyExistsComponentDialog();
          } else {
            this.showError(error);
          }
        }
      );
  }

  restoreSuite() {
    this.showSaveError = false;

    this.suiteService.restoreSuite(this.suite.id, this.suite.name)
      .subscribe(
        res => {
          this.dialogRef.close(null);
        },
        error => {
          if (error.status == 409) {
            this.sameNameAlreadyExistsComponentDialog();
          } else {
            this.showError(error);
          }
          /*this.errorCode = error['error']['errorCode'];
          this.errorMessage = error['error']['errorMessage'];
          this.showSaveError = true;*/
        }
      );
  }


  showError(error) {
    this.error = error;
    this.showSaveError = true;
  }

  sameNameAlreadyExistsComponentDialog(): void {
    if (this.type == 'Case') {
      this.suite = undefined;
    } else {
      this.case = undefined;
    }

    const dialogRef = this.dialog.open(SameNameAlreadyExistsComponent, {
      width: '400px',
      data: {case: this.case, suite: this.suite}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        this.dialogRef.close(null);
      }
      if (result) {
        this.restore();
      }
    });
  }
}

