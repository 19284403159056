import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {EditMessageDialogComponent} from '../edit-message-dialog/edit-message-dialog.component';
import {YesNoDialogComponent} from "../../../dialogs/yes-no-dialog/yes-no-dialog.component";
import {AiChatService} from "../../shared/services/ai-chat.service";

interface MessageElement {
  content: string;
  isCode: boolean;
}

@Component({
  selector: 'app-ai-message',
  templateUrl: './ai-message.component.html',
  styleUrls: ['./ai-message.component.css']
})
export class AiMessageComponent implements OnInit {

  processedMessage: MessageElement[] = [];
  message = undefined;

  @Input('message') set setMessage(newMessage: string) {
    this.message = newMessage;
    this.processedMessage = this.processMessage(this.message);
    //this.message = newMessage.replace(/`([^`]*)`/g, '<b>`$1`</b>');
  }

  @Input('ai') ai = false;
  @Input('chatId') chatId;
  @Input('chatSessionId') chatSessionId;

  @Output('refreshAndAsk') refreshAndAsk = new EventEmitter<string>();
  @Output('refreshChatRecords') refreshChatRecords = new EventEmitter<string>();

  constructor(public snackBar: MatSnackBar,
              private aiChatService: AiChatService,
              public dialog: MatDialog) {

  }

  ngOnInit(): void {
  }


  copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.message;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.openSnackBar('Successfully copied to clipboard', 'OK', 5);
  }

  openSnackBar(message: string, action: string, timeInSec: number) {
    const sec = 1000;
    this.snackBar.open(message, action, {
      duration: timeInSec * sec
    });
  }

  processMessage(input: string): MessageElement[] {
    const elements: MessageElement[] = [];

    let startIndex = 0;
    let endIndex = 0;
    let isInCodeBlock = false;

    while (endIndex < input.length) {
      if (!isInCodeBlock && input.slice(endIndex, endIndex + 3) === '```') {
        if (startIndex != 0 || endIndex != 0) {
          const content = input.slice(startIndex, endIndex);
          const element: MessageElement = {
            content: content,
            isCode: content.startsWith('```') && content.endsWith('```'),
          };

          elements.push(element);
        }
        isInCodeBlock = true;
        startIndex = endIndex;
        endIndex += 3;
      } else if (isInCodeBlock && input.slice(endIndex, endIndex + 3) === '```') {
        isInCodeBlock = false;
        const content = input.slice(startIndex, endIndex + 3);
        const element: MessageElement = {
          content: content,
          isCode: content.startsWith('```') && content.endsWith('```'),
        };

        elements.push(element);
        endIndex += 3;
        startIndex = endIndex;
      } else {
        endIndex++;
      }
    }

    if (startIndex < endIndex) {
      const content = input.slice(startIndex, endIndex);
      const element: MessageElement = {
        content: content,
        isCode: content.startsWith('```') && content.endsWith('```'),
      };

      elements.push(element);
    }

    return elements;
  }

  editMessage() {
    const dialogRef = this.dialog.open(EditMessageDialogComponent, {
      width: '1200px',
      // panelClass: 'custom-dialog-container',
      data: {
        message: this.message,
        id: this.chatId,
        sessionId: this.chatSessionId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.dialog.closeAll();
        this.refreshAndAsk.emit();
      }
    });
  }

  deleteMessage() {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        title: "Are you sure you want to delete this chat record?",
        body: '',
        disableSecondButton: false,
        disableThirdButton: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        return;
      } else if (result) {
        this.aiChatService.deleteChatRecordAndRemoveAllAfter(this.chatId, this.chatSessionId)
          .subscribe(res => {
              this.refreshChatRecords.emit();
            }, error => {
              console.error('error in creating new session: ', error);
            }
          );
      }
    });
  }
}
