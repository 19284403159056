<section>
  <div class="responsive-table-wrapper">
    <table mat-table matSort
           [dataSource]="items"
           [matSortActive]="pageParams.sort.value.split(',')[0]"
           [matSortDirection]="pageParams.sort.value.split(',')[1]"
           (matSortChange)="sortData($event)">

      <!-- Send Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email</th>
        <td mat-cell *matCellDef="let element">
          <div class="user-profile">
            <div class="user-avatar">
              <mx-icon mx="user-avatar"></mx-icon>
            </div>
            <div class="profile-description">
              <div class="profile-primary-text">{{element.email}}</div>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- SecurityGroup Column -->
      <ng-container matColumnDef="securityGroups">
        <th mat-header-cell *matHeaderCellDef> SecurityGroups</th>
        <td mat-cell *matCellDef="let element">
          <span *ngFor="let securityGroup of element.securityGroups">{{securityGroup.publicName}} &nbsp;</span>
        </td>
      </ng-container>

      <!-- Remove Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">

          <button mat-icon-button
                  *ngIf="checkPaths.remove.enabled"
                  (click)="removeRequestDialog(element.email)">
            <mx-icon marginBottom="true" class="gray_mx_icon" mx="delete"
                     matTooltip="Cancellation of requests"></mx-icon>
          </button>
          <button mat-icon-button (click)="sendAgainRequest(element.email)"
                  *ngIf="checkPaths.sendAgain.enabled">
            <mx-icon marginBottom="true" class="gray_mx_icon" mx="refresh"
                     matTooltip="Send the request again"></mx-icon>
          </button>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index" (click)="clickedItem(row, i)"
          style="cursor: pointer;"></tr>

    </table>
  </div>

  <spinner-for-table [loading]="isLoading"></spinner-for-table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [length]="pagesInfo.totalElements"
    [pageIndex]="pageParams.page.value"
    [pageSize]="pageParams.size.value"
    (page)="setPageSizeOrIndex($event)"
    showFirstLastButtons></mat-paginator>

</section>
