import {Directive, EventEmitter, Input, Output} from '@angular/core';

@Directive()
export abstract class MxFilter {

  @Output('close')
  public close = new EventEmitter<null>();

  public abstract search: any;

  public searchParams;

  /*
  * Set searchParams
  * */
  @Input('searchParams')
  public set setSearchParams(searchParams) {
    this.searchParams = searchParams;
    this.setStartSearch();
  }

  /*
  * Set ignore search params
  * */
  @Input('lockSearchParams')
  public lockSearchParams = ['completedRuns', 'activeRuns'];

  /*
  * Return searchParams
  * */
  @Output('searchParamsChange')
  public searchParamsChange = new EventEmitter<any>();

  /*
  * Return publicSearch
  * */
  @Output('filter')
  public filter = new EventEmitter<any>();

  protected constructor() {
    this.initSearch();
  }

  public filterRecords() {

    this.generatePublicSearchFromSearch();
    this.searchParamsChange.emit(this.searchParams);

    this.filter.emit(this.searchParams);
  }

  public clickedClose() {
    this.close.emit();
  }

  public clearFilter() {
    const tempSearch = this.searchDefaultValues();
    Object.keys(this.search).forEach(key => {
      if (!this.lockSearchParams.includes(key)) {
        this.search[key] = tempSearch[key];
      }
    });

    this.filterRecords();
  }

  public abstract searchDefaultValues(): any;

  public generatePublicSearchFromSearch() {

    Object.keys(this.searchParams).forEach(key => {

      if (this.search[key] != undefined && this.search[key] !== '') {
        this.searchParams[key].value = this.search[key];

      } else {
        if (key != 'completedRuns' && key != 'activeRuns') {
          this.searchParams[key].value = this.searchParams[key].defaultValue;
        }
      }

    });
    return this.searchParams;
  }

  public setStartSearch() {
    const paramsKeys: string[] = Object.keys(this.search);

    paramsKeys.forEach(key => {
      if (this.searchParams[key] != undefined && this.searchParams[key].value != undefined) {
        this.search[key] = this.searchParams[key].value;
      } else {
        this.search[key] = this.searchParams[key].defaultValue;
      }
    });

    if (this.searchParams.exactSearch.value == undefined) {
      this.search.exactSearch = 'Contains';
    } else {
      if (this.searchParams.exactSearch.value.toLowerCase() == 'exact') {
        this.search.exactSearch = 'Exact';
      } else {
        this.search.exactSearch = 'Contains';
      }
    }
  }


  private initSearch() {
    this.search = this.searchDefaultValues();
  }
}
