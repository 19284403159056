<div class="chat-sessions-filter" (keyup.enter)="filterRecords()">

  <!--  <div class="row">-->


  <!--  </div>-->

  <div class="row">

    <div class="col-md">
      <mat-form-field class="form-field-full-width">
        <input matInput type="text" placeholder="Title..." [disabled]="lockSearchParams.includes('title')"
               [(ngModel)]="search.title">
      </mat-form-field>
    </div>

    <div class="col-md">
      <div class="form-field-full-width" style="color: black">
        <mat-checkbox [(ngModel)]="search.hideCaseRelated" class="form-field-full-width" color="primary">
          Hide case-related
        </mat-checkbox>
      </div>
    </div>

  </div>

  <div class="row">

    <div class="col-md">
      <mat-form-field class="form-field-full-width">
        <mat-select placeholder="Filter done as exact match, or not" [(value)]="search.exactSearch">
          <mat-option value="Contains">Contains</mat-option>
          <mat-option value="Exact">Exact</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md">
      <button mat-raised-button class="mr-1 mb-1 session-filter-buttons" (click)="filterRecords()" mat-button
              color="primary">
        <mx-icon marginBottom="true" mx="filter"></mx-icon>
        <span class="pl-1">Filter</span>
      </button>
      <button mat-raised-button class="mr-1 mb-1 session-filter-buttons" (click)="clearFilter()" mat-button
              color="primary">
        <mx-icon marginBottom="true" mx="clear"></mx-icon>
        <span class="pl-1">Clear</span>
      </button>
      <button mat-raised-button class="mb-1 session-filter-buttons" (click)="clickedClose()" mat-button color="primary">
        <mx-icon marginBottom="true" mx="close"></mx-icon>
        <span class="pl-1">Close</span>
      </button>
    </div>

  </div>
</div>

