import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ParallelSerial} from '../../shared/models/parallelSerial';
import {RunParamsType} from '../../shared/models/runParamsType';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {UserService} from '../../../users/shared/services/user.service';

@Component({
  selector: 'app-run-advance-settings',
  templateUrl: './run-advance-settings.component.html',
  styleUrls: ['./run-advance-settings.component.css']
})
export class RunAdvanceSettingsComponent implements OnInit {
  ParallelSerialEnum: any = ParallelSerial;
  RunParamsTypeEnum = RunParamsType;
  errorMessage = '';

  @Input('parallelSerial') parallelSerial: ParallelSerial = this.ParallelSerialEnum.SERIAL;
  @Output('parallelSerialChange') parallelSerialChange = new EventEmitter<ParallelSerial>();

  @Input('runParamsType') runParamsType: RunParamsType = this.RunParamsTypeEnum.PRIORITY;
  @Output('runParamsTypeChange') runParamsTypeChange = new EventEmitter<RunParamsType>();

  @Input('parallelSerialDataDriven') parallelSerialDataDriven: ParallelSerial = this.ParallelSerialEnum.SERIAL;
  @Output('parallelSerialDataDrivenChange') parallelSerialDataDrivenChange = new EventEmitter<ParallelSerial>();

  @Input('repeatRun') repeatRun = 1;
  @Output('repeatRunChange') repeatRunChange = new EventEmitter<number>();

  @Input('params') params = '';
  @Output('paramsChange') paramsChange = new EventEmitter<string>();

  @Input('title') title = 'Advanced';
  @Input('hideTitle') hideTitle = false;
  @Input('description') description = '';
  @Input('caseOrSuiteType') caseOrSuiteType = 'case or suite';
  @Input('caseOrSuiteName') caseOrSuiteName = '';

  fileName = 'Choose data driven file';
  selectedFiles: FileList;
  @Output('selectedFilesChange') selectedFilesChange = new EventEmitter<FileList>();

  currentFileUpload: File;
  showUploadError = false;
  spinner = false;
  progress = 0;
  public curlyBrace = '{';

  @Input('hideDataDriven') hideDataDriven = false;
  @Input('hideParams') hideParams = false;

  @Input('caseOrSuite') set caseOrSuite(caseOrSuite: string) {
    if (caseOrSuite == 'suite') {
      this.apiRequestUrl = this.cloudConfigService.runSuiteForApiWithName() + '?suiteName=';
    } else {
      this.apiRequestUrl = this.cloudConfigService.runCaseForApiWithName() + '?testName=';
    }
  }

  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  apiRequestUrl;

  // Workaround for angular component issue #13870
  disableAnimation = true;

  showErrorAlert = false;
  error;

  baseRun = false;

  constructor(public tokensService: MaxtafTokensStorageService,
              public snackBar: MatSnackBar,
              public userService: UserService,
              public cloudConfigService: CloudConfigService) {
    this.baseRun = this.userService.getCurrentUserProject().roles.includes('ROLE_BASE_RUN_CASE');
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  selectFile(event) {

    const file: File = event.target.files.item(0);

    if (file.name.toLowerCase().match('.*\.csv|.*\.xlsx')) {
      this.selectedFiles = event.target.files;
      this.fileName = this.selectedFiles.item(0).name;
    } else {
      this.showError({
        name: 'Invalid format!',
        message: 'Supported extensions are .csv and .xlsx - please select .csv or .xlsx file for import.'
      });
      // alert('Invalid format! Supported extensions are .csv and .xlsx - please select text file for import.');
      this.selectedFiles = null;
      this.fileName = 'Choose data driven file';
    }
    this.selectedFilesChange.emit(this.selectedFiles);
  }

  repeatRunChangeValue() {
    this.repeatRunChange.emit(this.repeatRun);
  }

  parallelSerialChangeValue($event: any) {
    this.parallelSerial = $event;

    this.parallelSerialChange.emit(this.parallelSerial);
  }

  paramsChangeValue() {
    this.paramsChange.emit(this.params);
  }

  runParamsTypeChangeValue($event: any) {
    this.runParamsType = $event;

    this.runParamsTypeChange.emit(this.runParamsType);
  }

  parallelSerialDataDrivenChangeValue($event: any) {
    this.parallelSerialDataDriven = $event;

    this.parallelSerialDataDrivenChange.emit(this.parallelSerialDataDriven);
  }

  copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.cronRequest();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.openSnackBar('Successfully copied to clipboard', 'OK', 5);
  }

  private cronRequest() {
    return 'curl --request POST \'' + this.apiRequestUrl + '' + this.caseOrSuiteName + '\' ' +
      '-H \'X-Project-Id: ' + this.tokensService.getProjectId() + '\' ' +
      '-H \'X-Maxtaf-Api-Key: your_api_key\' ' +
      '-H \'X-User-TimeZone-Id: ' + this.timeZone + '\' ' +
      '-H \'Accept: application/json\' ' +
      '-F \'runDetails={ ' +
      '"repeatRun": "' + this.repeatRun + '", ' +
      '"syncAsync": "SYNC", ' +
      '"parallelSerial": "' + this.parallelSerial + '", ' +
      '"dataDrivenParallelSerial": "' + this.parallelSerialDataDriven + '", ' +
      '"runParams": "' + this.params + '", ' +
      '"runParamsType": "' + this.runParamsType + '" ' +
      '};type=application/json\' ' +
      ((this.selectedFiles == null) ? '' : '-F \'dataDrivenFile=@"your_datadriven_file_path";type=text/plain\'');
  }

  openSnackBar(message: string, action: string, timeInSec: number) {
    const sec = 1000;
    this.snackBar.open(message, action, {
      duration: timeInSec * sec
    });
  }
}
