<div
  *ngIf="editorType == editorTypes.Text"
  #editor
  id="monaco-editor"
  class="code-editor-test"
  style="background: white"
  [ngStyle]="{
  'height': height ? height + '' : '400px',
  'width': width ? width + '' : '100%'
  }"
  [ngClass]="{'ready-only': !this.edit}"
>
</div>

<app-image-viewer-workspace
  *ngIf="editorType == editorTypes.Image"
  [pathWithName]="pathWithName"
  class="code-editor-test"
  [ngStyle]="{'height': height ? height + '' : '400px'}"
>
</app-image-viewer-workspace>

<app-mx-video-viewer
  *ngIf="editorType == editorTypes.Video"
  [pathWithName]="pathWithName"
  class="code-editor-test"
  [ngStyle]="{'height': height ? height + '' : '400px'}"
>
</app-mx-video-viewer>

<app-mx-html-viewer
  *ngIf="editorType == editorTypes.Html"
  [pathWithName]="pathWithName"
  class="code-editor-test"
  [ngStyle]="{'height': height ? height + '' : '400px'}"
>
</app-mx-html-viewer>

