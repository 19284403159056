<div class="top-menu" *ngIf="showTopMenu && !this.terminal">
  <mat-toolbar color="primary">
    <!--    [style.display] {{this.sideNavMod}}="(screenWidth > mobileSize) ? 'none' : 'block'"-->


    <button *ngIf="this.tokensStorage.getProjectId() != undefined && showNavBarButton && (screenWidth <= mobileSize)"
            [style.display]="'block'"
            mat-icon-button
            (click)="toggleButton()">
      <mx-icon mx="menu" marginBottom="true" color="white"></mx-icon>
    </button>

    <div style="display: flex;">
      <div style="display: flex;" (click)="showNavBar ? selectProjectPage(): null"
           [style.cursor]="showNavBar ? 'pointer' :'default'">
        <div class="logo ">
          <img class="logo-mx" src="{{ (screenWidth <= mobileSize) ?
           '/assets/img/logo-without-text.svg' :
            '/assets/img/logo.svg'
             }}"
               [matTooltip]="'MaxTAF version: ' + maxtafVersion" alt="logo">
        </div>
      </div>
    </div>

    <button mat-button class="ml-1"
            *ngIf="(this.tokensStorage.getProjectId() != undefined && this.tokensStorage.getProjectId() != '') && !forceHideTopBarSelectProject"
            (click)="selectProject()">
      <mx-icon mx="project" color="white"></mx-icon>
      <span class="menu-text">{{ projectName() }}</span>
      <mx-icon mx="expand" color="white" class="remove-icon-margin"></mx-icon>
    </button>
    <!--    mouseX: {{mouseX}} / mouseY: {{mouseY}} / sideNavMod: {{sideNavMod}} / sidenavHover: {{sidenavHover}} / parametersHover: {{parametersHover}} / filesHover: {{filesHover}} / workspaceHover: {{workspaceHover}} /-->

    <span class="example-spacer"></span>

    <div class="doc-buttons" [style.display]="(screenWidth <= tabletSize) ? 'none' : 'block'">
      <button mat-button color="primary" class="doc-button">
        <mx-icon mx="documentation" color="white"></mx-icon>
        <a mat-button target="_blank" href="https://www.maxtaf.com/guides/mtc-guide/?version=Cloud">Docs</a>
      </button>

      <button mat-button color="primary" class="doc-button"
              [style.height]="(screenWidth <= smallMobileSize) ? '25px' : ''">
        <mx-icon mx="tutorial" color="white" class="mr-1"></mx-icon>
        <a mat-button target="_blank" class="px-1" href="https://www.maxtaf.com/tutorials">Tutorials</a>
      </button>
    </div>


    <div [style.display]="(showAccountSettings || forceShowTopBarUSerInfo) ?'block' : 'none' ">

      <!--nav menu-->
      <div [style.display]="(screenWidth > mobileSize || forceShowTopBarUSerInfo) ? 'block' :  'none' ">

        <!--username-->
        <button mat-button [matMenuTriggerFor]="username">
          <mx-icon marginBottom="true" mx="user" color="white"></mx-icon>
          <span class="menu-text" *ngIf="(screenWidth > mobileSize)">{{ userNameFull() }}</span>
          <mx-icon mx="expand" color="white"></mx-icon>
        </button>

        <mat-menu #username="matMenu" class="px-3">
          <!--          <button color="primary" mat-menu-item (click)="openUserDialog()">-->
          <!--            <mx-icon mx="user-avatar" color="primary"></mx-icon>-->
          <!--            <span class="menu-text-primary">User Profile</span>-->
          <!--          </button>-->

          <button mat-menu-item color="primary" (click)="logout()" class="px-3">
            <mx-icon mx="log-out" color="primary"></mx-icon>
            <span class="menu-text-primary">Log Out</span>
          </button>
        </mat-menu>

      </div>

    </div>

  </mat-toolbar>
</div>

<mat-sidenav-container
  [ngClass]="{
  'sidenav-container-without-top-menu': !showTopMenu  && !this.terminal,
   'sidenav-container': showTopMenu  && !this.terminal,
    'mini-sidenav': sideNavMod == 2 && !this.terminal,
    'normal-sidenav': sideNavMod == 1 && !this.terminal,
    'none-sidenav': sideNavMod == 0 || this.terminal
    }">

  <!--  -->
  <mat-sidenav class="sidebar" #sidenav [opened]="showNavBar && screenWidth > mobileSize"
               (mouseenter)="onHover()"
               (mouseleave)="onHoverExit()"
               [mode]=" (screenWidth > mobileSize) ? sideNavMod ==1 ? 'over' :'side' : 'over'">
    <overlay-scrollbars
      style="height: 100%"

      [options]="{ scrollbars: { autoHide: 'l' } }"
    >
      <mat-nav-list>
        <mat-expansion-panel class="primary_background no_shadow overflow-hidden" hideToggle #homeMenu
                             (mouseenter)="onHoverHome()"
                             (mouseleave)="onHoverExitHome()"
                             (click)="clickedHome(homeMenu); screenWidth < mobileSize ? sidenav.close() : null">
          <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Home'">
            <mx-icon mx="home" [marginBottom]="true" color="white"></mx-icon>
            <!--            <span class="menu-text" *ngIf="sideNavMod != 2"> Home </span>-->
            <span class="menu-text"> Home </span>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <div class="divider-line" style="padding-left: 18px;">
          <!--          {{sideNavMod != 2 ? 'Development' : 'Dev'}}-->
          Dev
        </div>

        <mat-expansion-panel class="primary_background no_shadow overflow-hidden" hideToggle #casesMenu
                             (mouseenter)="onHoverWorkspace()"
                             (mouseleave)="onHoverExitWorkspace()"
                             (click)="clickedCases(casesMenu); screenWidth < mobileSize ? sidenav.close() : null">
          <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Workspace'">
            <mx-icon mx="pencil" color="white"></mx-icon>
            <span class="menu-text"> Workspace </span>
          </mat-expansion-panel-header>

        </mat-expansion-panel>

        <mat-expansion-panel class="primary_background no_shadow overflow-hidden" hideToggle #filesMenu
                             (mouseenter)="onHoverFileSystem()"
                             (mouseleave)="onHoverExitFileSystem()"
                             (click)="clickedFiles(filesMenu); screenWidth < mobileSize ? sidenav.close() : null">
          <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'File System'">
            <mx-icon mx="workspace-project" color="white"></mx-icon>
            <span class="menu-text"> File System </span>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <!--        MARKO NOTE: Danijel needs to fix the following commented code to take you to a list of project params that can be set by the users of the project-->
        <mat-expansion-panel class="primary_background no_shadow overflow-hidden" hideToggle #parametersMenu
                             (mouseenter)="onHoverParameters()"
                             (mouseleave)="onHoverExitParameters()"
                             (click)="clickedParameters(parametersMenu); screenWidth < mobileSize ? sidenav.close() : null">
          <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Parameters'">
            <mx-icon mx="tune" color="white"></mx-icon>
            <span class="menu-text"> Parameters </span>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <div class="divider-line" style="padding-left: 17px;">
          <!--          {{sideNavMod != 2 ? 'Operations' : 'Ops'}}-->
          Ops
        </div>

        <mat-expansion-panel class="primary_background no_shadow overflow-hidden" hideToggle #suitesMenu
                             (mouseenter)="onHoverSuites()"
                             (mouseleave)="onHoverExitSuites()"
                             (click)="clickedSuites(suitesMenu); screenWidth < mobileSize ? sidenav.close() : null">
          <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Suites'">
            <mx-icon mx="suites" color="white"></mx-icon>
            <span class="menu-text"> Suites </span>
          </mat-expansion-panel-header>

        </mat-expansion-panel>

        <mat-expansion-panel class="primary_background no_shadow" hideToggle #scheduleMenu
                             (mouseenter)="onHoverSchedule()"
                             (mouseleave)="onHoverExitSchedule()"
                             (click)="clickedSchedule(scheduleMenu); screenWidth < mobileSize ? sidenav.close() : null">
          <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Schedule'">
            <mx-icon mx="schedule" color="white"></mx-icon>
            <span class="menu-text"> Schedule </span>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel class="primary_background no_shadow overflow-hidden" hideToggle #runsMenu
                             (mouseenter)="onHoverRuns()"
                             (mouseleave)="onHoverExitRuns()"
                             (click)="clickedRuns(runsMenu); screenWidth < mobileSize ? sidenav.close() : null">
          <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Runs'">
            <mx-icon mx="runs" color="white"></mx-icon>
            <span class="menu-text"> Runs </span>
          </mat-expansion-panel-header>

        </mat-expansion-panel>

        <div class="divider-line" style="padding-left: 12px;">
          Admin
        </div>

        <mat-expansion-panel class="primary_background no_shadow" #projectMenu hideToggle
                             (mouseenter)="onHoverProjectSetting()"
                             (mouseleave)="onHoverExitProjectSetting()"
                             [matTooltip]="sideNavMod != 2? '': 'Project Settings'"
                             (click)="clickedProject(projectMenu); screenWidth < mobileSize ? sidenav.close() : null">
          <mat-expansion-panel-header>
            <mx-icon mx="project" color="white"></mx-icon>
            <span class="menu-text"> Project Settings </span>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel class="primary_background no_shadow" #profileMenu hideToggle
                             (mouseenter)="onHoverUserSettings()"
                             (mouseleave)="onHoverExitUserSettings()"
                             [matTooltip]="sideNavMod != 2? '': 'User Settings'"
                             (click)="clickedProfile(profileMenu); screenWidth < mobileSize ? sidenav.close() : null">
          <mat-expansion-panel-header>
            <mx-icon mx="user-settings" color="white"></mx-icon>
            <span class="menu-text"> User Settings </span>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="this.userService.systemUser" class="primary_background no_shadow"
                             (mouseenter)="onHoverSystemReport()"
                             (mouseleave)="onHoverExitSystemReport()"
                             hideToggle #systemReportMenu
                             (click)="clickedSystemReport(systemReportMenu); screenWidth < mobileSize ? sidenav.close() : null">
          <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'System Report'">
            <mx-icon mx="system-report" color="white"></mx-icon>
            <span class="menu-text"> System Report </span>
          </mat-expansion-panel-header>
        </mat-expansion-panel>


      </mat-nav-list>

      <div class="divider-line" style="padding-left: 14px;">
        Learn
      </div>

      <mat-expansion-panel class="primary_background no_shadow" hideToggle #documentationMenu
                           (mouseenter)="onHoverAI()"
                           (mouseleave)="onHoverExitAI()"
                           (click)="clickedAI(documentationMenu); screenWidth < mobileSize ? sidenav.close() : null">
        <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Ai'">
          <mx-icon mx="ai" color="white"></mx-icon>
          <span class="menu-text"> AI </span>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <mat-expansion-panel class="primary_background no_shadow" hideToggle #documentationMenu
                           (mouseenter)="onHoverDocumentation()"
                           (mouseleave)="onHoverExitDocumentation()"
                           (click)="clickedDocumentation(documentationMenu); screenWidth < mobileSize ? sidenav.close() : null">
        <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Documentation'">
          <mx-icon mx="documentation" color="white"></mx-icon>
          <span class="menu-text"> Documentation </span>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <mat-expansion-panel class="primary_background no_shadow" hideToggle #tutorialMenu
                           (mouseenter)="onHoverTutorials()"
                           (mouseleave)="onHoverExitTutorials()"
                           (click)="clickedTutorials(tutorialMenu); screenWidth < mobileSize ? sidenav.close() : null">
        <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Tutorials'">
          <mx-icon mx="tutorial" color="white"></mx-icon>
          <span class="menu-text"> Tutorials </span>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <mat-expansion-panel class="primary_background no_shadow" hideToggle #supportMenu
                           (mouseenter)="onHoverSupport()"
                           (mouseleave)="onHoverExitSupport()"
                           (click)="clickedSupport(supportMenu); screenWidth < mobileSize ? sidenav.close() : null">
        <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Support'">
          <mx-icon mx="support" color="white"></mx-icon>
          <span class="menu-text"> Support </span>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <div class="divider-line" [ngStyle]="{'display' : (screenWidth > mobileSize) ? 'none' : 'block'}">

      </div>

      <mat-expansion-panel class="primary_background no_shadow" hideToggle #logOutMenu
                           (mouseenter)="onHoverLogOut()"
                           (mouseleave)="onHoverExitLogOut()"
                           [ngStyle]="{'display' : (screenWidth > mobileSize) ? 'none' : 'block'}"
                           (click)="clickedLogOutMenu(logOutMenu); screenWidth < mobileSize ? sidenav.close() : null">
        <mat-expansion-panel-header [matTooltip]="sideNavMod != 2? '': 'Log Out'">
          <mx-icon mx="log-out" color="white"></mx-icon>
          <span class="menu-text"> Log Out </span>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

    </overlay-scrollbars>
  </mat-sidenav>


  <!-- content -->
  <mat-sidenav-content
    (mouseenter)="onHoverContent()"
    (mouseleave)="onHoverContentExit()"
    [ngClass]="{
    'mini-sidenav': sideNavMod == 2 && !this.terminal,
    'normal-sidenav': sideNavMod == 1 && !this.terminal,
    'none-sidenav': sideNavMod == 0 || this.terminal
    }">
    <as-split
      direction="horizontal"
      unit="pixel"
      [disabled]="!expandedAIChat"
      [gutterSize]="expandedAIChat? 12: 0">

      <as-split-area
        [size]="expandedAIChat? resizeAIMode? 800 :275: 0"
        [lockSize]="!expandedAIChat"
        [minSize]="expandedAIChat? 175 : 0">

        <div
          style="width: 100%; height: 100%; background: #e9e9e9;"
          *ngIf="!forceHideTopBarSelectProject">

          <app-ai-wrapper #aiWrapperComponent
                          [resizeAIMode]="resizeAIMode"
                          (resizeAI)="resizeAI()"
                          (hideAI)="hideAI()">
          </app-ai-wrapper>

        </div>
      </as-split-area>

      <as-split-area>
        <overlay-scrollbars
          #pageScroll
          [ngClass]="{ 'height-without-board': !showNoticeBoard, 'height-with-board': showNoticeBoard }"
          [options]="{ scrollbars: { autoHide: 'l' } }"
        >
          <router-outlet
            [ngClass]="{'height-without-board': !showNoticeBoard, 'height-with-board': showNoticeBoard }"
          ></router-outlet>

        </overlay-scrollbars>

        <mx-notice-board *ngIf="showNoticeBoard"></mx-notice-board>
      </as-split-area>
    </as-split>

  </mat-sidenav-content>

</mat-sidenav-container>


