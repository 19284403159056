<h1 mat-dialog-title>{{duplicate ? 'Duplicate this' : 'Create a'}} case</h1>

<div mat-dialog-content>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

  <label *ngIf="!duplicate"> {{duplicate ? 'New case ' : 'Case '}} name</label>
  <div *ngIf="!duplicate" class="mb-3">
    <input [disabled]="saveSpinner" type="text" [(ngModel)]="case.name">
  </div>
  <label *ngIf="!duplicate">Type</label>
  <div *ngIf="!duplicate" class="mb-3">
    <mat-radio-group (change)="case.caseType = $event.value; getTestingFrameworksFor('cases',case.caseType)"
                     placeholder=""
                     [disabled]="saveSpinner || createFromFile || blockType || fromWorkspace"
                     [value]="case.caseType">
      <mat-radio-button value="JAVA">Java</mat-radio-button>
      <br>
      <mat-radio-button value="PYTHON">Python</mat-radio-button>
      <br>
      <mat-radio-button value="JAVASCRIPT">JavaScript</mat-radio-button>
      <br>
      <!--      <mat-option value="CSHARP">C#</mat-option>-->
      <!--      <mat-option value="ROBOT_FRAMEWORK">Robot Framework</mat-option>-->
      <mat-radio-button *ngIf="isLicensed" value="MXML">Mxml</mat-radio-button>
      <!--      <mat-option *ngIf="isLicensed" value="UTAM">Utam</mat-option>-->
    </mat-radio-group>
  </div>

  <div *ngIf="!duplicate" class="mb-3">
    <label>Choose testing framework</label>
    <mat-select
      (selectionChange)="getTemplatesFor(case.pageObject ? 'pageObjects': 'cases',case.caseType, case.testingFramework)"
      placeholder=""
      [disabled]="saveSpinner"
      [(value)]="case.testingFramework">
      <mat-option *ngFor="let testingFramework of filteredTestingFrameworks" [value]="testingFramework">
        {{parseTestingFrameworkName(testingFramework)}}
      </mat-option>
    </mat-select>
  </div>

  <div *ngIf="!duplicate && !createFromFile && !fromWorkspace">
    <label>Choose template</label>
    <mat-select [(value)]="selectedTemplate" [disabled]="saveSpinner">
      <mat-option *ngFor="let template of filteredTemplates" [value]="template.name">
        {{template.name}}
        <span [attr.data-content]="template.description" class="option-description"></span>
      </mat-option>
    </mat-select>

  </div>

  <label *ngIf="duplicate && setNewFileLocation && !advanceFileLocation">File location name</label>
  <div *ngIf="duplicate && setNewFileLocation && !advanceFileLocation">
    <!--    <input type="text" [(ngModel)]="fileLocationName">-->
  </div>

</div>

<div mat-dialog-actions>
  <button id="cancelButton" mat-button *ngIf="!saveSpinner" [mat-dialog-close]>Cancel</button>

  <button id="createButton" mat-button *ngIf="!saveSpinner" (click)="do()" color="primary">
    {{duplicate ? 'Duplicate' : 'Create'}}
  </button>
  <button mat-button *ngIf="saveSpinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>
