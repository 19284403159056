<section class="main-container" style="height: 100%">
  <div class="stop-responding" *ngIf="isRunActive">
    <button mat-raised-button
            color="primary"
            [disabled]="disableInput"
            (mousedown)="stopEvent()"
            aria-label="Stop Responding">
      Stop Responding
    </button>
  </div>

  <div class="input-box" style="height: 100%"
       tabindex="2"
       *ngIf="!isRunActive"
       [matTooltip]="disableInput ? 'Maximum number of requests per project on a daily basis has been reached using the Maxtaf key. Please configure your key in the project parameters.': ''">

    <mat-form-field class="fill-container" style="height: 100%">
      <mat-label>Send a message</mat-label>
      <textarea
        #textareaElement
        matInput
        placeholder="Send a message"
        (focus)="focusOnInput()"
        (blur)="focusOutInput()"
        [disabled]="disableInput"
        [(ngModel)]="value"
        (ngModelChange)="adjustTextareaHeight()"
        (keydown)="onKeydown($event)"
        rows="3"
      ></textarea>
    </mat-form-field>

    <div id="parentDiv" [ngStyle]="{ 'height': inputHeight + 'px' }" style="position: relative;">
<!--      <div id="fixedButtonContainer" *ngIf="showLinkCase">-->
<!--        <button mat-mini-fab-->
<!--                style="background: none; box-shadow: none; color: #1e88e5;"-->
<!--                [matTooltip]="this.linkCase ? 'Remove code from the question' : 'Add code to the question.'"-->
<!--                [disabled]="disableInput"-->
<!--                (mousedown)="clickedLinkCode()"-->
<!--                aria-label="Send message" id="fixedButton">-->
<!--          <mx-icon mx="unlinked-file" *ngIf="!this.linkCase"></mx-icon>-->
<!--          <mx-icon mx="linked-file" *ngIf="this.linkCase"></mx-icon>-->
<!--        </button>-->
<!--      </div>-->
      <div id="centeredButtonContainer">
        <button mat-raised-button
                class="run-button"
                color="primary"
                [disabled]="disableInput"
                (mousedown)="askQuestionEvent()"
                aria-label="Send message" id="centeredButton">
          Send
        </button>
      </div>
    </div>
  </div>
</section>
