import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ChatSession} from "../../shared/models/chatSession";
import {AiChat} from "../../shared/models/aiChat";
import {Observable, Subscription} from "rxjs";
import {AiChatStreamDelta} from "../../shared/models/aiStreamChat";
import {MaxtafTokensStorageService} from "../../../../shared/services/maxtaf-tokens-storage.service";
import {OverlayScrollbarsComponent} from "overlayscrollbars-ngx";
import {AiConfigService} from "../../shared/services/ai-config.service";
import {AiChatService} from "../../shared/services/ai-chat.service";
import {CaseService} from "../../../cases/shared/services/case.service";
import {Case} from "../../../cases/shared/models/case";
import {EditSessionDialogComponent} from "../edit-session-dialog/edit-session-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-chat-session',
  templateUrl: './chat-session.component.html',
  styleUrls: ['./chat-session.component.css']
})
export class ChatSessionComponent implements OnInit {
  @ViewChild('pageScroll') public pageScroll: OverlayScrollbarsComponent;

  session: ChatSession;

  @Input('chatSession') set setChatSession(session: ChatSession) {
    this.session = session;
    if (this.session == null || this.session.id == null || this.session.id == '') {
      this.chatRecords = [];
    } else {
      this.getChatRecordsForSession();
    }
  }

  @Input('activeChat') activeChat: AiChat;
  chatRecords: AiChat[] = [];
  private response: string;
  errorMessage = '';
  @Input('showLoadResponseDiv') showLoadResponseDiv = false;
  aiRequest: Subscription;
  @Input('isRunActive') isRunActive = false;

  private esAIChatAskQuestion: EventSource;

  maxHeight: string;
  runId: string;

  @Input('maxHeight') set setScrollHeight(maxHeight: number) {
    this.maxHeight = (maxHeight ) + "px"
  }

  @Output('startQuestion') startQuestionEvent = new EventEmitter<void>();
  @Output('completedQuestion') completedQuestionEvent = new EventEmitter<void>();

  refreshDataAndAsk() {
    this.getChatRecordsForSession(true);
  }

  constructor(
    private aiConfigService: AiConfigService,
    public dialog: MatDialog,
    private aiChatService: AiChatService,
    private caseService: CaseService,
    public tokensService: MaxtafTokensStorageService,
  ) {
  }

  ngOnInit(): void {
  }

  public askQuestion(question: string, sessionId: string = null, caseId: string) {
    let sessionIdForQuestion = sessionId != null ? sessionId : this.session.id;

    if (caseId != null) {
      this.caseService.getCase(caseId).subscribe(
        (testCase: Case) => {

          question += "\n\nHere is the current code:\n```" + testCase.caseType.toLowerCase() + "\n" + testCase.code + "\n```\n"
          this.realAskQuestion(question, sessionId);
        }, error => {
          console.error('error: ', error);
          this.realAskQuestion(question, sessionId);
        });
    } else {
      this.realAskQuestion(question, sessionId);
    }

  }


  public realAskQuestion(question: string, sessionId: string = null) {
    let sessionIdForQuestion = sessionId != null ? sessionId : this.session.id;

    this.aiChatService.saveQuestion(
      sessionIdForQuestion,
      question
    ).subscribe(
      (res: AiChat) => {
        this.addToChatArray([res]);
        // setTimeout(() => {
        this.scrollToBottom(0);
        this.askQuestionWithoutContent();
        // }, 500);

      }, error => {
        console.error('error: ', error);
      });

  }


  ngOnDestroy() {
    this.stopGetChatRecordsForSessionRequest();
    this.stopAIRequest();
  }

  askQuestionWithoutContent() {
    this.stopAIRequest();

    this.startQuestionEvent.emit();

    this.activeChat = null;
    this.showLoadResponseDiv = true;
    this.isRunActive = true;

    setTimeout(() => {
      this.scrollToBottom();
    }, 500);


    const user = this.tokensService.getUserId();
    const project = this.tokensService.getProjectId();
    const userProject = this.tokensService.getUserProjectID();
    const fireToken = this.tokensService.getFireToken();
    const refreshToken = this.tokensService.getFireRefreshToken();


    this.esAIChatAskQuestion = new EventSource(
      '/api/ai/ask/session/' + this.session.id +
      '?' + this.tokensService.USER_PROJECT_TOKEN + '=' + userProject + '&' +
      this.tokensService.PROJECT_TOKEN + '=' + project + '&' +
      this.tokensService.USER_TOKEN + '=' + user + '&' +
      this.tokensService.FIREBASE_TOKEN + '=' + fireToken + '&' +
      this.tokensService.FIREBASE_REFRESH_TOKEN + '=' + refreshToken + '&' +
      this.tokensService.USER_TIMEZONE_ID + '=' + Intl.DateTimeFormat().resolvedOptions().timeZone + ''
    );

    this.response = '';
    this.errorMessage = '';

    this.aiRequest = this.askMaxtafAI().subscribe({
      next: (result) => {
        this.errorMessage = '';
        this.isRunActive = true;
        this.showLoadResponseDiv = false;

        let objResponse: AiChatStreamDelta;
        try {
          objResponse = JSON.parse(result);
          if (objResponse.error) {
            this.isRunActive = false;
            this.showLoadResponseDiv = false;
            this.errorMessage = objResponse.content;

            this.closeAskAIEvent();
          }

          this.runId = objResponse.runId;

          if (objResponse.content != null) {
            this.response += objResponse.content;
          }
          this.activeChat = new AiChat("id", "assistant", this.response, this.session.userProjectId, true, this.session.id);
        } catch (e) {
          console.error("error: ", e);
          this.showLoadResponseDiv = false;
          this.closeAskAIEvent();
        }

        this.scrollToBottomLive();
      },
      error: (error) => {
        console.error("error: ", error);
        this.isRunActive = false;
        this.showLoadResponseDiv = false;
        this.closeAskAIEvent();
      },
      complete: () => {
        this.isRunActive = false;
        this.showLoadResponseDiv = false;

      }
    });
  }

  closeAskAIEvent() {
    this.esAIChatAskQuestion.close();

    this.addToChatArray([this.activeChat]);
    this.activeChat = null;
    this.getChatRecordsForSession();
    this.completedQuestionEvent.emit();
    // this.runId = null;
  }


  isOverflowingTitle(element: HTMLElement): boolean {
    return element.offsetWidth < element.scrollWidth;
  }

  private esAIChat: EventSource;
  private esAIChatRequest: Subscription;

  stopGetChatRecordsForSessionRequest() {
    try {
      if (this.esAIChatRequest) {
        this.esAIChatRequest.unsubscribe();
      }
    } catch (e) {
      console.error(e)
    }
  }

  closeEventGetChat(askAI = false) {
    this.esAIChat.close();
    this.finalListCheck();
    if (askAI) {
      this.askQuestionWithoutContent();
    }
  }


  editSessions(session: ChatSession) {

    const dialogRef = this.dialog.open(EditSessionDialogComponent, {
      width: '500px',
      data: {
        session: session
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }


  getChatRecordsForSession(askAI = false) {
    this.stopGetChatRecordsForSessionRequest();

    this.activeChat = null;

    if (this.session == null || this.session.id == null || this.session.id == '') {
      this.chatRecords = [];
    }

    setTimeout(() => {
      this.scrollToBottom();
    }, 500);


    const user = this.tokensService.getUserId();
    const project = this.tokensService.getProjectId();
    const userProject = this.tokensService.getUserProjectID();
    const fireToken = this.tokensService.getFireToken();
    const refreshToken = this.tokensService.getFireRefreshToken();


    this.esAIChat = new EventSource(
      '/api/ai' + "/chat/session/" + this.session.id +
      '?' + this.tokensService.USER_PROJECT_TOKEN + '=' + userProject + '&' +
      this.tokensService.PROJECT_TOKEN + '=' + project + '&' +
      this.tokensService.USER_TOKEN + '=' + user + '&' +
      this.tokensService.FIREBASE_TOKEN + '=' + fireToken + '&' +
      this.tokensService.FIREBASE_REFRESH_TOKEN + '=' + refreshToken + '&' +
      this.tokensService.USER_TIMEZONE_ID + '=' + Intl.DateTimeFormat().resolvedOptions().timeZone + ''
    );
    if (this.chatRecords.length > 0) {
      this.chatRecords[0].sessionId == this.session.id
    }

    // this.chatRecords = [];
    this.response = '';

    this.esAIChatRequest = this.getMaxtafAI().subscribe({
      next: (result) => {
        let objResponse: AiChat;
        try {
          objResponse = JSON.parse(result);

          if (objResponse != null) {

            this.addToChatArray([objResponse]);
            this.scrollToBottomLive();
          }

        } catch (e) {
          this.closeEventGetChat(askAI);
        }


      },
      error: (error) => {
        this.closeEventGetChat(askAI);


      },
      complete: () => {
        this.closeEventGetChat(askAI);
      }
    });
  }


  scrollToBottomLive() {
    const osInstance = this.pageScroll.osInstance();
    let scrollInfo = osInstance.scroll();

    if (scrollInfo.max.y - scrollInfo.position.y < 100) {
      osInstance.scroll({y: "100%"}, 500);
    }
  }

  scrollToBottom(duration = 500) {
    const osInstance = this.pageScroll.osInstance();
    osInstance.scroll({y: "100%"}, duration);
  }

  scrollY(y: number, duration: number = 0) {
    this.pageScroll.osInstance().scroll(
      {
        x: this.pageScroll.osInstance().getState().overflowAmount.x,
        y
      },
      duration
    );
  }


  getMaxtafAI(): Observable<string> {
    return new Observable<string>((observer) => {
      this.esAIChat.onmessage = (event) => {
        observer.next(event.data);
      };

      this.esAIChat.onerror = (error) => {
        observer.error(error);
      };
      return () => {
        this.closeEventGetChat();
      };
    });
  }


  addToChatArray(res: AiChat[]): void {
    this.addNewChatRecordToList(res[0]);
  }

  lastAddedDate: Date = null;
  previousAddedDate: Date = null;

  addNewChatRecordToList(newChatRecord: AiChat) {

    if (this.chatRecords.length > 0 && this.chatRecords[0].sessionId !== newChatRecord.sessionId) {
      this.chatRecords = [];
    }

    let exists = this.chatRecords.some(item => item.id === newChatRecord.id);

    if (!exists) {
      this.chatRecords.push(newChatRecord);

      this.previousAddedDate = this.lastAddedDate;
      this.lastAddedDate = new Date(newChatRecord.lastModifiedDate);
    }

    if (this.previousAddedDate != null && this.lastAddedDate != null) {
      this.chatRecords = this.chatRecords.filter(item => new Date(item.lastModifiedDate) <= this.previousAddedDate || new Date(item.lastModifiedDate) >= this.lastAddedDate);
    }

    this.chatRecords.sort((a, b) => new Date(a.lastModifiedDate).getTime() - new Date(b.lastModifiedDate).getTime());
  }

  finalListCheck() {
    if (this.lastAddedDate != null) {
      this.chatRecords = this.chatRecords.filter(item => new Date(item.lastModifiedDate).getTime() <= this.lastAddedDate.getTime());
    }

    this.lastAddedDate = null;
    this.previousAddedDate = null;

  }

  public stopAIRequest() {
    try {
      this.completedQuestionEvent.emit();
      if (this.aiRequest) {
        this.aiRequest.unsubscribe();
      }


    } catch (e) {
      console.error('error', e);
    } finally {
      this.cancelAiRequest();
    }
  }

  public cancelAiRequest() {
    if (this.runId != null) {

      this.aiChatService.cancelAiRequest(this.session.id, this.runId)
        .subscribe(
          chatSession => {
            this.runId = null;
          },
          error => {
            console.error(error);
          }
        );
    } else {
    }

  }


  askMaxtafAI(): Observable<string> {
    return new Observable<string>((observer) => {
      this.esAIChatAskQuestion.onmessage = (event) => {
        observer.next(event.data);
      };

      this.esAIChatAskQuestion.onerror = (error) => {
        console.error('EventSource failed:', error);
        observer.error(error);
        // this.runId = null;
      };
      return () => {
        this.esAIChatAskQuestion.close();
        // this.runId = null;
      };
    });
  }


}
