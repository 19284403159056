import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TemplateService} from '../../../../shared/services/template.service';
import {CaseService} from '../../../cases/shared/services/case.service';
import {SuiteService} from '../../../suites/shared/services/suite.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AiChat} from '../../shared/models/aiChat';
import {AiChatService} from '../../shared/services/ai-chat.service';

@Component({
  selector: 'app-edit-message-dialog',
  templateUrl: './edit-message-dialog.component.html',
  styleUrls: ['./edit-message-dialog.component.css']
})
export class EditMessageDialogComponent implements OnInit {

  message;
  id;
  sessionId;

  constructor(
    private router: Router,
    private aiChatService: AiChatService,
    private templateService: TemplateService,
    private caseService: CaseService,
    private suiteService: SuiteService,
    public dialogRef: MatDialogRef<EditMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
      id: string,
      sessionId: string
    }
  ) {
    dialogRef.disableClose = true;
    this.message = data.message;
    this.id = data.id;
    this.sessionId = data.sessionId;
  }

  ngOnInit(): void {
  }

  do() {
    this.aiChatService.updateChat(this.id, this.message, this.sessionId).subscribe(
      (res) => {

        this.dialogRef.close(true);

      }, error => {
        console.error('error: ', error);
      });
  }

  fileChanges(fileContent: string) {
    if (
      (fileContent == null) ||
      (fileContent == '')
    ) {
      return;
    }
    this.message = fileContent;
  }
}
